import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button } from "reactstrap";
import { Helmet } from "react-helmet";
import Spinner from "../../Spinner/spinner";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { localeEs } from "../../locale/ag-grid-es";
import Swal from "sweetalert2";
import BreadCrum from "../../breadCrum/breadCrum";
import withTokenCheck from "../../withTokenCheck";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";

const AppAsginacion = ({ token }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const gridApi = useRef(null);
    const currentDate = dayjs();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [modalEditcontent, setModalEditContent] = useState({});
    const [dateStart, setDateStart] = useState(currentDate);
    const [dateEnd, setDateEnd] = useState(currentDate);
    const [modalTittleContent, setModalTittleContent] = useState({});
    const [consultClicked, setConsultClicked] = useState(false);
    const tableRef = useRef(null);
    const etiquetas = [
        "Inicio Lossman",
        "Cambios De Evento",
        "Reporte de Transportes",
    ];

    const handleConsult = (event) => {
        event.preventDefault();
        const params = {
            FechaInicio: dateStart.format("YYYY-MM-DD"),
            FechaFinal: dateEnd.format("YYYY-MM-DD"),
        };
        if (token) {
            setLoading(true);
            axios
                .get("/programacionformulario/reporte-transportes", {
                    headers: { Authorization: `Bearer ${token}` },
                    params,
                })
                .then((response) => {
                    setData(response.data);
                    setLoading(false);  
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const handleModalToggle = () => {
        setModalOpen(!modalOpen);
    };
    const handleEditModalToggle = () => setModalEditOpen(!modalEditOpen);

    const handleButtonClick = (content) => {
        setModalContent(content);
        handleModalToggle();
    };

    const handleEditClick = (data) => {
        axios
            .get(`download/excel_transporte/${data.FormularioId}`, {
                headers: { Authorization: `Bearer ${token}`, "Content-Type": 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
                responseType: 'blob',
                withCredentials: true,
                })
            .then(response => {
                const fileName = response.headers['content-disposition']
                    .match(/filename="(.+)"/)[1];
                const blob = new Blob([response.data]/* , { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' } */);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
            })
            .catch(error => {
                console.error("Error al descargar el archivo:", error);
            });
    };
    

    function formatNumeroAcompanantes(element) {
        const numAcompanantes = element?.data?.formulario?.acompanantes
            ? element.data.formulario.acompanantes.length
            : "";  // Si no existe, retorna cadena vacía
        return numAcompanantes;
    }

    function formatNumeroAcompanantesTransporte(element) {
        const numAcompanantes = element?.data?.CantidadAcompanantes
            ? element.data.CantidadAcompanantes
            : "No se solicito transporte para acompañantes";  // Si no existe, retorna un mensaje
        return numAcompanantes;
    }

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        editable: true,
    };

    const columnDefs = [
        {
            field: "id",
            headerName: "Descargar Planilla control",
            cellRenderer: (params) => (
                <Button
                    color="secondary"
                    onClick={() => handleEditClick(params.data)}
                >
                    Descargar Excel
                </Button>
            ),
        },
        { headerName: "Numero de caso", field: "formulario.NumeroCaso" },
        {
            field: "formulario.hogar.Nombre",
            headerName: "Hogar",
        },
        {
            headerName: "Fecha de Servicio",
            field: "FechaServicio",
        },
        {
            headerName: "Tipo de servicio",
            field: "tipo_servicio_hogar.Texto",
        },
        {
            headerName: "Nombre del paciente",
            field: "formulario.paciente.Nombre",
        },
        {
            headerName: "Identificación",
            field: "formulario.paciente.NumeroIdent",
        },
        {
            headerName: "Numero de contacto",
            field: "formulario.paciente.TelContacto",
        },
        { headerName: "Ruta de traslado", field: "RutaTraslado" },
        { headerName: "Observaciones", field: "Observacion" },
        { headerName: "Hora de la cita", field: "HoraCita" },
        { headerName: "Hora de salida", field: "HoraSalida" },
        { headerName: "placa del vehiculo", field: "vehiculo.Placa" },
        { headerName: "Nombre del conductor", field: "conductor.Nombre" },
        {
            headerName: "Numero de Acompañantes",
            field: "formulario.acompanantes",
            valueFormatter: formatNumeroAcompanantes,
        },
        {
            headerName: "Numero de acompañantes que se les solicito transporte",
            field: "CantidadAcompanantes",
            valueFormatter: formatNumeroAcompanantesTransporte,
        },
        { headerName: "Fecha de ingreso", field: "formulario.FechaInicial" },
        {
            headerName: "Cita medica",
            field: "servicio_detalle.tipo_servicio.Texto",
        },
        {
            headerName: "Fecha de la cita",
            field: "servicio_detalle.FechaConsulta",
        },
        { headerName: "IPS", field: "servicio_detalle.ips.Texto" },
        { headerName: "Usuario creación", field: "created_by.name" },
        { headerName: "Usuario actualización", field: "updated_by.name" },
    ];

    const handleExportCSV = () => {
        gridApi.current.exportDataAsCsv();
    };

    const onGridReady = (params) => {
        gridApi.current = params.api;
    };

    const handleUpdateTable = () => {
        const params = {
            FechaInicio: dateStart.format("YYYY-MM-DD"),
            FechaFinal: dateEnd.format("YYYY-MM-DD"),
        };
        if (token) {
            setLoading(true);
            axios
                .get("/programacionformularios", {
                    headers: { Authorization: `Bearer ${token}` },
                    params,
                })
                .then((response) => {
                    setData(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    return (
        <>
            <Helmet>
                <title>Reporte traslados</title>
            </Helmet>
            <div style={{ position: "relative" }}>
                {loading && <Spinner />}
                <div>
                    <BreadCrum etiquetas={etiquetas} />
                </div>
                <div style={{ opacity: loading ? 0.5 : 1 }}>
                    <div className="container-fluid mt-5">
                        <div className="ConsultReport">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={2} sx={{ minWidth: 305 }}>
                                    <DatePicker
                                        label="Fecha Inicial Desde"
                                        id="dateStart"
                                        value={dateStart}
                                        onChange={setDateStart}
                                    />
                                </Stack>
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={2} sx={{ minWidth: 305 }}>
                                    <DatePicker
                                        label="Fecha Inicial Hasta"
                                        value={dateEnd}
                                        onChange={setDateEnd}
                                    />
                                </Stack>
                            </LocalizationProvider>

                            <Button
                                id="buttonConsult"
                                className="mb-3"
                                color="secondary"
                                onClick={handleConsult}
                            >
                                Consultar
                            </Button>
                        </div>
                        <td>
                            <Button
                                color="primary"
                                onClick={() =>
                                    handleButtonClick({
                                        title: "Añadir Traslado",
                                        footer: (
                                            <Button
                                                className="mb-3"
                                                color="secondary"
                                                onClick={handleModalToggle}
                                            >
                                                Cancelar
                                            </Button>
                                        ),
                                    })
                                }
                            >
                                Solicitar traslado
                            </Button>

                            <Button
                                style={{ marginLeft: "10px" }}
                                color="success"
                                onClick={handleExportCSV}
                            >
                                Exportar CSV (Excel)
                            </Button>
                        </td>
                        <div
                            className="mt-3"
                            style={{ height: "500px", width: "100%" }}
                        >
                            <div
                                className="ag-theme-alpine"
                                style={{ height: "100%", width: "100%" }}
                            >
                                <AgGridReact
                                    rowData={data}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    pagination={true}
                                    onGridReady={onGridReady}
                                    localeText={localeEs}
                                    paginationPageSize={20}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withTokenCheck(AppAsginacion);
