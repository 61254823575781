import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";

const ModalEdit = ({ isOpen, toggle, title, id, NombreInicial, DireccionInicial, CiudadId, NumeroContactoIni, token }) => {
  const [ciudad, setCiudad] = useState([]);
  const [nombre, setNombre] = useState("");
  const [direccion, setDireccion] = useState("");
  const [numeroContacto, setNumeroContacto] = useState("");
  const [selectedCiudad, setSelectedCiudad] = useState("");
  const [selectedCiudad2, setSelectedCiudad2] = useState("");
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);

  useEffect(() => {
    setNombre(NombreInicial);
    setDireccion(DireccionInicial);
    setNumeroContacto(NumeroContactoIni);
  }, [NombreInicial, DireccionInicial, NumeroContactoIni]);
  useEffect(() => {
    if(token){
    axios
      .get("/ciudad", { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setCiudad(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, [token]);

  const ciudadOptions = ciudad.map((option) => ({
    value: option.id,
    label: option.Texto ?? option.Texto,
  }));

  const handleNombreChange = (event) => {
    setNombre(event.target.value);
  };
  const handleCiudadChange = (selectedOption) => {
    setSelectedCiudad2(selectedOption.value);
    setSelectedCiudad(
      ciudadOptions.find((option) => option.value === selectedOption.value)
    );
  };
  const handleDireccionChange = (event) => {
    setDireccion(event.target.value);
  };
  const handleNumeroChange = (event) => {
    setNumeroContacto(event.target.value);
  };

  const handleEditSubmit = (event) => {
    event.preventDefault();
    axios
      .put(`/hogartraslado/${id}`, {
        Nombre: nombre ? nombre : NombreInicial,
        CiudadId: parseInt(selectedCiudad2) ? parseInt(selectedCiudad2) : parseInt(CiudadId),
        Direccion: direccion ? direccion : DireccionInicial,
        NumeroContacto: numeroContacto ? numeroContacto : NumeroContactoIni,
      }, {headers: { Authorization: `Bearer ${token}` }})
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Actualizado!",
          text: "el Hogar a sido actualizado con exito.",
          showCloseButton: true,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "el hogar no se pudo actualizar",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label>Hogar de paso:</label>
          <input
            className="form-control"
            name="nombre"
            type="text"
            value={nombre}
            onChange={handleNombreChange}
          />
        </FormGroup>
        <FormGroup>
          <label>Dirección:</label>
          <input
            className="form-control"
            name="nombre"
            type="text"
            value={direccion}
            onChange={handleDireccionChange}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="servicioId">Ciudad:</label>
          <Select
            value={selectedCiudad ? selectedCiudad : ciudadOptions.find((option) => option.value === CiudadId)}
            onChange={handleCiudadChange}
            options={ciudadOptions}
          ></Select>
        </FormGroup>
        <FormGroup>
          <label>Numero Contacto:</label>
          <input
            className="form-control"
            name="nombre"
            type="text"
            value={numeroContacto}
            onChange={handleNumeroChange}
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleEditSubmit} color="primary" otype="submit">
          Guardar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalEdit;
