import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";

const ModalCreate = ({ isOpen, toggle, title, token }) => {
  const [nombre1, setNombre1] = useState("");
  const [nombre2, setNombre2] = useState("");
  const [apellido1, setApellido1] = useState("");
  const [apellido2, setApellido2] = useState("");
  const [tipoDocumento, setTipoDocumento] = useState([]);
  const [selectedTipoDocumento, setSelectedTipoDocumento] = useState("");
  const [selectedTipoDocumento2, setSelectedTipoDocumento2] = useState("");
  const [numeroIdentificacion, setNumeroIndentificacion] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [telContacto, setTelContacto] = useState("");
  const [ciudadOrigen, setCiudadOrigen] = useState([]);
  const [direccionResidencia, setDireccionResidencia] = useState("");
  const [telFijo, setTelFijo] = useState("");
  const [contactoEmergencia, setContactoEmergencia] = useState("");
  const [telContactoEmergencia, setTelContactoEmergencia] = useState("");
  const [selectedCiudadOrigen, setSelectedCiudadOrigen] = useState("");
  const [selectedCiudadOrigen2, setSelectedCiudadOrigen2] = useState("");
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);

  useEffect(() => {
    if(token){
    axios
      .get("/tipodocumento" , { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setTipoDocumento(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, [token]);

  useEffect(() => {
    if(token){
    axios
      .get("/ciudad", { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setCiudadOrigen(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, [token]);

  const tipoDocumenOptions = tipoDocumento.map((option) => ({
    value: option.id,
    label:
      option.Codigo + " - " + option.Texto ??
      option.Codigo + "-" + option.Texto,
  }));

  const ciudadOptions = ciudadOrigen.map((option) => ({
    value: option.id,
    label: option.Texto ?? option.Texto,
  }));

  const handleNombre1Change = (event) => {
    setNombre1(event.target.value);
  };
  const handleNombre2Change = (event) => {
    setNombre2(event.target.value);
  };
  const handleApellido1Change = (event) => {
    setApellido1(event.target.value);
  };
  const handleApellido2Change = (event) => {
    setApellido2(event.target.value);
  };
  const handleTipoDocumentoChange = (selectedOption) => {
    setSelectedTipoDocumento2(selectedOption.value);
    setSelectedTipoDocumento(
      tipoDocumenOptions.find((option) => option.value === selectedOption.value)
    );
  };
  const handleNumeroIdentChange = (event) => {
    setNumeroIndentificacion(event.target.value);
  };
  const handleFechaNacimientoChange = (event) => {
    setFechaNacimiento(event.target.value);
  };
  const handleTelContactoChange = (event) => {
    setTelContacto(event.target.value);
  };
  const handleCiudadOrigenChange = (selectedOption) => {
    setSelectedCiudadOrigen2(selectedOption.value);
    setSelectedCiudadOrigen(
      ciudadOptions.find((option) => option.value === selectedOption.value)
    );
  };
  const handleDireccionChange = (event) => {
    setDireccionResidencia(event.target.value);
  };
  const handleTelFijoChange = (event) => {
    setTelFijo(event.target.value);
  };
  const handleContactoEmergenciaChange = (event) => {
    setContactoEmergencia(event.target.value);
  };
  const handleTelContactoEmergenciaChange = (event) => {
    setTelContactoEmergencia(event.target.value);
  };
  const handleCreateSubmit = (event) => {
    event.preventDefault();

    // Enviar los datos de ls IPs
    if(token){
    axios
      .post("/paciente", {
        Nombre1: nombre1,
        Nombre2: nombre2,
        Apellido1: apellido1,
        Apellido2: apellido2,
        TipoDocumentoId: parseInt(selectedTipoDocumento2),
        NumeroIdent: parseInt(numeroIdentificacion),
        FechaNacimiento: fechaNacimiento,
        TelContacto: telContacto,
        CiudadOrigenId: parseInt(selectedCiudadOrigen2),
        DireccionResidencia: direccionResidencia,
        TelFijo : telFijo,
        ContEmergencia: contactoEmergencia,
        TelEmergencia: telContactoEmergencia,
      }, {headers: { Authorization: `Bearer ${token}` }})
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Creado!",
          text: "el paciente ha sido creada con exito",
          showCloseButton: true,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Ha ocurrido un error al crear el paciente.",
          showCloseButton: true,
        });
      });
    }
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label>Primer Nombre:</label>
          <input
            className="form-control"
            name="Nombre1"
            type="text"
            value={nombre1}
            onChange={handleNombre1Change}
          />
        </FormGroup>
        <FormGroup>
          <label>Segundo Nombre:</label>
          <input
            className="form-control"
            name="Nombre2"
            type="text"
            value={nombre2}
            onChange={handleNombre2Change}
          />
        </FormGroup>
        <FormGroup>
          <label>Primer Apellido:</label>
          <input
            className="form-control"
            name="Apellido1"
            type="text"
            value={apellido1}
            onChange={handleApellido1Change}
          />
        </FormGroup>
        <FormGroup>
          <label>Segundo Apellido:</label>
          <input
            className="form-control"
            name="Apellido2"
            type="text"
            value={apellido2}
            onChange={handleApellido2Change}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="TipoDocumentoId">Tipo de Documento:</label>
          <Select
            options={tipoDocumenOptions}
            onChange={handleTipoDocumentoChange}
          ></Select>
        </FormGroup>
        <FormGroup>
          <label>Numero de Documento:</label>
          <input
            className="form-control"
            name="fechaInicial"
            type="text"
            value={numeroIdentificacion}
            onChange={handleNumeroIdentChange}
          />
        </FormGroup>
        <FormGroup>
          <label>Fecha de Nacimiento:</label>
          <input
            className="form-control"
            name="fechaNacimiento"
            type="date"
            value={fechaNacimiento}
            onChange={handleFechaNacimientoChange}
          />
        </FormGroup>
        <FormGroup>
          <label>Telefono de Contacto:</label>
          <input
            className="form-control"
            name="TelContacto"
            type="text"
            value={telContacto}
            onChange={handleTelContactoChange}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="CiudadOrigenId">Ciudad de Origen:</label>
          <Select
            options={ciudadOptions}
            onChange={handleCiudadOrigenChange}
          ></Select>
        </FormGroup>
        <FormGroup>
          <label htmlFor="DireccionResidencia">Dirección Residencia:</label>
          <input
            className="form-control"
            name="DireccionResidencia"
            type="text"
            value={direccionResidencia}
            onChange={handleDireccionChange}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="TelFijo">Telefono Fijo:</label>
          <input
            className="form-control"
            name="TelFijo"
            type="text"
            value={telFijo}
            onChange={handleTelFijoChange}
          />
        </FormGroup>
        
        <FormGroup>
          <label htmlFor="ContactoEmergencia">Contacto Emergencia:</label>
          <input
            className="form-control"
            name="ContactoEmergencia"
            type="text"
            value={contactoEmergencia}
            onChange={handleContactoEmergenciaChange}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="TelContacto"> Telefono Contacto Emergencia:</label>
          <input
            className="form-control"
            name="TelContactoEmergencia"
            type="text"
            value={telContactoEmergencia}
            onChange={handleTelContactoEmergenciaChange}
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleCreateSubmit} color="primary" otype="submit">
          Insertar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalCreate;
