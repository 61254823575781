import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import ModalCreate from "./ModalCreate";
import ModalEdit from "./ModalEdit";
import { Button } from "reactstrap";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import withTokenCheck from "../../withTokenCheck";

const AppTipoAdmision = ({ token }) => {
    const [tipoAdmision, setTipoAdmision] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [modalEditcontent, setModalEditContent] = useState({});
    const [modalTittleContent, setModalTittleContent] = useState({});
    const [loading, setLoading] = useState(true);
    const tableRef = useRef(null);

    useEffect(() => {
        if (token) {
            axios
                .get("/tipoadmision", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setTipoAdmision(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const handleModalToggle = () => setModalOpen(!modalOpen);
    const handleEditModalToggle = () => setModalEditOpen(!modalEditOpen);

    const handleButtonClick = (content) => {
        setModalContent(content);
        handleModalToggle();
    };

    const handleEditClick = (element, content) => {
        setModalEditContent(element);
        setModalTittleContent(content);
        handleEditModalToggle();
    };

    const handleDeleteClick = (tipoAdmisionTodelete) => {
        axios
            .delete(`/tipoadmision/${tipoAdmisionTodelete.id}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                setTipoAdmision(
                    tipoAdmision.filter(
                        (tipoAdmision) =>
                            tipoAdmision.id !== tipoAdmisionTodelete.id
                    )
                );
                Swal.fire({
                    icon: "success",
                    title: "Eliminado!",
                    text: "el tipo de Admisión ha sido eliminado con exito.",
                    showCloseButton: true,
                }).then(() => {
                    window.location.reload();
                });
            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "Ha ocurrido un error al eliminar el tipo de Admisión.",
                });
            });
    };

    useEffect(() => {
        let dataTable;
        if (!loading) {
            $(tableRef.current).DataTable({
                paging: true,
                ordering: true,
                info: true,
                dom: "<'row'<'col-sm-2'l><'col-sm-6'B><'col-sm-4'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-6 text-right'i><'col-sm-6'p>>",
                language: {
                    url: "//cdn.datatables.net/plug-ins/1.13.1/i18n/es-ES.json",
                },
                buttons: {
                    dom: {
                        button: {
                            tag: "button",
                            className: "btn",
                        },
                    },
                    buttons: [
                        {
                            extend: "excel",
                            className: "btn-success",
                            text: "<i class='far fa-file-excel'></i> Excel",
                            extension: ".xlsx",
                        },
                    ],
                },
            });
        }
        return () => {
            if (dataTable) {
                dataTable.destroy();
            }
        };
    }, [loading]);

    return (
        <>
            <Helmet>
                <title>Tipo Admisión</title>
            </Helmet>
            {loading ? (
                <div>Cargando...</div>
            ) : (
                <div style={{ maxWidth: "1200px", margin: "50px auto" }}>
                    <div style={{ width: "100%", overflowX: "auto" }}>
                        <td>
                            <Button
                                color="primary"
                                onClick={() =>
                                    handleButtonClick({
                                        title: "Crear tipo de Admisión",
                                        footer: (
                                            <Button
                                                className="mb-3"
                                                color="secondary"
                                                onClick={handleModalToggle}
                                            >
                                                Cancelar
                                            </Button>
                                        ),
                                    })
                                }
                            >
                                Crear Tipo de Admisión
                            </Button>
                        </td>
                        <div style={{ height: "20px" }}></div>
                        <table
                            ref={tableRef}
                            id="myTable"
                            className="table"
                            style={{ width: "100%" }}
                        >
                            <thead>
                                <tr>
                                    <th>Editar</th>
                                    <th>Eliminar</th>
                                    <th>Tipo de Admisión</th>
                                    <th>Usuario creación</th>
                                    <th>Fecha de creación</th>
                                    <th>Usuario actualización</th>
                                    <th>Fecha Actualización</th>
                                    ...
                                </tr>
                            </thead>
                            <tbody>
                                {tipoAdmision.map((element) => (
                                    <tr key={element.id}>
                                        <td>
                                            <Button
                                                className="mb-3"
                                                color="secondary"
                                                onClick={() =>
                                                    handleEditClick(element, {
                                                        title: "Actualizar tipo de Admisión",
                                                        footer: (
                                                            <Button
                                                                className="mb-3"
                                                                color="secondary"
                                                                onClick={
                                                                    handleModalToggle
                                                                }
                                                            >
                                                                Cancelar
                                                            </Button>
                                                        ),
                                                    })
                                                }
                                            >
                                                Editar
                                            </Button>
                                        </td>
                                        <td>
                                            <Button
                                                color="danger"
                                                onClick={() =>
                                                    handleDeleteClick(element)
                                                }
                                            >
                                                Eliminar
                                            </Button>
                                        </td>
                                        <td>{element.Texto}</td>
                                        <td>
                                            {element.created_by
                                                ? element.created_by
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.created_at
                                                ? element.created_at
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.updated_by
                                                ? element.updated_by
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.updated_at
                                                ? element.updated_at
                                                : "No existe información"}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <ModalCreate
                            isOpen={modalOpen}
                            toggle={handleModalToggle}
                            title={modalContent.title}
                            content={modalContent.content}
                            footer={modalContent.footer}
                            token={token}
                        />
                        <ModalEdit
                            isOpen={modalEditOpen}
                            toggle={handleEditModalToggle}
                            title={modalTittleContent.title}
                            id={modalEditcontent.id}
                            textoInicial={modalEditcontent.Texto}
                            footer={modalEditcontent.footer}
                            token={token}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default withTokenCheck(AppTipoAdmision);
