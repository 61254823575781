import { TextField } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import FormHelperText from "@mui/material/FormHelperText";

const ModalCreate = ({ isOpen, toggle, title, formId, token }) => {
  const [tipoServicio, setTipoServicio] = useState([]);
  const [selectedTipoServicio, setSelectedTipoServicio] = useState("");
  const [selectedTipoServicio2, setSelectedTipoServicio2] = useState("");
  const [tipoAdmision, setTipoAdmision] = useState([]);
  const [selectedTipoAdmision, setSelectedTipoAdmision] = useState("");
  const [selectedTipoAdmision2, setSelectedTipoAdmision2] = useState("");
  const [ips, setIps] = useState([]);
  const [selecteIps, setSelectedIps] = useState("");
  const [selectedIps2, setSelectedIps2] = useState("");
  const [fechaConsulta, setFechaconsulta] = useState("");
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);
  useEffect(() => {
    if(token){
    axios
      .get("/tiposervicio", { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setTipoServicio(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, [token]);

  useEffect(() => {
    if(token){
    axios
      .get("/ips", { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setIps(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, [token]);

  useEffect(() => {
    if(token){
    axios
      .get("/tipoadmision", { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setTipoAdmision(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, [token]);

  const servicioOptions = tipoServicio.map((option) => ({
    value: option.id,
    label: option.Texto ?? option.Texto,
  }));

  const ipsOptions = ips.map((option) => ({
    value: option.id,
    label: option.Texto ?? option.Texto,
  }));

  const admisionOptions = tipoAdmision.map((option) => ({
    value: option.id,
    label: option.Texto ?? option.Texto,
  }));

  const handleTipoServicioChange = (selectedOption) => {
    setSelectedTipoServicio2(selectedOption.value);
    setSelectedTipoServicio(
      servicioOptions.find((option) => option.value === selectedOption.value)
    );
  };

  const handleIpsChange = (selectedOption) => {
    setSelectedIps2(selectedOption.value);
    setSelectedIps(
      ipsOptions.find((option) => option.value === selectedOption.value)
    );
  };

  const handleTipoadmisionChange = (selectedOption) => {
    setSelectedTipoAdmision2(selectedOption.value);
    setSelectedTipoAdmision(
      tipoAdmision.find((option) => option.value === selectedOption.value)
    );
  };

  const handleFechaChange = (event) => {
    setFechaconsulta(event.target.value);
  };

  const handleCreateSubmit = (event) => {
    event.preventDefault();

    // Enviar los datos del paciente y de los acompañantes
    axios
      .post('/edicion-formulario/tiposervicio', {
        FormularioId: formId,
        TipoServicioId: selectedTipoServicio2,
        IpsId: selectedIps2,
        TipoAdmisionId: selectedTipoAdmision2,
        FechaConsulta: fechaConsulta,
      }, {headers: { Authorization: `Bearer ${token}` }})
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Creado!",
          text: "el tipo de servicio ha sido creada con exito.",
          showCloseButton: true,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Ha ocurrido un error al crear el tipo de servicio.",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label htmlFor="servicioId">Tipo de servicio:</label>
          <Select
            value={selectedTipoServicio}
            onChange={handleTipoServicioChange}
            options={servicioOptions}
          ></Select>
          <div style={{ wordBreak: "break-word" }}>
            <FormHelperText>
              {"Hola"/* {servicioOptions.find(
                (option) => option.value === input.tipoServicio
              )?.label || ""} */}
            </FormHelperText>
          </div>
        </FormGroup>
        <FormGroup>
          <label htmlFor="servicioId">IPS que practica:</label>
          <Select
            value={selecteIps}
            onChange={handleIpsChange}
            options={ipsOptions}
          ></Select>
        </FormGroup>
        <FormGroup>
          <label htmlFor="servicioId">Tipo de admisión:</label>
          <Select
            value={selectedTipoAdmision}
            onChange={handleTipoadmisionChange}
            options={admisionOptions}
          ></Select>
        </FormGroup>
        <FormGroup>
          <TextField
            label="Fecha de consulta"
            className="form-control "
            type="Date"
            name="fechaConsulta"
            value={fechaConsulta}
            onChange={handleFechaChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleCreateSubmit} color="primary" otype="submit">
          Insertar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalCreate;
