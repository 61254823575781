import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const ModalEdit = ({
    isOpen,
    toggle,
    title,
    token,
    selectedRows,
    SolicitudId,
    updateTable,
    horaSalidaMasivo,
    setHoraSalidaMasivo,
}) => {
    const [formMode, setFormMode] = useState("none");
    const modalRef = useRef(null);
    const [conductores, setconductores] = useState([]);
    const [vehiculos, setVehiculos] = useState([]);
    const [selectedConductor, setSelectedConductor] = useState("");
    const [selectedConductor2, setSelectedConductor2] = useState("");
    const [selectedVehiculo, setSelectedVehiculo] = useState("");
    const [selectedVehiculo2, setSelectedVehiculo2] = useState("");
    const [solicitudesSelected, setSolicitudesSelected] = useState([]);

    useEffect(() => {
        setSolicitudesSelected(selectedRows);
    }, [selectedRows]);

    useEffect(() => {
        if (token) {
            axios
                .get("/conductor", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setconductores(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            axios
                .get("/vehiculo", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setVehiculos(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const handleTimeEndChange = (newTime) => {
        const timeZone = "America/Bogota";
        const horas = newTime.getHours();
        const minutos = newTime.getMinutes();
        const segundos = newTime.getSeconds();
        const formattedTime = `${horas.toString().padStart(2, "0")}:${minutos
            .toString()
            .padStart(2, "0")}:${segundos.toString().padStart(2, "0")}`;
        setHoraSalidaMasivo(formattedTime);
    };

    const conductoresOptions = conductores.map((option) => ({
        value: option.id,
        label: option.Nombre ?? option.Nombre,
    }));

    const vehiculosOptions = vehiculos.map((option) => ({
        value: option.id,
        label: option.Placa ?? option.Placa + option.Referencia,
    }));

    const handleConductorChange = (selectedOption) => {
        setSelectedConductor2(selectedOption.value);
        setSelectedConductor(selectedOption);
    };

    const handleVehiucloChange = (selectedOption) => {
        setSelectedVehiculo2(selectedOption.value);
        setSelectedVehiculo(selectedOption);
    };

    const handleEditSubmit = (event) => {
        event.preventDefault();
        axios
            .post(
                `/programacionformulario/asignarTransporte`,
                {
                    SolicitudesId: solicitudesSelected,
                    ConductorId: selectedConductor,
                    VehiculoId: selectedVehiculo,
                    HoraSalida: horaSalidaMasivo,
                },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: "Actualizado!",
                    text: "el transporte a sido asignado con exito.",
                    showCloseButton: true,
                }).then(() => {
                    updateTable();
                    toggle();
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "El transporte no se pudo asignar",
                    showCloseButton: true,
                });
            });
    };

    return (
        <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <label htmlFor="ServicioHogar">Conductor:</label>
                    <Select
                        options={conductoresOptions}
                        onChange={(selectedOption) =>
                            handleConductorChange(selectedOption.value)
                        }
                    ></Select>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="ServicioHogar">Vehiculo:</label>
                    <Select
                        options={vehiculosOptions}
                        onChange={(selectedOption) =>
                            handleVehiucloChange(selectedOption.value)
                        }
                    ></Select>
                </FormGroup>
                <FormGroup>
                    <label>Hora de salida:</label>
                    <br />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                            value={horaSalidaMasivo}
                            onChange={handleTimeEndChange}
                        />
                    </LocalizationProvider>
                </FormGroup>
            </ModalBody>

            <ModalFooter>
                <Button
                    onClick={handleEditSubmit}
                    color="primary"
                    otype="submit"
                >
                    Asginar
                </Button>
                <Button color="danger" onClick={() => setFormMode("none")}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalEdit;
