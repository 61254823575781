import { TextField } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";

const ModalEdit = ({ isOpen, toggle, title, id, serviceInitial, fechaInicial, ipsInitial, admision, servicioMaestroId, servicioDetalleId, token }) => {
  const [tipoServicio, setTipoServicio] = useState([]);
  const [selectedTipoServicio, setSelectedTipoServicio] = useState("");
  const [selectedTipoServicio2, setSelectedTipoServicio2] = useState("");
  const [tipoAdmision, setTipoAdmision] = useState([]);
  const [selectedTipoAdmision, setSelectedTipoAdmision] = useState("");
  const [selectedTipoAdmision2, setSelectedTipoAdmision2] = useState("");
  const [ips, setIps] = useState([]);
  const [selectedIps, setSelectedIps] = useState("");
  const [selectedIps2, setSelectedIps2] = useState("");
  const [fechaConsulta, setFechaconsulta] = useState("");
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);
  
  useEffect(() => {
    if(token){
    axios
      .get("/tiposervicio", { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setTipoServicio(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, [token]);

  useEffect(() => {
    if(token){
    axios
      .get("/ips", { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setIps(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, [token]);

  useEffect(() => {
    if(token){
    axios
      .get("/tipoadmision", { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setTipoAdmision(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, [token]);

  const servicioOptions = tipoServicio.map((option) => ({
    value: option.id,
    label: option.Texto ?? option.Texto,
  }));

  const ipsOptions = ips.map((option) => ({
    value: option.id,
    label: option.Texto ?? option.Texto,
  }));

  const admisionOptions = tipoAdmision.map((option) => ({
    value: option.id,
    label: option.Texto ?? option.Texto,
  }));

  const handleTipoServicioChange = (selectedOption) => {
    setSelectedTipoServicio2(selectedOption.value);
    setSelectedTipoServicio(
      servicioOptions.find((option) => option.value === selectedOption.value)
    );
  };

  const handleIpsChange = (selectedOption) => {
    setSelectedIps2(selectedOption.value);
    setSelectedIps(
      ipsOptions.find((option) => option.value === selectedOption.value)
    );
  };

  const handleTipoadmisionChange = (selectedOption) => {
    setSelectedTipoAdmision2(selectedOption.value);
    setSelectedTipoAdmision(
      tipoAdmision.find((option) => option.value === selectedOption.value)
    );
  };

  

  const handleFechaChange = (event) => {
    setFechaconsulta(event.target.value);
  };
  const handleEditSubmit = (event) => {
    event.preventDefault();
    axios
      .put(`/edicion-formulario/tiposervicio/${id}`, {
        FormularioId: id,
        ServicioMaestroId: servicioMaestroId,
        id: servicioDetalleId,
        TipoServicioId: selectedTipoServicio2 ? selectedTipoServicio2 : serviceInitial.id,
        IpsId: selectedIps2 ? selectedIps2 : ipsInitial,
        TipoAdmisionId: selectedTipoAdmision2 ? selectedTipoAdmision2 : admision,
        FechaConsulta: fechaConsulta ? fechaConsulta : fechaInicial,
      }, {headers: { Authorization: `Bearer ${token}` }})
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Actualizado!",
          text: "el tipo de servicio ha sido actualizada con exito.",
          showCloseButton: true,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "El tipo de servicio no se pudo actualizar",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label htmlFor="servicioId">Tipo de servicio:</label>
          <Select
            value={selectedTipoServicio ? selectedTipoServicio : servicioOptions.find((option) => option.value === serviceInitial.id)}
            onChange={handleTipoServicioChange}
            options={servicioOptions}
          ></Select>
        </FormGroup>
        <FormGroup>
          <label htmlFor="servicioId">IPS que practica:</label>
          <Select
            value={selectedIps ? selectedIps : ipsOptions.find((option) => option.value === ipsInitial)}
            onChange={handleIpsChange}
            options={ipsOptions}
          ></Select>
        </FormGroup>
        <FormGroup>
          <label htmlFor="servicioId">Tipo de admisión:</label>
          <Select
            value={selectedTipoAdmision ? selectedTipoAdmision : admisionOptions.find((option) => option.value === admision)}
            onChange={handleTipoadmisionChange}
            options={admisionOptions}
          ></Select>
        </FormGroup>
        <FormGroup>
          <TextField
            label="Fecha de consulta"
            className="form-control "
            type="Date"
            name="fechaConsulta"
            value={fechaConsulta ? fechaConsulta : fechaInicial}
            onChange={handleFechaChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleEditSubmit} color="primary" otype="submit">
          Editar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalEdit;
