import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
    Button,
    Container,
    Modal,
    ModalBody,
    ModalHeader,
    FormGroup,
    ModalFooter,
    Input,
    Col,
    Row,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { makeStyles } from "@material-ui/core/styles";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
} from "@material-ui/core";
import ExcelJS from "exceljs";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import withTokenCheck from "../withTokenCheck";

const useStyles = makeStyles({
    tableContainer: {
        maxHeight: 500,
    },
    tableCell: {
        textAlign: "left",
    },
    button: {
        marginRight: 10,
    },
});

const AppCase = () => {
    const [authorization, setAuthorization] = useState([]);
    const [selectedAuthorization, setSelectedAuthorization] = useState(null);
    const [numeroCaso, setNumeroCaso] = useState("");
    const [fechaSolicitud, setFechaSolicitud] = useState("");
    const [descripcion, setDescripcion] = useState([]);
    const [SelectedDescripcion, setSelectedDescripcion] = useState("");
    const [tipoSolicitud, setTipoSolicitud] = useState([]);
    const [selectedTipoSolicitud, setSelectedTipoSolicitud] = useState("");
    const [fechaInicial, setFechaInicial] = useState("");
    const [fechaFinal, setFechaFinal] = useState("");
    const [hogar, setHogar] = useState([]);
    const [selectedHogar, setSelectedHogar] = useState("");
    const [paciente, setPaciente] = useState([]);
    const [selectedPaciente, setSelectedPaciente] = useState("");
    const [numeroMipres, setNumeroMipres] = useState("");
    const [servicioMaestro, setServicioMaestro] = useState([]);
    const [selectedServicioMaestro, setSelectedServicioMaestro] = useState("");
    const [observaciones, setObservaciones] = useState("");
    const [formMode, setFormMode] = useState("none");
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState("");
    const tableRef = useRef(null);

    // Obtener todas las ciudades al cargar el componente
    useEffect(() => {
        axios
            .get("/autorizacion")
            .then((response) => {
                setAuthorization(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .get("/descripcion")
            .then((response) => {
                setDescripcion(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .get("/tiposolicitud")
            .then((response) => {
                setTipoSolicitud(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .get("/hogar")
            .then((response) => {
                setHogar(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .get("/paciente")
            .then((response) => {
                setPaciente(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .get("/serviciomaestro")
            .then((response) => {
                setServicioMaestro(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    // Manejar el envío del formulario para crear una nueva ciudad
    const handleCreateSubmit = (event) => {
        event.preventDefault();
        axios
            .post("/autorizacion", {
                NumeroCaso: parseInt(numeroCaso),
                FechaSolicitud: fechaSolicitud,
                DescripcionId: parseInt(descripcion),
                TipoSolicitudId: parseInt(tipoSolicitud),
                FechaInicial: fechaInicial,
                FechaFinal: fechaFinal,
                HogarId: parseInt(hogar),
                PacienteId: parseInt(paciente),
                NumeroMIPRES: numeroMipres,
                ServicioMaestroId: servicioMaestro,
                Observaciones: observaciones,
            })
            .then((response) => {
                setAuthorization([...authorization, response.data]);
                setDescripcion([...descripcion, response.data.DescripcionId]);
                setTipoSolicitud([
                    ...tipoSolicitud,
                    response.data.TipoSolicitudId,
                ]);
                setHogar([...hogar, response.data.HogarId]);
                setPaciente([...paciente, response.data.PacienteId]);
                setServicioMaestro([
                    ...servicioMaestro,
                    response.data.ServicioMaestroId,
                ]);
                setNumeroCaso("");
                setFechaSolicitud("");
                setFechaInicial("");
                setFechaFinal("");
                setNumeroMipres("");
                setObservaciones("");
                setFormMode("none");
                Swal.fire({
                    icon: "success",
                    title: "Guardado!",
                    text: "La autorización ha sido guardada con exito.",
                    showCloseButton: true,
                });
            })

            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "Ha ocurrido un error al crear la autorización.",
                });
            });
    };

    // Manejar el envío del formulario para editar una ciudad existente
    const handleEditSubmit = (event) => {
        event.preventDefault();
        axios
            .put(`/autorizacion/${selectedAuthorization.id}`, {
                NumeroCaso: parseInt(numeroCaso),
                FechaSolicitud: fechaSolicitud,
                DescripcionId: parseInt(descripcion),
                TipoSolicitudId: parseInt(tipoSolicitud),
                FechaInicial: fechaInicial,
                FechaFinal: fechaFinal,
                HogarId: parseInt(hogar),
                PacienteId: parseInt(paciente),
                NumeroMIPRES: numeroMipres,
                ServicioMaestroId: servicioMaestro,
                Observaciones: observaciones,
            })
            .then((response) => {
                setAuthorization(
                    authorization.map((c) =>
                        c.id === selectedAuthorization.id ? response.data : c
                    )
                );
                setDescripcion(
                    descripcion.map((c) =>
                        c.id === SelectedDescripcion.id ? response.data : c
                    )
                );
                setTipoSolicitud(
                    tipoSolicitud.map((c) =>
                        c.id === selectedTipoSolicitud.id ? response.data : c
                    )
                );
                setHogar(
                    hogar.map((c) =>
                        c.id === selectedHogar.id ? response.data : c
                    )
                );
                setPaciente(
                    paciente.map((c) =>
                        c.id === selectedPaciente.id ? response.data : c
                    )
                );
                setServicioMaestro(
                    servicioMaestro.map((c) =>
                        c.id === selectedServicioMaestro.id ? response.data : c
                    )
                );
                setNumeroCaso("");
                setFechaSolicitud("");
                setFechaInicial("");
                setFechaFinal("");
                setNumeroMipres("");
                setObservaciones("");
                setFormMode("none");
                Swal.fire({
                    icon: "success",
                    title: "Actualizado!",
                    text: "La autorización ha sido actualizada con exito.",
                    showCloseButton: true,
                });
            })

            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: error.response.data.message,
                    showCloseButton: true,
                });
            });
    };

    // Manejar el clic en el botón "Editar"
    const handleEditClick = (authorization) => {
        setSelectedAuthorization(authorization);
        setNumeroCaso(authorization.NumeroCaso);
        setFechaSolicitud(authorization.FechaSolicitud);
        setSelectedDescripcion(authorization.DescripcionId);
        setSelectedTipoSolicitud(authorization.TipoSolicitudId);
        setFechaInicial(authorization.FechaInicial);
        setFechaFinal(authorization.FechaFinal);
        setSelectedHogar(authorization.HogarId);
        setSelectedPaciente(authorization.PacienteId);
        setNumeroMipres(authorization.NumeroMIPRES);
        setSelectedServicioMaestro(authorization.ServicioMaestroId);
        setObservaciones(authorization.Observaciones);
        setFormMode("edit");
    };

    // Manejar el clic en el botón "Borrar"
    const handleDeleteClick = (authorizationToDelete) => {
        axios
            .delete(`/autorizacion/${authorizationToDelete.id}`)
            .then((response) => {
                setAuthorization(
                    authorization.filter(
                        (authorization) => authorization.id !== authorization.id
                    )
                );
                Swal.fire({
                    icon: "success",
                    title: "Eliminado!",
                    text: "La autorizacion ha sido eliminado con exito.",
                    showCloseButton: true,
                });
            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "Ha ocurrido un error al eliminar el hogar.",
                });
            });
    };

    // Manejar el cambio en el campo de descripción
    const handleNumeroCasoChange = (event) => {
        setNumeroCaso(event.target.value);
    };
    const handleFechaSolicitudChange = (event) => {
        setFechaSolicitud(event.target.value);
    };
    const handleDescripcionChange = (event) => {
        setSelectedDescripcion(event.target.value);
    };
    const handleTipoSolicitudChange = (event) => {
        setSelectedTipoSolicitud(event.target.value);
    };
    const handleFechaInicialChange = (event) => {
        setFechaInicial(event.target.value);
    };
    const handleFechaFinalChange = (event) => {
        setFechaFinal(event.target.value);
    };
    const handleHogarChange = (event) => {
        setSelectedHogar(event.target.value);
    };
    const handlePacienteChange = (event) => {
        setSelectedPaciente(event.target.value);
    };
    const handleNumeroMipresChange = (event) => {
        setNumeroMipres(event.target.value);
    };
    const handleServicioMaestroChange = (event) => {
        setSelectedServicioMaestro(event.target.value);
    };
    const handleObservacionesChange = (event) => {
        setObservaciones(event.target.value);
    };

    const handleCreateClick = () => {
        setNumeroCaso("");
        setFechaSolicitud("");
        setFechaInicial("");
        setFechaFinal("");
        setNumeroMipres("");
        setObservaciones("");
        setFormMode("create");
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setPage(0);
    };

    const filteredData = authorization.filter((item) =>
        item.NumeroCaso.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleExport = () => {
        const columnsToExport = [
            "NumeroCaso",
            "FechaSolicitud",
            "FechaInicial",
            "FechaFinal",
        ]; // Reemplaza con los nombres de las columnas que deseas exportar
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Data");

        // Add headers
        columnsToExport.forEach((column, index) => {
            worksheet.getCell(1, index + 1).value = column;
        });

        // Add data
        authorization.forEach((row, rowIndex) => {
            columnsToExport.forEach((column, columnIndex) => {
                worksheet.getCell(rowIndex + 2, columnIndex + 1).value =
                    row[column];
            });
        });

        // Generate XLSX file
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Autorización.xlsx");
            document.body.appendChild(link);
            link.click();
        });
    };

    return (
        <>
            <Container>
                <br />
                <Button color="primary" onClick={handleCreateClick}>
                    Crear Autorización
                </Button>
                <br />
                <br />
                <TableContainer className={classes.tableContainer}>
                    <Row>
                        <Col md={6}>
                            <Button id="btn-export" onClick={handleExport}>
                                Export to XLSX
                            </Button>
                        </Col>
                        <Col md={6}>
                            <Input
                                className="text-rigth"
                                type="text"
                                placeholder="filtrar por nombre"
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </Col>
                    </Row>
                    <Table className="mt-3" ref={tableRef} stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableCell}>
                                    Numero de Caso
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    Fecha de Solicitud
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    Descripción
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    Tipo de solicitud
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    Fecha Inicial
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    Fecha Final
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    Prestador
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    Paciente
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    Numero MIPRES
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    Servicio
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    Observaciones
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    Acciones
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((element) => (
                                    <TableRow key={element.id}>
                                        <TableCell
                                            className={classes.tableCell}
                                        >
                                            {element.NumeroCaso}
                                        </TableCell>
                                        <TableCell>
                                            {element.FechaSolicitud}
                                        </TableCell>
                                        <TableCell>
                                            {element.DescripcionId}
                                        </TableCell>
                                        <TableCell>
                                            {element.tipoSolicitud.Texto}
                                        </TableCell>
                                        <TableCell>
                                            {element.FechaInicial}
                                        </TableCell>
                                        <TableCell>
                                            {element.FechaFinal}
                                        </TableCell>
                                        <TableCell>
                                            {element.hogar.Nombre}
                                        </TableCell>
                                        <TableCell>
                                            {element.paciente.Nombre}
                                        </TableCell>
                                        <TableCell>
                                            {element.NumeroMIPRES}
                                        </TableCell>
                                        <TableCell>
                                            {element.servicioMaestro.Texto}
                                        </TableCell>
                                        <TableCell>
                                            {element.Observaciones}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                color="primary"
                                                onClick={() =>
                                                    handleEditClick(element)
                                                }
                                            >
                                                Editar
                                            </Button>
                                            <Button
                                                color="danger"
                                                onClick={() =>
                                                    handleDeleteClick(element)
                                                }
                                            >
                                                Eliminar
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Container>
            <Modal isOpen={formMode === "create"} toggle={handleCreateClick}>
                <ModalHeader toggle={() => setFormMode("none")}>
                    Crear Autorización
                </ModalHeader>
                <form onSubmit={handleCreateSubmit}>
                    <ModalBody>
                        <FormGroup>
                            <label>Numero Caso:</label>
                            <input
                                className="form-control"
                                name="numeroCaso"
                                type="text"
                                value={numeroCaso}
                                onChange={handleNumeroCasoChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Fecha de Solicitud:</label>
                            <input
                                className="form-control"
                                name="fechaSolicitud"
                                type="date"
                                value={fechaSolicitud}
                                onChange={handleFechaSolicitudChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="DescripcionId">Descripcion:</label>
                            <Select
                                fullWidth
                                labelId="Descripcion-id-label"
                                id="ciudadId"
                                value={SelectedDescripcion}
                                onChange={handleDescripcionChange}
                                label="Descripcion"
                            >
                                {descripcion.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.Texto ?? option.Texto}{" "}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="SolicitudId">
                                Tipo de Solicitud:
                            </label>
                            <Select
                                fullWidth
                                labelId="solicitud-id-label"
                                id="solicitudId"
                                value={selectedTipoSolicitud}
                                onChange={handleTipoSolicitudChange}
                                label="TipoSolicitud"
                            >
                                {tipoSolicitud.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.Texto ?? option.Texto}{" "}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormGroup>
                        <FormGroup>
                            <label>Fecha Inicial:</label>
                            <input
                                className="form-control"
                                name="fechaInicial"
                                type="date"
                                value={fechaInicial}
                                onChange={handleFechaInicialChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Fecha Final:</label>
                            <input
                                className="form-control"
                                name="fechaFinal"
                                type="date"
                                value={fechaFinal}
                                onChange={handleFechaFinalChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="HogarId">Prestador:</label>
                            <Select
                                fullWidth
                                labelId="prestador-id-label"
                                id="PrestadorId"
                                value={selectedHogar}
                                onChange={handleHogarChange}
                                label="Hogar"
                            >
                                {hogar.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.Texto ?? option.Texto}{" "}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="PacienteId">Paciente:</label>
                            <Select
                                fullWidth
                                labelId="paciente-id-label"
                                id="PacienteId"
                                value={selectedPaciente}
                                onChange={handlePacienteChange}
                                label="TipoSolicitud"
                            >
                                {paciente.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.Texto ?? option.Texto}{" "}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormGroup>
                        <FormGroup>
                            <label>Numero MIPRES:</label>
                            <input
                                className="form-control"
                                name="NumeroMipres"
                                type="text"
                                value={numeroMipres}
                                onChange={handleNumeroMipresChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="ServicioId">Servicio:</label>
                            <Select
                                fullWidth
                                labelId="servicio-id-label"
                                id="ServicioId"
                                value={selectedServicioMaestro}
                                onChange={handleServicioMaestroChange}
                                label="servicioMaestro"
                            >
                                {servicioMaestro.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.Texto ?? option.Texto}{" "}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormGroup>
                        <FormGroup>
                            <label>Observaciones:</label>
                            <input
                                className="form-control"
                                name="Observaciones"
                                type="text"
                                value={observaciones}
                                onChange={handleObservacionesChange}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" otype="submit">
                            Insertar
                        </Button>
                        <Button
                            color="danger"
                            onClick={() => setFormMode("none")}
                        >
                            Cancelar
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>
            <Modal isOpen={formMode === "edit"} toggle={handleEditClick}>
                <ModalHeader toggle={handleEditClick}>Editar Hogar</ModalHeader>
                <form onSubmit={handleEditSubmit}>
                    <ModalBody>
                        <FormGroup>
                            <label>Numero de Caso:</label>
                            <input
                                className="form-control"
                                name="numeroCaso"
                                type="text"
                                onChange={handleNumeroCasoChange}
                                value={numeroCaso}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Fecha Solicitud:</label>
                            <input
                                className="form-control"
                                name="fechaSolicitud"
                                type="date"
                                onChange={handleFechaSolicitudChange}
                                value={fechaSolicitud}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Descripcion:</label>
                            <input
                                className="form-control"
                                name="descripcion"
                                type="text"
                                onChange={handleDescripcionChange}
                                value={descripcion}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Tipo de Soliciutd:</label>
                            <input
                                className="form-control"
                                name="tipoSolicitud"
                                type="text"
                                onChange={handleTipoSolicitudChange}
                                value={tipoSolicitud}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Fecha Inicial:</label>
                            <input
                                className="form-control"
                                name="fechaInicial"
                                type="date"
                                onChange={handleFechaInicialChange}
                                value={fechaInicial}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Fecha Final:</label>
                            <input
                                className="form-control"
                                name="fechaFinal"
                                type="date"
                                onChange={handleFechaFinalChange}
                                value={fechaFinal}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Hogar:</label>
                            <input
                                className="form-control"
                                name="hogar"
                                type="text"
                                onChange={handleHogarChange}
                                value={hogar}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Paciente:</label>
                            <input
                                className="form-control"
                                name="paciente"
                                type="text"
                                onChange={handlePacienteChange}
                                value={paciente}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Numero MIPRES:</label>
                            <input
                                className="form-control"
                                name="numeroMipres"
                                type="text"
                                onChange={handleNumeroMipresChange}
                                value={numeroMipres}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Servicio:</label>
                            <input
                                className="form-control"
                                name="servicio"
                                type="text"
                                onChange={handleServicioMaestroChange}
                                value={servicioMaestro}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Observaciones:</label>
                            <input
                                className="form-control"
                                name="observaciones"
                                type="text"
                                onChange={handleObservacionesChange}
                                value={observaciones}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            Guardar
                        </Button>
                        <Button
                            color="danger"
                            onClick={() => setFormMode("none")}
                        >
                            Cancelar
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    );
};

export default withTokenCheck(AppCase);
