import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@material-ui/core";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";

const ModalEdit = ({
  isOpen,
  toggle,
  title,
  token,
  HogarId,
  FormularioId,
  Nombre,
  Celular,
  RutaTraslado,
  HoraCita,
  HoraSalida,
  Observacion,
  TipoServicioId,
  SolicitudId,
  ConductorId,
  VehiculoId,
  updateTable,
}) => {
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);
  const [hogarTraslado, setHogarTraslado] = useState([]);
  const [numeroCaso, setNumeroCaso] = useState([]);
  const [rutaTraslado, setRutraTraslado] = useState("");
  const [horaCita, setHoraCita] = useState("");
  const [horaSalida, setHoraSalida] = useState("");
  const [horaCitaInicial, setHoraCitaInicial] = useState("");
  const [horaSalidaInicial, setHoraSalidaInicial] = useState("");
  const [horaCitaApi, setHoraCitaApi] = useState("");
  const [horaSalidaApi, setHoraSalidaApi] = useState("");
  const [tipoServicioHogar, setTipoServicioHogar] = useState([]);
  const [selectedServicioHogar, setSelectedServicioHogar] = useState("");
  const [selectedServicioHogar2, setSelectedServicioHogar2] = useState("");
  const [selectedHogarTraslado, setSelectedHogarTraslado] = useState("");
  const [selectedHogarTraslado2, setSelectedHogarTraslado2] = useState("");
  const [selectedNumeroCaso, setSelectedNumeroCaso] = useState("");
  const [selectedNumeroCaso2, setSelectedNumeroCaso2] = useState("");
  const [placaVehiculo, setPlacaVehiculo] = useState("");
  const [detallesTransporteExterno, setDetallesTranposrteExterno] =
    useState("");
  const [servicios, setServicios] = useState([]);
  const [selectedServicio, setSelectedServicio] = useState("");
  const [selectedServicio2, setSelectedServicio2] = useState("");
  const [transporteExterno, setTransporteExterno] = useState(false);
  const [conductor, setConductor] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [conductores, setconductores] = useState([]);
  const [vehiculos, setVehiculos] = useState([]);
  const [selectedConductor, setSelectedConductor] = useState("");
  const [selectedConductor2, setSelectedConductor2] = useState("");
  const [selectedVehiculo, setSelectedVehiculo] = useState("");
  const [selectedVehiculo2, setSelectedVehiculo2] = useState("");
  const [selectedSolicitudesId, setSelectedSolicitudesId] = useState([]);

    useEffect(() => {
    setRutraTraslado(RutaTraslado);
    setObservaciones(Observacion);
  }, [Nombre, Celular, RutaTraslado, Observacion]);

  useEffect(() => {
    if (token) {
      axios
        .get("/conductor", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setconductores(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      axios
        .get("/vehiculo", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setVehiculos(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  const conductoresOptions = conductores.map((option) => ({
    value: option.id,
    label: option.Nombre ?? option.Nombre,
  }));

  const vehiculosOptions = vehiculos.map((option) => ({
    value: option.id,
    label: option.Placa ?? option.Placa + option.Referencia,
  }));

  const handleConductorChange = (selectedOption) => {
    setSelectedConductor2(selectedOption.value);
    setSelectedConductor(selectedOption);
  };

  const handlevehiculoChange = (selectedOption) => {
    setSelectedVehiculo2(selectedOption.value);
    setSelectedVehiculo(selectedOption);
  };

  useEffect(() => {
    // Parse la hora militar en un objeto Date
    const horaCitaDate = new Date(`1970-01-01T${HoraCita}`);
    const horaSalidaDate = new Date(`1970-01-01T${HoraSalida}`);
    setHoraSalidaInicial(horaSalidaDate);
    setHoraCitaInicial(horaCitaDate);
    const horas = horaCitaDate.getHours();
    const minutos = horaCitaDate.getMinutes();
    const segundos = horaCitaDate.getSeconds();
    const formattedTime = `${horas.toString().padStart(2, "0")}:${minutos
      .toString()
      .padStart(2, "0")}:${segundos.toString().padStart(2, "0")}`;
    setHoraCitaApi(formattedTime);
    const horasS = horaSalidaDate.getHours();
    const minutosS = horaSalidaDate.getMinutes();
    const segundosS = horaSalidaDate.getSeconds();
    const formattedTimeS = `${horasS.toString().padStart(2, "0")}:${minutosS
      .toString()
      .padStart(2, "0")}:${segundosS.toString().padStart(2, "0")}`;
    setHoraSalidaApi(formattedTimeS);
  }, [HoraCita]);

  const handleTimeChange = (newTime) => {
    const timeZone = "America/Bogota";
    const horas = newTime.getHours();
    const minutos = newTime.getMinutes();
    const segundos = newTime.getSeconds();
    const formattedTime = `${horas.toString().padStart(2, "0")}:${minutos
      .toString()
      .padStart(2, "0")}:${segundos.toString().padStart(2, "0")}`;
    setHoraCita(formattedTime);
  };

  const handleTimeEndChange = (newTime) => {
    const timeZone = "America/Bogota";
    const horas = newTime.getHours();
    const minutos = newTime.getMinutes();
    const segundos = newTime.getSeconds();
    const formattedTime = `${horas.toString().padStart(2, "0")}:${minutos
      .toString()
      .padStart(2, "0")}:${segundos.toString().padStart(2, "0")}`;
    setHoraSalida(formattedTime);
  };

  useEffect(() => {
    if (token) {
      axios
        .get("/programacionformulario/case-number", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setNumeroCaso(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      axios
        .get("/tiposerviciohogar", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setTipoServicioHogar(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      axios
        .get("/hogartraslado", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setHogarTraslado(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  useEffect(() => {
    if (token && FormularioId) {
      axios
        .get(`/formulario/consulta/servicios/${FormularioId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const servicioDetalle =
            response.data ?? response.data.servicio_maestro;

          setServicios(servicioDetalle);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token, FormularioId]);
/* 
  const numeroCasoOptions = numeroCaso.map((option) => ({
    value: option.Id,
    label: option.NumeroCaso ?? option.NumeroCaso,
  })); */

  const hogarTrasladoOptions = hogarTraslado.map((option) => ({
    value: option.id,
    label: option.Nombre ?? option.Nombre,
  }));

  const tipoServicioHogarOptions = tipoServicioHogar.map((option) => ({
    value: option.id,
    label: option.Texto ?? option.Texto,
  }));

  const servicioDiscriminado = servicios.flatMap((option) => {
    if (option.servicio_maestro && option.servicio_maestro.FormularioId) {
      return option.servicio_maestro.servicio_detalle.map((detalle) => ({
        value: detalle.id,
        label: detalle.tipo_servicio.Texto,
      }));
    } else {
      return [
        {
          value: "N.A",
          label: "N.A",
        },
      ];
    }
  });


  const handleTipoServicioHogarChange = (selectedOption) => {
    setSelectedServicioHogar2(selectedOption.value);
    setSelectedServicioHogar(selectedOption);
  };
  

  const handleHogarTrasladoChange = (selectedOption) => {
    setSelectedHogarTraslado2(selectedOption.value);
    setSelectedHogarTraslado(selectedOption);
  };

  const handleRutaTrasladoChange = (event) => {
    setRutraTraslado(event.target.value);
  };
  const handleObservacionesChange = (event) => {
    setObservaciones(event.target.value);
  };
  const handleTransporteExternoChange = (event) => {
    setDetallesTranposrteExterno(event.target.value);
  };

  const handleEditSubmit = (event) => {
    event.preventDefault();
    axios
      .post(
        `/programacionformulario/asignarTransporte`,
        {
          SolicitudesId: [SolicitudId],
          HogarTrasladoId: HogarId,
          TipoServicioHogarId: selectedServicioHogar2
            ? selectedServicioHogar2
            : TipoServicioId,
          RutaTraslado: rutaTraslado,
          HoraCita: horaCita ? horaCita : horaCitaApi,
          HoraSalida: horaSalida ? horaSalida : horaSalidaApi,
          Observacion: observaciones ? observaciones : Observacion,
          ConductorId: selectedConductor2 ? selectedConductor2 : ConductorId,
          VehiculoId: selectedConductor2 ? selectedVehiculo2 : VehiculoId,
          TransporteExterno: detallesTransporteExterno,
          FormularioId: selectedNumeroCaso2
            ? selectedNumeroCaso2
            : FormularioId,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Actualizado!",
          text: "el transporte a sido asignado con exito.",
          showCloseButton: true,
        }).then(() => {
          updateTable();
          toggle();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "El transporte no se pudo asignar",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label htmlFor="ServicioHogar">Hogar:</label>
          <Select
            isDisabled
            options={hogarTrasladoOptions}
            value={
              selectedHogarTraslado
                ? selectedHogarTraslado
                : hogarTrasladoOptions.find(
                    (option) => option.value === HogarId
                  )
            }
            onChange={handleHogarTrasladoChange}
          ></Select>
        </FormGroup>
        <FormGroup>
          <label htmlFor="ServicioHogar">Servicio del hogar:</label>
          <Select
            options={tipoServicioHogarOptions}
            value={
              selectedServicioHogar
                ? selectedServicioHogar
                : tipoServicioHogarOptions.find(
                    (option) => option.value === TipoServicioId
                  )
            }
            onChange={handleTipoServicioHogarChange}
          ></Select>
        </FormGroup>
        <FormGroup>
          <label>Nombre Paciente</label>
          <TextField
            disabled
            className="form-control "
            type="Text"
            value={Nombre}
          />
        </FormGroup>
        <FormGroup>
          <label>Numero Contacto</label>
          <TextField
            disabled
            className="form-control "
            type="Text"
            value={Celular}
          />
        </FormGroup>
        <FormGroup>
          <label>Ruta de traslado:</label>
          <input
            className="form-control"
            name="rutaTraslado"
            type="text"
            value={rutaTraslado}
            onChange={handleRutaTrasladoChange}
          />
        </FormGroup>
        <FormGroup>
          <label>Hora de la cita:</label>
          <br />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              value={horaCita ? horaCita : horaCitaInicial}
              onChange={handleTimeChange}
            />
          </LocalizationProvider>
        </FormGroup>
        <FormGroup>
          <label>Hora de salida:</label>
          <br />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              value={horaSalida ? horaSalida : horaSalidaInicial}
              onChange={handleTimeEndChange}
            />
          </LocalizationProvider>
        </FormGroup>
        <FormGroup>
          <label>¿Transporte Externo? :</label>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={(e) => setTransporteExterno(e.target.value === "Si")}
          >
            <FormControlLabel value="Si" control={<Radio />} label="SI" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormGroup>
        {transporteExterno && (
          <FormGroup>
            <label>Detalles Transporte Externo</label>
            <input
              className="form-control"
              name="transporteExterno"
              type="text"
              value={detallesTransporteExterno}
              onChange={handleTransporteExternoChange}
            />
          </FormGroup>
        )}
        <FormGroup>
          <label htmlFor="ServicioHogar">Conductor:</label>
          <Select
            options={conductoresOptions}
            onChange={handleConductorChange}
            value={selectedConductor
              ? selectedConductor
              : conductoresOptions.find(
                  (option) => option.value === ConductorId
                )}
          ></Select>
        </FormGroup>
        <FormGroup>
          <label htmlFor="ServicioHogar">Vehiculo:</label>
          <Select
            options={vehiculosOptions}
            onChange={handlevehiculoChange}
            value={selectedVehiculo
              ? selectedVehiculo
              : vehiculosOptions.find(
                  (option) => option.value === VehiculoId
                )}
          ></Select>
        </FormGroup>
        <FormGroup>
          <label>Observaciones:</label>
          <input
            className="form-control"
            name="observaciones"
            type="text"
            value={observaciones}
            onChange={handleObservacionesChange}
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleEditSubmit} color="primary" otype="submit">
          Asginar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalEdit;
