import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";

const ModalCreate = ({
  isOpen,
  toggle,
  title,
  token,
}) => {
  const [menu, setMenu] = useState("");
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);

  const handleMenuChange = (event) => {
    setMenu(event.target.value);
  };


  const handleCreateSubmit = (event) => {
    event.preventDefault();
    axios
      .post("/menumaestro", {
        Nombre: menu,
      }, {headers: { Authorization: `Bearer ${token}` }})
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Creado!",
          text: "el Menú ha sido creada con exito",
          showCloseButton: true,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Ha ocurrido un error al crear el Menú.",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label>Nombre del Menú:</label>
          <input
            className="form-control"
            name="rol"
            type="text"
            value={menu}
            onChange={handleMenuChange}
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleCreateSubmit} color="primary" otype="submit">
          Insertar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalCreate;
