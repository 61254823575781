import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, FormGroup, Form } from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { v4 as uuid } from "uuid";
import { TextField } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControlLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import withTokenCheck from "../withTokenCheck";

async function fetchDescription(token) {
    const response = await axios.get("/descripcion", {
        headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
}
async function fetchCodigoDescripcion(token) {
    const response = await axios.get("/codigoDescripcion", {
        headers: { Authorization: `Bearer ${token}` },
    });
    console.log(response.data, "codigo descripción fetch")
    return response.data;
}
async function fetchSolicitud(token) {
    const response = await axios.get("/tiposolicitud", {
        headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
}
async function fetchHogar(token) {
    const response = await axios.get("/hogar", {
        headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
}
async function fetchPaciente(token) {
    const response = await axios.get("/paciente", {
        headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
}
async function fetchTypeDocument(token) {
    const response = await axios.get("/tipodocumento", {
        headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
}
async function fetchIps(token) {
    const response = await axios.get("/ips", {
        headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
}
async function fetchServicio(token) {
    const response = await axios.get("/tiposervicio", {
        headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
}

async function fetchAdmision(token) {
    const response = await axios.get("/tipoadmision", {
        headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
}
async function fetchCiudad(token) {
    const response = await axios.get("/ciudad", {
        headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
}

async function fetchEspecialCondition(token) {
    const response = await axios.get("/condicionesespeciales", {
        headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
}
const AppForm = ({ token }) => {
    const [fechaSolicitud, setFechaSolicitud] = useState("");
    const [numeroCaso, setNumeroCaso] = useState("");
    const [descripcionCubrimiento, setDescripcionCubrimiento] = useState([]);
    const [descripcionOptions, setDescripcionOptions] = useState([]);
    const [selectedDescripcionCubrimiento, setSelectedDescripcionCubrimiento] =
        useState("");
    const [
        selectedDescripcionCubrimiento2,
        setSelectedDescripcionCubrimiento2,
    ] = useState("");
    const [codigoDescripcionCrubrimiento, setCodigoDescripcionCubrimiento] = useState([]);
    const [codigoDescripcionOptions, setCodigoDescripcionOptions] = useState([]);
    const [selectedCodigoDescripcionCubrimiento, setSelectedCodigoDescripcionCubrimiento] =
        useState("");
    const [
        selectedCodigoDescripcionCubrimiento2,
        setSelectedCodigoDescripcionCubrimiento2,
    ] = useState("");
    const [diasHospedaje, setDiasHospedaje] = useState([]);
    const [solicitudOptions, setSolicitudOptions] = useState([]);
    const [tipoSolicitud, setTipoSolicitud] = useState([]);
    const [selectedTipoSolicitud, setSelectedTipoSolicitud] = useState("");
    const [selectedTipoSolicitud2, setSelectedTipoSolicitud2] = useState("");
    const [fechaInicial, setFechaInicial] = useState("");
    const [fechaFinal, setFechaFinal] = useState("");
    const [hogarOptions, setHogarOptions] = useState([]);
    const [hogar, setHogar] = useState([]);
    const [selectedHogar, setSelectedHogar] = useState("");
    const [selectedHogar2, setSelectedHogar2] = useState("");
    const [direccion, setDireccion] = useState("");
    const [telContact, setTelContact] = useState("");
    const [ciudadOptions, setCiudadOptions] = useState([]);
    const [ciudad, setCiudad] = useState([]);
    const [selectedCiudad, setSelectedCiudad] = useState("");
    const [selectedCiudad2, setSelectedCiudad2] = useState("");
    const [pacienteOptions, setPacienteOptions] = useState([]);
    const [paciente, setPaciente] = useState([]);
    const [selectedPaciente, setSelectedPaciente] = useState("");
    const [selectedPaciente2, setSelectedPaciente2] = useState("");
    const [nombre1Paciente, setNombre1Paciente] = useState("");
    const [nombre2Paciente, setNombre2Paciente] = useState("");
    const [apellido1Paciente, setApellido1Paciente] = useState("");
    const [apellido2Paciente, setApellido2Paciente] = useState("");
    const [tipoDocumentoOptions, setTipoDocumentoOptions] = useState([]);
    const [tipoDocumento, setTipoDocumento] = useState([]);
    const [selectedTipoDocumento, setSelectedTipoDocumento] = useState("");
    const [selectedTipoDocumento2, setSelectedTipoDocumento2] = useState("");
    const [numeroIdentificacion, setNumeroIndentificacion] = useState("");
    const [fechaNacimiento, setFechaNacimiento] = useState("");
    const [edad, setEdad] = useState("");
    const [telPaciente, setTelPaciente] = useState("");
    const [numeroMipres, setNumeroMipres] = useState("");
    const [ciudadOrigen, setCiudadOrigen] = useState([]);
    const [selectedCiudadOrigen, setSelectedCiudadOrigen] = useState("");
    const [selectedCiudadOrigen2, setSelectedCiudadOrigen2] = useState("");
    const [servicioOptions, setServicioOptions] = useState([]);
    const [tipoServicio, setTipoServicio] = useState([]);
    const [ipsOptions, setIpsOptions] = useState([]);
    const [ips, setIps] = useState([]);
    const [admisionOptions, setAdmisionOptions] = useState([]);
    const [tipoAdmision, setTipoAdmision] = useState([]);
    const [codigoServicio, setCodigoServicio] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [acompananteOptions, setAcompananteOptions] = useState([]);
    const [acompanante, setAcompanante] = useState([]);
    const [tipoDocumentoAcom, setTipoDocumentoAcom] = useState([]);
    const [caracteristicasEspeciales, setCaracteristicasEspeciales] = useState(
        []
    );
    const [checkedState, setCheckedState] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [observacionesEdit, setObservacionesEdit] = useState("");
    const [observaciones, setObservaciones] = useState("");
    const [infoOpciones, setInfoOpciones] = useState({});
    const [infoIps, setInfoIps] = useState({});
    const [infoTipoAdmision, setInfoTipoAdmision] = useState({});
    const [/* forMode, */ setFormMode] = useState("none");
    const [numInputsAcom, setNumInputsAcom] = useState(1);
    const [inputsAcom, setInputsAcom] = useState([
        {
            id: uuid(),
            acompanante: "",
            tipoAcompanante: "",
            apellido1Acompanante: "",
            apellido2Acompanante: "",
            nombre1Acompanante: "",
            nombre2Acompanante: "",
            tipoDocumentoAcom: "",
            numeroIdentificacionAcom: "",
            fechaNacimientoAcom: "",
        },
    ]);
    const navigate = useNavigate();
    const [numInputs, setNumInputs] = useState(1);
    const [inputs, setInputs] = useState([
        {
            id: uuid(),
            tipoServicio: "",
            Ips: "",
            tipoAdmision: "",
            fechaConsulta: "",
        },
    ]);

    /** Variables carga dinamica */
    const [acompanantesSeleccionados, setAcompanantesSeleccionados] = useState(
        []
    );
    const [serviciosSeleccionados, setServiciosSeleccionados] = useState([]);
    /** Variables carga dinamica */

    const location = useLocation();
    const id = location.state.id;

    useEffect(() => {
        if (token) {
            async function fetchData() {
                try {
                    const [condicionesEspeciales] = await Promise.all([
                        fetchEspecialCondition(token),
                    ]);

                    setCaracteristicasEspeciales(condicionesEspeciales);
                } catch (error) {
                    // Manejar errores de la petición
                } finally {
                    setIsLoading(false);
                }
            }

            fetchData();
        }
    }, [token]);

    useEffect(() => {
        if (!isLoading && caracteristicasEspeciales.length > 0) {
            axios
                .get(`/formulario/edicion/${id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    const selectedData = response.data;
                    if (
                        !selectedData.condiciones_especiales ||
                        selectedData.condiciones_especiales.length === 0
                    ) {
                        const initialState = caracteristicasEspeciales.reduce(
                            (acc, item) => {
                                return { ...acc, [item.id]: false };
                            },
                            {}
                        );
                        setCheckedState(initialState);
                    } else {
                        const initialState = caracteristicasEspeciales.reduce(
                            (acc, item) => {
                                const estado =
                                    selectedData.condiciones_especiales.find(
                                        (condicion) => condicion.id === item.id
                                    )?.pivot.estado;
                                return { ...acc, [item.id]: estado === 1 };
                            },
                            {}
                        );
                        setCheckedState(initialState);
                    }
                });
        }
    }, [isLoading, caracteristicasEspeciales, token, id]);

    useEffect(() => {
        if (token) {
            async function fetchData() {
                const dataDescriptionPromise = fetchDescription(token)
                    .then((dataDescription1) => {
                        setDescripcionCubrimiento(dataDescription1);
                        return dataDescription1;
                    })
                    .then((dataDescription2) => {
                        const descripcionOptions = dataDescription2.map(
                            (option) => ({
                                value: option.id,
                                label: option.Texto ?? option.Texto,
                            })
                        );
                        setDescripcionOptions(descripcionOptions);
                        return descripcionOptions;
                    });
                    const dataCodigoDescriptionPromise = fetchCodigoDescripcion(token)
                    .then((dataCodigoDescription1) => {
                        setCodigoDescripcionCubrimiento(dataCodigoDescription1);
                        return dataCodigoDescription1;
                    })
                    .then((dataCodigoDescription2) => {
                        const codigoDescripcionOptions = dataCodigoDescription2.map(
                            (option) => ({
                                value: option.id,
                                label: option.CodigoServicio ?? option.CodigoServicio,
                            })
                        );
                        console.log(codigoDescripcionOptions, "codigo descripcion")
                        setCodigoDescripcionOptions(codigoDescripcionOptions);
                        return codigoDescripcionOptions;
                    });
                const dataSolicitudPromise = fetchSolicitud(token)
                    .then((datasolicitud1) => {
                        setTipoSolicitud(datasolicitud1);
                        return datasolicitud1;
                    })
                    .then((dataSolicitud2) => {
                        const solicitudOptions = dataSolicitud2.map(
                            (option) => ({
                                value: option.id,
                                label: option.Texto ?? option.Texto,
                            })
                        );
                        setSolicitudOptions(solicitudOptions);
                        return solicitudOptions;
                    });
                const dataHogarPromise = fetchHogar(token)
                    .then((dataHogar1) => {
                        setHogar(dataHogar1);
                        return dataHogar1;
                    })
                    .then((dataHogar2) => {
                        const hogarOptions = dataHogar2.map((option) => ({
                            value: option.id,
                            label: option.Nombre ?? option.Nombre,
                        }));
                        setHogarOptions(hogarOptions);
                        return hogarOptions;
                    });
                const dataCiudadPromise = fetchCiudad(token)
                    .then((dataCiudad1) => {
                        setCiudad(dataCiudad1);
                        setCiudadOrigen(dataCiudad1);
                        return dataCiudad1;
                    })
                    .then((dataCiudad2) => {
                        const ciudadOptions = dataCiudad2.map((option) => ({
                            value: option.id,
                            label: option.Texto ?? option.Texto,
                        }));
                        setCiudadOptions(ciudadOptions);
                        return ciudadOptions;
                    });
                const dataPacientePromise = fetchPaciente(token)
                    .then((dataPaciente1) => {
                        setPaciente(dataPaciente1);
                        return dataPaciente1;
                    })
                    .then((dataPaciente2) => {
                        const pacienteOptions = dataPaciente2.map((option) => ({
                            value: option.id,
                            label:
                                option.NumeroIdent +
                                    " " +
                                    option.Nombre1 +
                                    " " +
                                    option.Apellido1 ??
                                option.NumeroIdent +
                                    " " +
                                    option.Nombre1 +
                                    " " +
                                    option.Apellido1,
                        }));
                        setPacienteOptions(pacienteOptions);
                        return pacienteOptions;
                    });
                const dataDocumentoPromise = fetchTypeDocument(token)
                    .then((dataDocumento1) => {
                        setTipoDocumento(dataDocumento1);
                        return dataDocumento1;
                    })
                    .then((dataDocumento2) => {
                        const tipoDocumentoOptions = dataDocumento2.map(
                            (option) => ({
                                value: option.id,
                                label: option.Texto ?? option.Texto,
                            })
                        );
                        setTipoDocumentoOptions(tipoDocumentoOptions);
                        return tipoDocumentoOptions;
                    });
                const dataServicioPromise = fetchServicio(token)
                    .then((dataServicio1) => {
                        setTipoServicio(dataServicio1);
                        return dataServicio1;
                    })
                    .then((dataServicio2) => {
                        const servicioOptions = dataServicio2.map((option) => ({
                            value: option.id,
                            label: option.Texto ?? option.Texto,
                        }));
                        setServicioOptions(servicioOptions);
                        return servicioOptions;
                    });
                const dataIpsPromise = fetchIps(token)
                    .then((dataIps1) => {
                        setIps(dataIps1);
                        return dataIps1;
                    })
                    .then((dataIps2) => {
                        const ipsOptions = dataIps2.map((option) => ({
                            value: option.id,
                            label: option.Texto ?? option.Texto,
                        }));
                        setIpsOptions(ipsOptions);
                        return ipsOptions;
                    });
                const dataAdmisionPromise = fetchAdmision(token)
                    .then((dataAdmision1) => {
                        setTipoAdmision(dataAdmision1);
                        return dataAdmision1;
                    })
                    .then((dataAdmision2) => {
                        const admisionOptions = dataAdmision2.map((option) => ({
                            value: option.id,
                            label: option.Texto ?? option.Texto,
                        }));
                        setAdmisionOptions(admisionOptions);
                        return admisionOptions;
                    });

                const [
                    dataSolicitud,
                    dataHogar,
                    dataPaciente,
                    dataTypeDocument,
                    dataIps,
                    dataServicio,
                    dataAdmision,
                    dataCiudad,
                    descripcionOptions,
                    codigoDescripcionOptions,
                    solicitudOptions,
                    hogarOptions,
                    ciudadOptions,
                    pacienteOptions,
                    tipoDocumentoOptions,
                ] = await Promise.all([
                    fetchSolicitud(token),
                    fetchHogar(token),
                    fetchPaciente(token),
                    fetchTypeDocument(token),
                    fetchIps(token),
                    fetchServicio(token),
                    fetchAdmision(token),
                    fetchCiudad(token),
                    dataDescriptionPromise,
                    dataCodigoDescriptionPromise,
                    dataSolicitudPromise,
                    dataHogarPromise,
                    dataCiudadPromise,
                    dataPacientePromise,
                    dataDocumentoPromise,
                ]);

                axios
                    .get(`/formulario/edicion/${id}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then((response) => {
                        const selectedData = response.data;
                        const formattedFechaSolicitud =
                            selectedData.FechaSolicitud;
                        const formattedFechaInicial = selectedData.FechaInicial;
                        const formattedFechaFinal = selectedData.FechaFinal;
                        const descripcionO = descripcionOptions.find(
                            (option) =>
                                option.value === selectedData.DescripcionId
                        );
                        if (descripcionO) {
                            setSelectedDescripcionCubrimiento(descripcionO);
                            setSelectedDescripcionCubrimiento2(
                                descripcionO.value
                            );
                        }
                        const descripcionSolicitud = solicitudOptions.find(
                            (option) =>
                                option.value === selectedData.TipoSolicitudId
                        );
                        if (descripcionSolicitud) {
                            setSelectedTipoSolicitud(descripcionSolicitud);
                            setSelectedTipoSolicitud2(
                                descripcionSolicitud.value
                            );
                        }
                        const codigoDescripcionI = codigoDescripcionOptions.find (
                            (option) =>
                                option.value === selectedData.CodigoDescripcionId
                        )
                        if (codigoDescripcionI) {
                            setSelectedCodigoDescripcionCubrimiento(codigoDescripcionI);
                            setSelectedCodigoDescripcionCubrimiento2(codigoDescripcionI.value);
                        }
                        const hogarE = hogarOptions.find(
                            (option) => option.value === selectedData.HogarId
                        );
                        if (hogarE) {
                            setSelectedHogar(hogarE);
                            setSelectedHogar2(hogarE.value);
                            if (token) {
                                axios
                                    .get(`/hogar/${hogarE.value}`, {
                                        headers: {
                                            Authorization: `Bearer ${token}`,
                                        },
                                    })
                                    .then((response) => {
                                        setSelectedCiudad(
                                            ciudadOptions.find(
                                                (option) =>
                                                    option.value ===
                                                    response.data.CiudadId
                                            )
                                        );
                                        setSelectedCiudad2(
                                            response.data.CiudadId
                                        );
                                        setDireccion(response.data.Direccion);
                                        setTelContact(
                                            response.data.NumeroContacto
                                        );
                                    })

                                    .catch((error) => {
                                        console.log(error);
                                    });
                            }
                        }
                        const pacienteS = pacienteOptions.find(
                            (option) => option.value === selectedData.PacienteId
                        );
                        if (pacienteS) {
                            setSelectedPaciente(pacienteS);
                            setSelectedPaciente2(pacienteS.value);
                            if (token) {
                                axios
                                    .get(`/paciente/${pacienteS.value}`, {
                                        headers: {
                                            Authorization: `Bearer ${token}`,
                                        },
                                    })
                                    .then((response) => {
                                        setNombre1Paciente(
                                            response.data.Nombre1
                                        );
                                        setNombre2Paciente(
                                            response.data.Nombre2
                                        );
                                        setApellido1Paciente(
                                            response.data.Apellido1
                                        );
                                        setApellido2Paciente(
                                            response.data.Apellido2
                                        );
                                        setSelectedTipoDocumento(
                                            tipoDocumentoOptions.find(
                                                (option) =>
                                                    option.value ===
                                                    response.data
                                                        .TipoDocumentoId
                                            )
                                        );
                                        setSelectedTipoDocumento2(
                                            response.data.TipoDocumentoId
                                        );
                                        setNumeroIndentificacion(
                                            response.data.NumeroIdent
                                        );
                                        setFechaNacimiento(
                                            response.data.FechaNacimiento
                                        );
                                        setTelPaciente(
                                            response.data.TelContacto
                                        );
                                        setSelectedCiudadOrigen(
                                            ciudadOptions.find(
                                                (option) =>
                                                    option.value ===
                                                    response.data.CiudadOrigenId
                                            )
                                        );
                                        setSelectedCiudadOrigen2(
                                            response.data.CiudadOrigenId
                                        );
                                        setEdad(response.data.edad);

                                        axios
                                            .get(
                                                `acompanante/paciente/${pacienteS.value}`,
                                                {
                                                    headers: {
                                                        Authorization: `Bearer ${token}`,
                                                    },
                                                }
                                            )
                                            .then((response) => {
                                                setAcompanante(response.data);
                                                const acompananteOptions =
                                                    response.data.map(
                                                        (option) => ({
                                                            value: option.id,
                                                            label:
                                                                option.NumeroIdent +
                                                                    " " +
                                                                    option.Nombre1 +
                                                                    " " +
                                                                    option.Apellido1 ??
                                                                option.NumeroIdent +
                                                                    " " +
                                                                    option.Nombre1 +
                                                                    " " +
                                                                    option.Apellido1,
                                                        })
                                                    );
                                                setAcompananteOptions(
                                                    acompananteOptions
                                                );
                                                setInputsAcom(response.data);
                                                setAcompanantesSeleccionados(
                                                    response.data
                                                );
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                            });
                                    })

                                    .catch((error) => {
                                        console.log(error);
                                    });
                            }
                        }
                        setTipoSolicitud(dataSolicitud);
                        setHogar(dataHogar);
                        setPaciente(dataPaciente);
                        setTipoDocumento(dataTypeDocument);
                        setTipoDocumentoAcom(dataTypeDocument);
                        setIps(dataIps);
                        setTipoServicio(dataServicio);
                        setTipoAdmision(dataAdmision);
                        setCiudad(dataCiudad);
                        setCiudadOrigen(dataCiudad);
                        handleFechaSolicitudChange({
                            target: { value: formattedFechaSolicitud },
                        });
                        handleNumeroCasoChange({
                            target: { value: selectedData.NumeroCaso },
                        });

                        handleFechaInicialChange({
                            target: { value: formattedFechaInicial },
                        });
                        handleFechaFinalChange({
                            target: { value: formattedFechaFinal },
                        });
                        handleDiasHospedajeChange({
                            target: {value: selectedData.DiasHospedaje},
                        })
                        handleNumeroMipresChange({
                            target: { value: selectedData.NumeroMIPRES },
                        });
                        handleCodigoServicioChange({
                            target: { value: selectedData.CodigoServicio },
                        });
                        handleDescripcionChange({
                            target: { value: selectedData.Descripcion },
                        });
                        handleObservacionesChange({
                            target: { value: selectedData.Observaciones },
                        });
                    })

                    .catch((error) => {
                        console.log(error);
                    });
            }

            fetchData();
        }
    }, [id, token]);

    // Manejar el envío del formulario para crear una nueva ciudad
    const handleCreateSubmit = (event) => {
        event.preventDefault();
        axios
            .post(
                `/formulario/actualizar/${id}`,
                {
                    FechaSolicitud: fechaSolicitud,
                    NumeroCaso: parseInt(numeroCaso),
                    DescripcionId: parseInt(selectedDescripcionCubrimiento2),
                    TipoSolicitudId: parseInt(selectedTipoSolicitud2),
                    FechaInicial: fechaInicial,
                    FechaFinal: fechaFinal,
                    DiasHospedaje : diasHospedaje,
                    HogarId: parseInt(selectedHogar2),
                    PacienteId: parseInt(selectedPaciente2),
                    NumeroMIPRES: numeroMipres,
                    CodigoServicio: codigoServicio,
                    CodigoDescripcionId : parseInt(selectedCodigoDescripcionCubrimiento2),
                    Descripcion: descripcion,
                    CasosEspeciales: checkedState,
                    Observaciones: observaciones,
                    ObservacionesEdit: observacionesEdit,
                },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: "Guardado!",
                    text: "los datos han sido actualizados con exito.",
                    showCloseButton: true,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        navigate("/reportEdition");
                    } else if (result.isDenied) {
                        Swal.fire("Changes are not saved", "", "info");
                    }
                });
            })

            .catch((error) => {
                console.log(error);
                var errorMessage = "";
                for (var key in error.response.data.errors) {
                    errorMessage += error.response.data.errors[key] + "<br>";
                }
                Swal.fire({
                    icon: "error",
                    title: error.response.data.message,
                    html: errorMessage,
                });
            });
    };

    // Manejar el cambio en el campo de descripción
    const handleNumeroCasoChange = (event) => {
        setNumeroCaso(event.target.value);
    };
    const handleFechaSolicitudChange = (event) => {
        setFechaSolicitud(event.target.value);
    };
    const handleDescripcionCubrimientoChange = (selectedOption) => {
        setSelectedDescripcionCubrimiento2(selectedOption.value);
        setSelectedDescripcionCubrimiento(
            descripcionOptions.find(
                (option) => option.value === selectedOption.value
            )
        );
    };
    const handleCodigoDescripcionCubrimientoChange = (selectedOption) => {
        setSelectedCodigoDescripcionCubrimiento2(selectedOption.value);
        setSelectedCodigoDescripcionCubrimiento(
            codigoDescripcionCrubrimiento.find(
                (option) => option.value === selectedOption.value
            )
        );
    };
    const handleTipoSolicitudChange = (selectedOption) => {
        setSelectedTipoSolicitud2(selectedOption.value);
        setSelectedTipoSolicitud(
            solicitudOptions.find(
                (option) => option.value === selectedOption.value
            )
        );
    };
    const handleFechaInicialChange = (event) => {
        setFechaInicial(event.target.value);
    };
    const handleFechaFinalChange = (event) => {
        setFechaFinal(event.target.value);
    };
    const handleHogarChange = (selectedOption) => {
        setSelectedHogar2(selectedOption.value);
        setSelectedHogar(
            hogarOptions.find((option) => option.value === selectedOption.value)
        );
        if (token) {
            axios
                .get(`/hogar/${selectedOption.value}`, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setSelectedCiudad(
                        ciudadOptions.find(
                            (option) => option.value === response.data.CiudadId
                        )
                    );
                    setSelectedCiudad2(response.data.CiudadId);
                    setDireccion(response.data.Direccion);
                    setTelContact(response.data.NumeroContacto);
                })

                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const handlePacienteChange = (selectedOption) => {
        setSelectedPaciente2(selectedOption.value);
        setSelectedPaciente(
            pacienteOptions.find(
                (option) => option.value === selectedOption.value
            )
        );
        if (token) {
            axios
                .get(`/paciente/${selectedOption.value}`, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setNombre1Paciente(response.data.Nombre1);
                    setNombre2Paciente(response.data.Nombre2);
                    setApellido1Paciente(response.data.Apellido1);
                    setApellido2Paciente(response.data.Apellido2);
                    setSelectedTipoDocumento(
                        tipoDocumentoOptions.find(
                            (option) =>
                                option.value === response.data.TipoDocumentoId
                        )
                    );
                    setSelectedTipoDocumento2(response.data.TipoDocumentoId);
                    setNumeroIndentificacion(response.data.NumeroIdent);
                    setFechaNacimiento(response.data.FechaNacimiento);
                    setTelPaciente(response.data.TelContacto);
                    setSelectedCiudadOrigen(
                        ciudadOptions.find(
                            (option) =>
                                option.value === response.data.CiudadOrigenId
                        )
                    );
                    setSelectedCiudadOrigen2(response.data.CiudadOrigenId);
                    setEdad(response.data.edad);
                    axios
                        .get(`acompanante/paciente/${selectedOption.value}`)
                        .then((response) => {
                            setAcompanante(response.data);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                })

                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const handleNumeroMipresChange = (event) => {
        setNumeroMipres(event.target.value);
    };
    const handleDiasHospedajeChange = (event) => {
        setDiasHospedaje(event.target.value);
    };
    const handleCodigoServicioChange = (event) => {
        setCodigoServicio(event.target.value);
    };
    const handleDescripcionChange = (event) => {
        setDescripcion(event.target.value);
    };

    const handleObservacionesChange = (event) => {
        setObservaciones(event.target.value);
    };

    const handleObservacionesEditChange = (event) => {
        setObservacionesEdit(event.target.value);
    };

    const handleOnChange = (id) => {
        setCheckedState((prevState) => {
            const updatedState = { ...prevState, [id]: !prevState[id] };
            return updatedState;
        });
    };
    useEffect(() => {}, [checkedState]);

    return (
        <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Form onSubmit={handleCreateSubmit}>
                <Divider className="mt-4">
                    <Chip label="Solicitud" />
                </Divider>
                <Box>
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="input-group input-group-sm  d-flex justify-content-center align-items-center">
                            <FormGroup className="col-md-4 mt-3 align-items-center">
                                <TextField
                                    label="Fecha de Solicitud"
                                    className="form-control"
                                    type="date"
                                    name="FechaSolicitud"
                                    value={fechaSolicitud}
                                    onChange={handleFechaSolicitudChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="col-md-4 mt-3 align-items-center">
                                <TextField
                                    disabled
                                    label="Numero de Caso"
                                    className="form-control "
                                    type="Number"
                                    name="NumeroCaso"
                                    value={numeroCaso || ""}
                                    onChange={handleNumeroCasoChange}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="input-group input-group-sm mb-2 d-flex justify-content-around">
                            <FormControl size="small" className="col-md">
                                <FormControl size="small" className="col-md">
                                    <FormGroup>
                                        <label htmlFor="descripcionId">
                                            Codigo Descripcion:
                                        </label>
                                        <Select
                                            value={selectedCodigoDescripcionCubrimiento}
                                            options={codigoDescripcionOptions}
                                            onChange={
                                                handleCodigoDescripcionCubrimientoChange
                                            }
                                        ></Select>
                                    </FormGroup>
                                </FormControl>
                                <FormGroup>
                                    <label htmlFor="descripcionId">
                                        Descripción Cubrimiento:
                                    </label>
                                    <Select
                                        value={selectedDescripcionCubrimiento}
                                        options={descripcionOptions}
                                        onChange={
                                            handleDescripcionCubrimientoChange
                                        }
                                    ></Select>
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md">
                                <FormGroup className="ml-1">
                                    <label htmlFor="solicitudId">
                                        Tipo de solicitud
                                    </label>
                                    <Select
                                        value={selectedTipoSolicitud}
                                        options={solicitudOptions}
                                        onChange={handleTipoSolicitudChange}
                                    ></Select>
                                </FormGroup>
                            </FormControl>
                        </div>
                        <div className="input-group input-group-sm mb-2 d-flex justify-content-around">
                        <FormControl size="small" className="col-md">
                                <FormGroup>
                                    <TextField
                                        label="Fecha Inicial Servicio"
                                        className="form-control "
                                        type="Date"
                                        name="FechaIncial"
                                        value={fechaInicial}
                                        onChange={handleFechaInicialChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl className="col-md">
                                <FormGroup>
                                    <TextField
                                        label="Fecha Final Del Servicio"
                                        className="form-control"
                                        type="Date"
                                        name="FechaFinal"
                                        value={fechaFinal}
                                        onChange={handleFechaFinalChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-4">
                                <FormGroup>
                                    <TextField
                                        label="Cantidad de días de hospedaje"
                                        className="form-control "
                                        type="Number"
                                        name="diasHospedaje"
                                        value={diasHospedaje}
                                        onChange={handleDiasHospedajeChange}
                                    />
                                </FormGroup>
                                </FormControl>
                        </div>
                    </div>
                </Box>
                <Divider className="mb-4">
                    <Chip label="Prestador" />
                </Divider>
                <Box>
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="input-group input-group-sm mb-2 d-flex justify-content-center align-items-center">
                            <FormControl size="small" className="col-md-4 ">
                                <FormGroup>
                                    <label htmlFor="hogarId">Hogar:</label>
                                    <Select
                                        value={selectedHogar}
                                        options={hogarOptions}
                                        onChange={handleHogarChange}
                                    ></Select>
                                </FormGroup>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="input-group input-group-sm mb-2 d-flex justify-content-center align-items-center">
                            <FormControl size="small" className="col-md-4">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Telefonos de Contacto"
                                        className="form-control "
                                        type="text"
                                        name="TelContacto"
                                        value={telContact}
                                        /* onChange={handleTelContactChange} */
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-4">
                                <FormGroup>
                                    <label htmlFor="ciudadId">Ciudad:</label>
                                    <Select
                                        isDisabled
                                        options={ciudadOptions}
                                        value={selectedCiudad}
                                    ></Select>
                                </FormGroup>
                            </FormControl>
                        </div>
                    </div>
                </Box>
                <Divider className="mb-4">
                    <Chip label="Paciente" />
                </Divider>
                <Box>
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="input-group input-group-sm mb-2 d-flex justify-content-center align-items-center">
                            <FormControl size="small" className="col-md-10">
                                <FormGroup>
                                    <label htmlFor="solicitudId">
                                        Seleccionar Paciente por Numero de
                                        Identificacion:
                                    </label>
                                    {
                                        <Select
                                            isDisabled
                                            value={selectedPaciente}
                                            options={pacienteOptions}
                                            onChange={handlePacienteChange}
                                        ></Select>
                                    }
                                </FormGroup>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row d-flex align-items-center justify-content-center flex-wrap">
                        <div className="input-group input-group-sm mb-2 d-flex justify-content-center align-items-center">
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Primer Apellido Paciente"
                                        className="form-control "
                                        type="Text"
                                        name="Apellido1Paciente"
                                        value={apellido1Paciente}
                                        /* onChange={handleApellido1PacienteChange} */
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Segundo Apellido Paciente"
                                        className="form-control "
                                        type="Text"
                                        name="Apellido2Paciente"
                                        value={apellido2Paciente}
                                        /*  onChange={handleApellido2PacienteChange} */
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Primer Nombre Paciente"
                                        className="form-control "
                                        type="Text"
                                        name="nombre1Paciente"
                                        value={nombre1Paciente}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Segundo Nombre Paciente"
                                        className="form-control "
                                        type="Text"
                                        name="nombre2Paciente"
                                        value={nombre2Paciente}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <label htmlFor="documentoId">
                                        Tipo de Documento
                                    </label>
                                    <Select
                                        isDisabled
                                        options={tipoDocumentoOptions}
                                        value={selectedTipoDocumento}
                                        label="ciudad"
                                    ></Select>
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Numero de indentificación"
                                        className="form-control "
                                        type="Text"
                                        name="numeroIdentificacion"
                                        value={numeroIdentificacion}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Fecha de Nacimiento"
                                        className="form-control "
                                        type="Date"
                                        name="FechaNacimiento"
                                        value={fechaNacimiento}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Edad"
                                        className="form-control "
                                        type="text"
                                        name="edad"
                                        value={edad}
                                    />
                                </FormGroup>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row d-flex align-items-center justify-content-center flex-wrap">
                        <div className="input-group input-group-sm mb-2 d-flex justify-content-center align-items-center">
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Telefono Contacto Paciente"
                                        className="form-control "
                                        type="Number"
                                        name="TelPaciente"
                                        value={telPaciente}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        label="Numero MIPRES"
                                        className="form-control "
                                        type="Text"
                                        name="NumeroMIPRES"
                                        value={numeroMipres}
                                        onChange={handleNumeroMipresChange}
                                        /* onChange={handleNumeroMipresChange} */
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <label htmlFor="ciudadOrigenId">
                                        Ciudad Origen
                                    </label>
                                    <Select
                                        isDisabled
                                        options={ciudadOptions}
                                        value={selectedCiudadOrigen}
                                        /* onChange={handleCiudadOrigenChange} */
                                        label="ciudad"
                                    ></Select>
                                </FormGroup>
                            </FormControl>
                        </div>
                    </div>
                </Box>
                <Divider className="mb-4">
                    <Chip label="Descripcion de servicio" />
                </Divider>
                <Box>
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="input-group input-group-sm mb-2 d-flex justify-content-center align-items-center">
                            <FormControl size="small" className="col-md-4">
                                <FormGroup>
                                    <TextField
                                        label="Codigo de Servicio"
                                        className="form-control "
                                        type="Text"
                                        name="codigoServicio"
                                        value={codigoServicio}
                                        onChange={handleCodigoServicioChange}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-4">
                                <FormGroup>
                                    <TextField
                                        label="Descripción"
                                        className="form-control "
                                        type="Text"
                                        name="Descripcion"
                                        value={descripcion}
                                        onChange={handleDescripcionChange}
                                    />
                                </FormGroup>
                            </FormControl>
                        </div>
                    </div>
                </Box>
                <Divider className="mb-4">
                    <Chip label="Caracteristicas Especiales" />
                </Divider>
                <div style={{ display: "box", alignItems: "center" }}>
                    <FormControl component="fieldset">
                        <FormGroup>
                            {caracteristicasEspeciales.map((index) => {
                                return (
                                    <FormControlLabel
                                        key={index.id}
                                        control={
                                            <Checkbox
                                                checked={
                                                    checkedState[index.id] ||
                                                    false
                                                }
                                                onChange={() =>
                                                    handleOnChange(index.id)
                                                }
                                            />
                                        }
                                        label={index.Texto}
                                    />
                                );
                            })}
                        </FormGroup>
                    </FormControl>
                </div>
                <Divider className="mb-4">
                    <Chip label="Observaciones Especiales" />
                </Divider>
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="input-group input-group-sm mb-2 d-flex justify-content-center align-items-center">
                        <FormControl size="small" className="col-md-10">
                            <FormGroup>
                                <TextField
                                    label="Observaciones de necesidades especiales para el hospedaje"
                                    className="form-control "
                                    type="Text"
                                    name="observaciones"
                                    value={observaciones}
                                    onChange={handleObservacionesChange}
                                />
                            </FormGroup>
                        </FormControl>
                    </div>
                </div>
                <Divider className="mb-4">
                    <Chip label="Observaciones Cambio de evento" />
                </Divider>
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="input-group input-group-sm mb-2 d-flex justify-content-center align-items-center">
                        <FormControl size="small" className="col-md-10">
                            <FormGroup>
                                <TextField
                                    label="Observaciones asociadas a la modificación del formulario inicial"
                                    className="form-control"
                                    type="Text"
                                    name="observacionesEdit"
                                    value={observacionesEdit}
                                    onChange={handleObservacionesEditChange}
                                />
                            </FormGroup>
                        </FormControl>
                    </div>
                </div>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Button
                        className="mb-4"
                        type="submit"
                        id="Button"
                        variant="contained"
                    >
                        Enviar Formulario
                    </Button>
                </Box>
            </Form>
        </Box>
    );
};

export default withTokenCheck(AppForm);
