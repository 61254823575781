import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";

const ModalEdit = ({
  isOpen,
  toggle,
  title,
  id,
  textoInicial,
  MenuMaestro,
  Path,
  token,
}) => {
  const [menuDetail, setMenuDetail] = useState("");
  const [menu, setMenu] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [selectedMenu2, setSelectedMenu2] = useState("");
  const [path, setPath] = useState("");
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);

  useEffect(() => {
    setMenuDetail(textoInicial);
    setPath(Path);
  }, [textoInicial]);

  const handleMenuDetailChange = (event) => {
    setMenuDetail(event.target.value);
  };
  const handleMenuChange = (selectedOption) => {
    setSelectedMenu2(selectedOption.value);
    setSelectedMenu(
      menuOptions.find((option) => option.value === selectedOption.value)
    );
  };

  const handlePathChange = (event) => {
    setPath(event.target.value);
  };

  useEffect(() => {
    if (token) {
      axios
        .get("/menumaestro", { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          setMenu(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  const menuOptions = menu.map((option) => ({
    value: option.id,
    label: option.Nombre ?? option.nombre,
  }));

  const handleEditSubmit = (event) => {
    event.preventDefault();
    axios
      .put(
        `/menudetalle/${id}`,
        {
          MenuMaestroId: selectedMenu2 ? selectedMenu2 : MenuMaestro,
          Nombre : menuDetail,
          Path : path,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Actualizado!",
          text: "el Menú a sido actualizado con exito.",
          showCloseButton: true,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "el Menú no se pudo actualizar",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label htmlFor="MenuId">Menú Maestro:</label>
          <Select
            options={menuOptions}
            value={
              selectedMenu
                ? selectedMenu
                : menuOptions.find((option) => option.value === MenuMaestro)
            }
            onChange={handleMenuChange}
          ></Select>
        </FormGroup>
        <FormGroup>
          <label>Nombre</label>
          <input
            className="form-control"
            name="MenuMasterId"
            type="text"
            value={menuDetail}
            onChange={handleMenuDetailChange}
          />
        </FormGroup>
        <FormGroup>
          <label>Path:</label>
          <input
            className="form-control"
            name="path"
            type="text"
            value={path}
            onChange={handlePathChange}
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleEditSubmit} color="primary" otype="submit">
          Guardar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalEdit;
