import React, { useState, useEffect } from "react";
import axios from "axios";
import { TextField } from "@material-ui/core";

export default function Home({ token }) {
    const [totalCamas, setTotalCamas] = useState("");
    const [totalCamasOcupadas, setTotalCamasOcupadas] = useState("");

    if (localStorage.getItem("session") === "1") {
        localStorage.setItem("session", 0);
        window.location.reload();
    }

    useEffect(() => {
        if (token) {
            const headers = { Authorization: `Bearer ${token}` };
            const fetchData = async () => {
                try {
                    const response = await axios.get(
                        "/asignacion/reporte/totales",
                        { headers }
                    );
                    setTotalCamas(response.data.Disponibles);
                    setTotalCamasOcupadas(response.data.NoDisponibles);
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        // Aquí puedes redirigir al usuario a la página de inicio de sesión, por ejemplo
                        console.log("Error 401: Usuario no autenticado");
                    } else {
                        console.error("Error al obtener los totales:", error);
                    }
                }
            };
            fetchData();
        }
    }, [token]);

    return (
        <div className="input-group input-group-sm  d-flex justify-content-center align-items-center">
            <TextField
                disabled
                label="Numero de camas disponibles"
                className="form-control"
                type="Text"
                name="camasDisponibles"
                value={totalCamas}
            />
            <TextField
                disabled
                label="Numero de camas ocupadas"
                className="form-control"
                type="Text"
                name="camasOcupadas"
                value={totalCamasOcupadas}
            />
        </div>
    );
}
