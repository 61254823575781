import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import ModalEdit from "./ModalEdit";
import { Button } from "reactstrap";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import "./styles.css";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import BreadCrum from "../../breadCrum/breadCrum";
import withTokenCheck from "../../withTokenCheck";

const AppIps = ({ token }) => {
    const [dataAutomata, setDataAutomata] = useState([]);
    const currentDate = dayjs();
    const [id, setId] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [modalEditcontent, setModalEditContent] = useState({});
    const [modalTittleContent, setModalTittleContent] = useState({}); /* 
  const [dateStart, setDateStart] = useState(currentDate);
  const [dateEnd, setDateEnd] = useState(currentDate); */
    const [consultClicked, setConsultClicked] = useState(false);
    const tableRef = useRef(null);
    const etiquetas = [
        "Inicio Lossman",
        "Reportes",
        "Reporte Información Cargada Por El Robot",
    ];

    /*  const params = {
      FechaInicio: dateStart.format("YYYY-MM-DD"),
      FechaFinal: dateEnd.format("YYYY-MM-DD"),
    }; */
    useEffect(() => {
        if (token) {
            axios
                .get("/formularioautomatico", {
                    headers: { Authorization: `Bearer ${token}` } /* ,
              params */,
                })
                .then((response) => {
                    setDataAutomata(response.data);
                    console.log(response.data, "info robot");
                    setConsultClicked(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const handleModalToggle = () => setModalOpen(!modalOpen);
    const handleEditModalToggle = () => setModalEditOpen(!modalEditOpen);

    const handleButtonClick = (content) => {
        setModalContent(content);
        handleModalToggle();
    };

    const handleEditClick = (element, content) => {
        setModalEditContent(element);
        setModalTittleContent(content);
        handleEditModalToggle();
    };

    const handleCreateSubmit = (event, id) => {
        axios
            .post(
                "/formularioautomatico",
                {
                    Id: id,
                },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: "Creado!",
                    text: "El formulario ha sido registrado con exito",
                    showCloseButton: true,
                }).then(() => {
                    window.location.reload();
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "Ha ocurrido un error al registrar el formulario.",
                    showCloseButton: true,
                });
            });
    };
    useEffect(() => {
        let dataTable;
        if (consultClicked) {
            $(tableRef.current).DataTable({
                paging: true,
                ordering: true,
                info: true,
                dom: "<'row'<'col-sm-2'l><'col-sm-6'B><'col-sm-4'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-6 text-right'i><'col-sm-6'p>>",
                language: {
                    url: "//cdn.datatables.net/plug-ins/1.13.1/i18n/es-ES.json",
                },
                buttons: {
                    dom: {
                        button: {
                            tag: "button",
                            className: "btn",
                        },
                    },
                    buttons: [
                        {
                            extend: "excel",
                            className: "btn-success",
                            text: "<i class='far fa-file-excel'></i> Excel",
                            extension: ".xlsx",
                        },
                    ],
                },
            });
        }
        return () => {
            if (dataTable) {
                dataTable.destroy();
            }
        };
    }, [consultClicked]);

    return (
        <>
            <Helmet>
                <title>Reporte cargue Robot</title>
            </Helmet>

            <div style={{ maxWidth: "1200px", margin: "50px auto" }}>
                <div>
                    <BreadCrum etiquetas={etiquetas} />
                </div>
                <div style={{ width: "100%", overflowX: "auto" }}>
                    <td></td>
                    <div style={{ height: "20px" }}></div>
                    {/* <div className="ConsultReport">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={2} sx={{ minWidth: 305 }}>
                <DatePicker
                  label="FechaInicial"
                  id="dateStart"
                  value={dateStart}
                  onChange={setDateStart}
                />
              </Stack>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={2} sx={{ minWidth: 305 }}>
                <DatePicker
                  label="FechaFinal"
                  value={dateEnd}
                  onChange={setDateEnd}
                />
              </Stack>
            </LocalizationProvider>

            <Button
              id="buttonConsult"
              className="mb-3"
              color="secondary"
              onClick={handleConsult}
            >
              Consultar
            </Button>
          </div> */}
                    <table
                        ref={tableRef}
                        id="myTable"
                        className="table"
                        style={{ width: "100%" }}
                    >
                        <thead>
                            <tr>
                                <th>Nombre Paciente</th>
                                <th>Tipo de Documento</th>
                                <th>Numero Documento</th>
                                <th>Fecha De Nacimiento</th>
                                <th>Edad</th>
                                <th>Telefono contacto</th>
                                <th>Ciudad de Origen</th>
                                <th>Fecha Solicitud</th>
                                <th>Numero de Caso</th>
                                <th>Fecha Inicial</th>
                                <th>Fecha Final</th>
                                <th>Prestador</th>
                                <th>Direccion</th>
                                <th>Telefonos Contacto</th>
                                <th>Ciudad</th>
                                <th>Numero MIPRES</th>
                                <th>Tipo de solicitud</th>
                                <th>Servicio</th>
                                <th>Codigo Servicio</th>
                                <th>Descripción</th>
                                <th>Primer Servicio</th>
                                <th>Fecha de consulta</th>
                                <th>IPS</th>
                                <th>Tipo de Admisión</th>
                                <th>Segundo Servicio</th>
                                <th>Fecha de consulta</th>
                                <th>IPS</th>
                                <th>Tipo de Admisión</th>
                                <th>Tercer Servicio</th>
                                <th>Fecha de consulta</th>
                                <th>IPS</th>
                                <th>Tipo de Admisión</th>
                                <th>Cuarto Servicio</th>
                                <th>Fecha de consulta</th>
                                <th>IPS</th>
                                <th>Tipo de Admisión</th>
                                <th>quintoServicio</th>
                                <th>Fecha de consulta</th>
                                <th>IPS</th>
                                <th>Tipo de Admisión</th>
                                <th>Tipo Acompañante 1</th>
                                <th>Primer Acompañante</th>
                                <th>Tipo documento primer acompañante</th>
                                <th>Numero de Documento</th>
                                <th>Fecha Nacimiento primer acompañante</th>
                                <th>Edad Primer Acompañante</th>
                                <th>Tipo Acompañante 2</th>
                                <th>Segundo Acompañante</th>
                                <th>Tipo de documento</th>
                                <th>Numero de Documento</th>
                                <th>Fecha de Nacimiento</th>
                                <th>Edad</th>
                                <th>Observaciones</th>
                                <th>Fecha de inserción</th>
                                <th>Editar</th>
                                <th>Guardar</th>
                                ...
                            </tr>
                        </thead>
                        {consultClicked ? (
                            <tbody>
                                {dataAutomata.map((element) => (
                                    <tr key={element.id}>
                                        <td>{element.Apellido1}</td>
                                        <td>
                                            {element.TipoDocumento
                                                ? element.TipoDocumento
                                                : "No hay información"}
                                        </td>
                                        <td>{element.NumeroDocumento}</td>
                                        <td>
                                            {element.FechaNacimiento
                                                ? element.FechaNacimiento
                                                : "No existe informacion"}
                                        </td>
                                        <td>
                                            {element.Edad
                                                ? element.Edad
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.TelefonoMovil
                                                ? element.TelefonoMovil
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.CiudadOrigen
                                                ? element.CiudadOrigen
                                                : " "}
                                        </td>
                                        <td>{element.FechaSolicitud}</td>
                                        <td>{element.NumeroCaso}</td>
                                        <td>{element.FechaInicialServivio}</td>
                                        <td>{element.FechaFinalServicio}</td>
                                        <td>
                                            {element.Prestador
                                                ? element.Prestador
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.Direccion
                                                ? element.Direccion
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.TelefonosContacto
                                                ? element.TelefonosContacto
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.Ciudad
                                                ? element.Ciudad
                                                : "No existe información"}
                                        </td>
                                        <td>{element.NumeroMIPRES}</td>
                                        <td>
                                            {element.TipoSolicitud
                                                ? element.TipoSolicitud
                                                : " "}
                                        </td>
                                        <td>
                                            {element.DescripcionCubrimiento
                                                ? element.DescripcionCubrimiento
                                                : " "}
                                        </td>
                                        <td>{element.CodigoServicio}</td>
                                        <td>{element.Descripcion}</td>
                                        <td>
                                            {element.TipoServicio1
                                                ? element.TipoServicio1
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.FechaConsulta1
                                                ? element.FechaConsulta1
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.IPS1
                                                ? element.IPS1
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.TipoAdmision1
                                                ? element.TipoAdmision1
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.TipoServicio2
                                                ? element.TipoServicio2
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.FechaConsulta2
                                                ? element.FechaConsulta2
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.IPS2
                                                ? element.IPS2
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.TipoAdmision2
                                                ? element.TipoAdmision2
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.TipoServicio3
                                                ? element.TipoServicio3
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.FechaConsulta3
                                                ? element.FechaConsulta3
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.IPS3
                                                ? element.IPS3
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.TipoAdmision3
                                                ? element.TipoAdmision3
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.TipoServicio4
                                                ? element.TipoServicio4
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.FechaConsulta4
                                                ? element.FechaConsulta4
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.IPS4
                                                ? element.IPS4
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.TipoAdmision4
                                                ? element.TipoAdmision4
                                                : " No existe información"}
                                        </td>
                                        <td>
                                            {element.TipoServicio5
                                                ? element.TipoServicio5
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.FechaConsulta5
                                                ? element.FechaConsulta5
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.IPS5
                                                ? element.IPS5
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.TipoAdmision5
                                                ? element.TipoServicio5
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.TipoAcompanante1
                                                ? element.TipoAcompanante1
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {(element.Nombre1Acompanante1
                                                ? element.Nombre1Acompanante1
                                                : " No existe información") +
                                                " " +
                                                (element.Nombre2Acompanante1
                                                    ? element.Nombre2Acompanante1
                                                    : " ") +
                                                " " +
                                                element.Apellido1Acompanante1 +
                                                " " +
                                                (element.Apellido2Acompanante1
                                                    ? element.Apellido2Acompanante1
                                                    : " ")}
                                        </td>
                                        <td>
                                            {element.TipoDocumentoAcompanante1
                                                ? element.TipoDocumentoAcompanante1
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.NumeroDocumentoAcompanante1
                                                ? element.NumeroDocumentoAcompanante1
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.FechaNacimientoAcompanante1
                                                ? element.FechaNacimientoAcompanante1
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.EdadAcompanante1
                                                ? element.EdadAcompanante1
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.TipoAcompanante2
                                                ? element.TipoAcompanante2
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {(element.Nombre1Acompanante2
                                                ? element.Nombre1Acompanante2
                                                : " No existe información") +
                                                " " +
                                                (element.Nombre2Acompanante2
                                                    ? element.Nombre2Acompanante2
                                                    : " ") +
                                                " " +
                                                element.Apellido1Acompanante2 +
                                                " " +
                                                (element.Apellido2Acompanante2
                                                    ? element.Apellido2Acompanante2
                                                    : " ")}
                                        </td>
                                        <td>
                                            {element.TipoDocumentoAcompanante2
                                                ? element.TipoDocumentoAcompanante2
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.NumeroDocumentoAcompanante2
                                                ? element.NumeroDocumentoAcompanante2
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.FechaNacimientoAcompanante2
                                                ? element.FechaNacimientoAcompanante2
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            {element.EdadAcompanante2
                                                ? element.EdadAcompanante2
                                                : "No existe información"}
                                        </td>
                                        <td>{element.Observaciones}</td>
                                        <td>
                                            {element.created_at
                                                ? new Date(
                                                      element.created_at
                                                  ).toLocaleString()
                                                : "No existe información"}
                                        </td>
                                        <td>
                                            <Button
                                                className="mb-3"
                                                color="secondary"
                                                onClick={() =>
                                                    handleEditClick(element, {
                                                        title: "Actualizar Formulario",
                                                        footer: (
                                                            <Button
                                                                className="mb-3"
                                                                color="secondary"
                                                                onClick={
                                                                    handleModalToggle
                                                                }
                                                            >
                                                                Cancelar
                                                            </Button>
                                                        ),
                                                    })
                                                }
                                            >
                                                Editar
                                            </Button>
                                        </td>
                                        <td>
                                            <Button
                                                id="btn-send"
                                                onClick={() =>
                                                    handleCreateSubmit(
                                                        element,
                                                        element.id
                                                    )
                                                }
                                            >
                                                Guardar
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        ) : (
                            <tbody>
                                {/* Renderiza una tabla vacía o un mensaje de espera */}
                                <tr>
                                    <td colSpan="6">Cargando información...</td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                    <ModalEdit
                        isOpen={modalEditOpen}
                        toggle={handleEditModalToggle}
                        title={modalTittleContent.title}
                        id={modalEditcontent.id}
                        Apellido1={modalEditcontent.Apellido1}
                        Apellido1Acom1={modalEditcontent.Apellido1Acompanante1}
                        Apellido1Acom2={modalEditcontent.Apellido1Acompanante2}
                        Apellido2={modalEditcontent.Apellido2}
                        Apellido2Acom1={modalEditcontent.Apellido2Acompanante1}
                        Apellido2Acom2={modalEditcontent.Apellido2Acompanante2}
                        Ciudad={modalEditcontent.Ciudad}
                        CiudadOrigen={modalEditcontent.CiudadOrigen}
                        CodigoServicio={modalEditcontent.CodigoServicio}
                        Descripcion={modalEditcontent.Descripcion}
                        DescripcionCubrimiento={
                            modalEditcontent.DescripcionCubrimiento
                        }
                        Direccion={modalEditcontent.Direccion}
                        Edad={modalEditcontent.Edad}
                        Edad1Acompanante={modalEditcontent.EdadAcompanante1}
                        Edad2Acompanante={modalEditcontent.EdadAcompanante2}
                        FechaConsulta1={modalEditcontent.FechaConsulta1}
                        FechaConsulta2={modalEditcontent.FechaConsulta2}
                        FechaConsulta3={modalEditcontent.FechaConsulta3}
                        FechaConsulta4={modalEditcontent.FechaConsulta4}
                        FechaConsulta5={modalEditcontent.FechaConsulta5}
                        FechaInicialServicio={
                            modalEditcontent.FechaInicialServivio
                        }
                        FechaFinalServicio={modalEditcontent.FechaFinalServicio}
                        FechaSolicitud={modalEditcontent.FechaSolicitud}
                        FechaNacimiento={modalEditcontent.FechaNacimiento}
                        FechaNacimiento1Acom={
                            modalEditcontent.FechaNacimientoAcompanante1
                        }
                        FechaNacimiento2acom={
                            modalEditcontent.FechaNacimientoAcompanante2
                        }
                        IPS1={modalEditcontent.IPS1}
                        IPS2={modalEditcontent.IPS2}
                        IPS3={modalEditcontent.IPS3}
                        IPS4={modalEditcontent.IPS4}
                        IPS5={modalEditcontent.IPS5}
                        Nombre1={modalEditcontent.Nombre1}
                        Nombre1Acom1={modalEditcontent.Nombre1Acompanante1}
                        Nombre1Acom2={modalEditcontent.Nombre1Acompanante2}
                        Nombre2={modalEditcontent.Nombre2}
                        Nombre2Acom1={modalEditcontent.Nombre2Acompanante1}
                        Nombre2Acom2={modalEditcontent.Nombre2Acompanante2}
                        NumeroCaso={modalEditcontent.NumeroCaso}
                        NumeroDocumento={modalEditcontent.NumeroDocumento}
                        NumeroDocumenAcom1={
                            modalEditcontent.NumeroDocumentoAcompanante1
                        }
                        NumeroDocumenAcom2={
                            modalEditcontent.NumeroDocumentoAcompanante2
                        }
                        NumeroMIPRES={modalEditcontent.NumeroMIPRES}
                        Observaciones={modalEditcontent.Observaciones}
                        Prestador={modalEditcontent.Prestador}
                        TelefonoMovil={modalEditcontent.TelefonoMovil}
                        TelefonosContacto={modalEditcontent.TelefonosContacto}
                        TipoAdmision1={modalEditcontent.TipoAdmision1}
                        TipoAdmision2={modalEditcontent.TipoAdmision2}
                        TipoAdmision3={modalEditcontent.TipoAdmision3}
                        TipoAdmision4={modalEditcontent.TipoAdmision4}
                        TipoAdmision5={modalEditcontent.TipoAdmision5}
                        TipoDocumento={modalEditcontent.TipoDocumento}
                        TipoDocumentoAcompanante1={
                            modalEditcontent.TipoDocumentoAcompanante1
                        }
                        TipoDocumentoAcompanante2={
                            modalEditcontent.TipoDocumentoAcompanante2
                        }
                        TipoServicio1={modalEditcontent.TipoServicio1}
                        TipoServicio2={modalEditcontent.TipoServicio2}
                        TipoServicio3={modalEditcontent.TipoServicio3}
                        TipoServicio4={modalEditcontent.TipoServicio4}
                        TipoServicio5={modalEditcontent.TipoServicio5}
                        TipoSolicitud={modalEditcontent.TipoSolicitud}
                        footer={modalEditcontent.footer}
                        token={token}
                    />
                </div>
            </div>
        </>
    );
};

export default withTokenCheck(AppIps);
