import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";

const ModalCreate = ({ isOpen, toggle, title, token, updateTable }) => {
  const [formData, setFormData] = useState({
    selectedPaciente: "",
    selectedTipoAcompanante: "",
    nombre1: "",
    nombre2: "",
    apellido1: "",
    apellido2: "",
    selectedTipoDocumento: "",
    numeroIdentificacion: "",
    fechaNacimiento: "",
  });

  const [pacienteOptions, setPacienteOptions] = useState([]);
  const [tipoAcompananteOptions, setTipoAcompananteOptions] = useState([]);
  const [tipoDocumentoOptions, setTipoDocumentoOptions] = useState([]);

  const modalRef = useRef(null);

  useEffect(() => {
    if (token) {
      axios
        .get("/tipoacompanante", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setTipoAcompananteOptions(response.data.map((option) => ({
            value: option.id,
            label: option.Texto || "",
          })));
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get("/tipodocumento", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setTipoDocumentoOptions(response.data.map((option) => ({
            value: option.id,
            label: option.Codigo + " - " + (option.Texto || ""),
          })));
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get("/paciente", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setPacienteOptions(response.data.map((option) => ({
            value: option.id,
            label: (option.NumeroIdent || "") + " " + (option.Nombre1 || "") + " " + (option.Apellido1 || ""),
          })));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleCreateSubmit = (event) => {
    event.preventDefault();

    // Enviar los datos de ls IPs
    axios
      .post(
        "/acompanante",
        {
          TipoAcompananteId: parseInt(formData.selectedTipoAcompanante),
          PacienteId: parseInt(formData.selectedPaciente),
          Nombre1: formData.nombre1,
          Nombre2: formData.nombre2,
          Apellido1: formData.apellido1,
          Apellido2: formData.apellido2,
          TipoDocumentoId: parseInt(formData.selectedTipoDocumento),
          NumeroIdent: parseInt(formData.numeroIdentificacion),
          FechaNacimiento: formData.fechaNacimiento,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Creado!",
          text: "El acompañante ha sido vinculado con exito",
          showCloseButton: true,
        }).then((result) => {
          updateTable();
          toggle();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Ha ocurrido un error al vincular el acompañante.",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label htmlFor="PacienteId">Paciente relacionado:</label>
          <Select
            options={pacienteOptions}
            onChange={(selectedOption) =>
              handleChange("selectedPaciente", selectedOption.value)
            }
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="TipoAcompananteId">Tipo de Acompañante:</label>
          <Select
            options={tipoAcompananteOptions}
            onChange={(selectedOption) =>
              handleChange("selectedTipoAcompanante", selectedOption.value)
            }
          />
        </FormGroup>
        <FormGroup>
          <label>Primer Nombre:</label>
          <input
            className="form-control"
            name="Nombre1"
            type="text"
            value={formData.nombre1}
            onChange={(event) => handleChange("nombre1", event.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <label>Segundo Nombre:</label>
          <input
            className="form-control"
            name="Nombre2"
            type="text"
            value={formData.nombre2}
            onChange={(event) => handleChange("nombre2", event.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <label>Primer Apellido:</label>
          <input
            className="form-control"
            name="Apellido1"
            type="text"
            value={formData.apellido1}
            onChange={(event) => handleChange("apellido1", event.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <label>Segundo Apellido:</label>
          <input
            className="form-control"
            name="Apellido2"
            type="text"
            value={formData.apellido2}
            onChange={(event) => handleChange("apellido2", event.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="TipoDocumentoId">Tipo de Documento:</label>
          <Select
            options={tipoDocumentoOptions}
            onChange={(selectedOption) =>
              handleChange("selectedTipoDocumento", selectedOption.value)
            }
          />
        </FormGroup>
        <FormGroup>
          <label>Numero de Documento:</label>
          <input
            className="form-control"
            name="fechaInicial"
            type="text"
            value={formData.numeroIdentificacion}
            onChange={(event) =>
              handleChange("numeroIdentificacion", event.target.value)
            }
          />
        </FormGroup>
        <FormGroup>
          <label>Fecha de Nacimiento:</label>
          <input
            className="form-control"
            name="fechaNacimiento"
            type="date"
            value={formData.fechaNacimiento}
            onChange={(event) =>
              handleChange("fechaNacimiento", event.target.value)
            }
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleCreateSubmit} color="primary" otype="submit">
          Insertar
        </Button>
        <Button color="danger" onClick={toggle}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalCreate;