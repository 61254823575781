import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
    Button,
    Container,
    Modal,
    ModalBody,
    ModalHeader,
    FormGroup,
    ModalFooter,
    Input,
    Col,
    Row,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { makeStyles } from "@material-ui/core/styles";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
} from "@material-ui/core";
import ExcelJS from "exceljs";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import withTokenCheck from "../withTokenCheck";

const useStyles = makeStyles({
    tableContainer: {
        maxHeight: 500,
    },
    tableCell: {
        textAlign: "left",
    },
    button: {
        marginRight: 10,
    },
});

const AppRoom = ({ token }) => {
    const [room, setRoom] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [lender, setLender] = useState([]);
    const [selectedLender, setSelectedLender] = useState("");
    const [numberRoom, setNumberRoom] = useState("");
    const [numberBed, setNumberBed] = useState("");
    const [formMode, setFormMode] = useState("none");
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState("");
    const tableRef = useRef(null);

    // Obtener todas las ciudades al cargar el componente
    useEffect(() => {
        axios
            .get("/hogar")
            .then((response) => {
                setRoom(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .get("/hogar")
            .then((response) => {
                setLender(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    // Manejar el envío del formulario para crear una nueva ciudad
    const handleCreateSubmit = (event) => {
        event.preventDefault();

        axios
            .post("/habitacion", {
                HogarId: parseInt(selectedLender),
                NumeroHabitacion: numberRoom,
                CantidadCamas: numberBed,
            })
            .then((response) => {
                setRoom([...room, response.data]);
                setLender([...lender, response.data.CiudadId]);
                setNumberRoom("");
                setNumberBed("");
                setFormMode("none");
                Swal.fire({
                    icon: "success",
                    title: "Guardado!",
                    text: "el hogar a sido configurado con exito.",
                    showCloseButton: true,
                });
            })

            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "Ha ocurrido un error al configurar el Hogar.",
                });
            });
    };

    // Manejar el envío del formulario para editar una ciudad existente
    const handleEditSubmit = (event) => {
        event.preventDefault();
        axios
            .put(`/habitacion/${selectedRoom.id}`, {
                HogarId: parseInt(selectedLender),
                NumeroHabitacion: numberRoom,
                CantidadCamas: numberBed,
            })
            .then((response) => {
                setRoom(
                    room.map((c) =>
                        c.id === selectedRoom.id ? response.data : c
                    )
                );
                setLender(
                    lender.map((c) =>
                        c.id === selectedLender.id ? response.data : c
                    )
                );
                setNumberRoom("");
                setNumberBed("");
                setFormMode("none");
                Swal.fire({
                    icon: "success",
                    title: "Actualizado!",
                    text: "El hogar ha sido actualizado con exito.",
                    showCloseButton: true,
                });
            })

            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: error.response.data.message,
                    showCloseButton: true,
                });
            });
    };

    // Manejar el clic en el botón "Editar"
    const handleEditClick = (room) => {
        setSelectedRoom(room.HogarId);
        setNumberRoom(room.NumeroHabitacion);
        setNumberBed(room.CantidadCamas);
        setFormMode("edit");
    };

    // Manejar el clic en el botón "Borrar"
    const handleDeleteClick = (roomToDelete) => {
        axios
            .delete(`/habitacion/${roomToDelete.id}`)
            .then((response) => {
                setRoom(room.filter((room) => room.id !== room.id));
                Swal.fire({
                    icon: "success",
                    title: "Eliminado!",
                    text: "la configuración del hogar ha sido eliminado con exito.",
                    showCloseButton: true,
                });
            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "Ha ocurrido un error al eliminar la configuración del hogar.",
                });
            });
    };

    // Manejar el cambio en el campo de descripción
    const handleLenderChange = (event) => {
        setSelectedLender(event.target.value);
    };
    const handleNumberRoomChange = (event) => {
        setNumberRoom(event.target.value);
    };
    const handleNumberBedChange = (event) => {
        setNumberBed(event.target.value);
    };

    // Manejar el clic en el botón "Crear"
    const handleCreateClick = () => {
        setNumberRoom("");
        setNumberBed("");
        setFormMode("create");
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setPage(0);
    };

    const filteredData = room.filter((item) =>
        item.Nombre.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleExport = () => {
        const columnsToExport = [
            "HogarId",
            "NumeroHabitacion",
            "CantidadCamas",
        ]; // Reemplaza con los nombres de las columnas que deseas exportar
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Data");

        // Add headers
        columnsToExport.forEach((column, index) => {
            worksheet.getCell(1, index + 1).value = column;
        });

        // Add data
        room.forEach((row, rowIndex) => {
            columnsToExport.forEach((column, columnIndex) => {
                worksheet.getCell(rowIndex + 2, columnIndex + 1).value =
                    row[column];
            });
        });

        // Generate XLSX file
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Hogares.xlsx");
            document.body.appendChild(link);
            link.click();
        });
    };

    return (
        <>
            <Container>
                <br />
                <Button color="primary" onClick={handleCreateClick}>
                    Configuar habitaciones
                </Button>
                <br />
                <br />
                <TableContainer className={classes.tableContainer}>
                    <Row>
                        <Col md={6}>
                            <Button id="btn-export" onClick={handleExport}>
                                Export to XLSX
                            </Button>
                        </Col>
                        <Col md={6}>
                            <Input
                                className="text-rigth"
                                type="text"
                                placeholder="filtrar por nombre"
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </Col>
                    </Row>
                    <Table className="mt-3" ref={tableRef} stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableCell}>
                                    Hogar Relacionado
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    Numero de habitaciones
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    Cantidad de Camas
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((element) => (
                                    <TableRow key={element.id}>
                                        <TableCell
                                            className={classes.tableCell}
                                        >
                                            {element.hogar.Nombre}
                                        </TableCell>
                                        <TableCell>
                                            {element.NumeroHabitacion}
                                        </TableCell>
                                        <TableCell>
                                            {element.CantidadCamas}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                color="primary"
                                                onClick={() =>
                                                    handleEditClick(element)
                                                }
                                            >
                                                Editar
                                            </Button>
                                            <Button
                                                color="danger"
                                                onClick={() =>
                                                    handleDeleteClick(element)
                                                }
                                            >
                                                Eliminar
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Container>
            <Modal isOpen={formMode === "create"} toggle={handleCreateClick}>
                <ModalHeader toggle={() => setFormMode("none")}>
                    configurar Hogar
                </ModalHeader>
                <form onSubmit={handleCreateSubmit}>
                    <ModalBody>
                        <FormGroup>
                            <label htmlFor="hogarId">Hogar:</label>
                            <Select
                                fullWidth
                                labelId="hogar-id-label"
                                id="hogarId"
                                value={selectedLender}
                                onChange={handleLenderChange}
                                label="hogar"
                            >
                                {lender.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.Nombre ?? option.Nombre}{" "}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormGroup>
                        <FormGroup>
                            <label>Numero de Habitaciones:</label>
                            <input
                                className="form-control"
                                name="numberRoom"
                                type="text"
                                value={numberRoom}
                                onChange={handleNumberRoomChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Numero de Camas:</label>
                            <input
                                className="form-control"
                                name="numberBed"
                                type="text"
                                value={numberBed}
                                onChange={handleNumberBedChange}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" otype="submit">
                            Insertar
                        </Button>
                        <Button
                            color="danger"
                            onClick={() => setFormMode("none")}
                        >
                            Cancelar
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>
            <Modal isOpen={formMode === "edit"} toggle={handleEditClick}>
                <ModalHeader toggle={() => setFormMode("none")}>
                    Editar Hogar
                </ModalHeader>
                <form onSubmit={handleEditSubmit}>
                    <ModalBody>
                        <FormGroup>
                            <label>Hogar:</label>
                            <input
                                className="form-control"
                                name="hogar"
                                type="text"
                                onChange={handleLenderChange}
                                value={lender}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Numero de Habitaciones:</label>
                            <input
                                className="form-control"
                                name="numeroHabitacion"
                                type="text"
                                onChange={handleNumberRoomChange}
                                value={numberRoom}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Cantidad de Camas:</label>
                            <input
                                className="form-control"
                                name="numeroCamas"
                                type="text"
                                onChange={handleNumberBedChange}
                                value={numberBed}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            Guardar
                        </Button>
                        <Button
                            color="danger"
                            onClick={() => setFormMode("none")}
                        >
                            Cancelar
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    );
};

export default withTokenCheck(AppRoom);
