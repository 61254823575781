import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";

const ModalCreate = ({ isOpen, toggle, title, token }) => {
  const [menuDetail, setMenuDetail] = useState("");
  const [path, setPath] = useState("");
  const [menu, setMenu] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [selectedMenu2, setSelectedMenu2] = useState("");
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);

  const handleMenuDetailChange = (event) => {
    setMenuDetail(event.target.value);
  };

  const handlePathChange = (event) => {
    setPath(event.target.value);
  };

  const handleMenuChange = (selectedOption) => {
    setSelectedMenu2(selectedOption.value);
    setSelectedMenu(
      menuOptions.find((option) => option.value === selectedOption.value)
    );
  };

  useEffect(() => {
    if (token) {
      axios
        .get("/menumaestro", { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          setMenu(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  const handleCreateSubmit = (event) => {
    event.preventDefault();
    axios
      .post(
        "/menudetalle",
        {
          Nombre: menuDetail,
          MenuMaestroId: parseInt(selectedMenu2),
          Path: path,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Creado!",
          text: "el detalle del Menú ha sido creada con exito",
          showCloseButton: true,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Ha ocurrido un error al crear el detalle del Menú.",
          showCloseButton: true,
        });
      });
  };

  const menuOptions = menu.map((option) => ({
    value: option.id,
    label: option.Nombre ?? option.nombre,
  }));

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label htmlFor="MenuId">Menú Maestro:</label>
          <Select options={menuOptions} onChange={handleMenuChange}></Select>
        </FormGroup>
        <FormGroup>
          <label>Nombre</label>
          <input
            className="form-control"
            name="MenuMasterId"
            type="text"
            value={menuDetail}
            onChange={handleMenuDetailChange}
          />
        </FormGroup>
        <FormGroup>
          <label>Path:</label>
          <input
            className="form-control"
            name="path"
            type="text"
            value={path}
            onChange={handlePathChange}
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleCreateSubmit} color="primary" otype="submit">
          Insertar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalCreate;
