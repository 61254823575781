import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";

const ModalCreate = ({ isOpen, toggle, title, token, FormId, updateTable }) => {
    const [hogar, setHogar] = useState([]);
    const [selectedHogar, setSelectedHogar] = useState("");
    const [selectedHogar2, setSelectedHogar2] = useState("");
    const [formMode, setFormMode] = useState("none");
    const modalRef = useRef(null);

    const handleHogarChange = (selectedOption) => {
        setSelectedHogar2(selectedOption.value);
        setSelectedHogar(
            hogarOptions.find((option) => option.value === selectedOption.value)
        );
    };
    useEffect(() => {
        if (token) {
            axios
                .get("/hogar", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setHogar(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const handleCreateSubmit = (event) => {
        if (token) {
            axios
                .post(
                    `/formulario/autorizar/${FormId}`,
                    {
                        Autorizado: true,
                        HogarId: parseInt(selectedHogar2),
                    },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                )
                .then((response) => {
                    Swal.fire({
                        icon: "success",
                        title: "Actualizado!",
                        text: "El caso ha sido autorizado con exito.",
                        showCloseButton: true,
                    }).then(() => {
                        updateTable();
                        toggle();
                    });
                })
                .catch((error) => {
                    console.log(error);
                    Swal.fire({
                        icon: "error",
                        title: "Error!",
                        text: error.response.data.error,
                        showCloseButton: true,
                    });
                });
        }
    };

    const hogarOptions = hogar.map((option) => ({
        value: option.id,
        label: option.Nombre ?? option.nombre,
    }));

    return (
        <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <label htmlFor="MenuId">Hogar a asignar:</label>
                    <Select
                        options={hogarOptions}
                        onChange={handleHogarChange}
                    ></Select>
                </FormGroup>
            </ModalBody>

            <ModalFooter>
                <Button
                    onClick={handleCreateSubmit}
                    color="primary"
                    otype="submit"
                >
                    Autorizar
                </Button>
                <Button color="danger" onClick={() => setFormMode("none")}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalCreate;
