import { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import List from "@mui/material/List";
import Logo from "./Logo.jpg";
import axios from "axios";
import "./Navbar.css";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

export default function ButtonAppBar(props) {
    const [token, setToken] = useState("");
    const [dataLoadedInfoUser, setDataLoadedInfoUser] = useState(false);
    const [dataLoadedMenuDetalle, setDataLoadedMenuDetalle] = useState(false);
    const [dataLoadedMenuRol, setDataLoadedMenuRol] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl1, setAnchorEl1] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [anchorAdmin, setAnchorAdmin] = useState(null);
    const [anchorSolicitudes, setAnchorSolicitudes] = useState(null);
    const [infoUserRol, setInfoUser] = useState("");
    const [infoMenu, setInfoMenu] = useState("");
    const [userMenusEvent, setUserMenusEvent] = useState([]);
    const [userMenusTables, setUserMenusTables] = useState([]);
    const [userMenusReport, setUserMenusReport] = useState([]);
    const [userMenusAdmin, setUserMenusAdmin] = useState([]);
    const [usersMenuSolicitud, setUserMenuSolicitud] = useState([]);
    const [infoMenuxRol, setInfoMenuxRol] = useState([]);
    const [infoMenuMaestro, setInfoMenuMaestro] = useState([]);
    const { login, handleMenuOf } = props;

    const navigate = useNavigate();

    const logoutSubmit = (e) => {
        e.preventDefault();
        if (token) {
            axios
                .post("/logout", null, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    if (response.data.status === true) {
                        Cookies.remove("token"); // Elimina la cookie al cerrar sesión
                        navigate(`/signin`);
                    }
                });
        } else {
            Cookies.remove("token");
            navigate(`/signin`);
            localStorage.setItem("session", 0);
        }
    };

    useEffect(() => {
        const storedToken = Cookies.get("token"); // Lee el token de la cookie
        if (storedToken) {
            setToken(storedToken);
        } else {
            navigate("/signin"); // Redirige al inicio de sesión si no hay token
            setToken("");
            localStorage.setItem("loggedIn", false);
        }
    }, []);

    useEffect(() => {
        if (token.trim() !== "") {
            axios
                .get("/infouser", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setInfoUser(response.data.user.RolId);
                    setDataLoadedInfoUser(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (token.trim() !== "") {
            axios
                .get("/menudetalle", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setInfoMenu(response.data);
                    setDataLoadedMenuDetalle(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (token.trim() !== "") {
            axios
                .get("/menuporrol", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setInfoMenuxRol(response.data);
                    setDataLoadedMenuRol(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (
            dataLoadedInfoUser &&
            dataLoadedMenuDetalle &&
            dataLoadedMenuRol &&
            infoMenuxRol.length > 0 &&
            infoUserRol
        ) {
            const filteredMenus = infoMenuxRol
                .filter((menu) => menu.RolId === infoUserRol)
                .map((menu) =>
                    infoMenu.find((item) => item.id === menu.MenuDetalleId)
                );
            setUserMenusEvent(
                filteredMenus.filter((menu) => menu.MenuMaestroId === 4)
            );
            setUserMenusTables(
                filteredMenus.filter((menu) => menu.MenuMaestroId === 3)
            );
            setUserMenusReport(
                filteredMenus.filter((menu) => menu.MenuMaestroId === 2)
            );
            setUserMenusAdmin(
                filteredMenus.filter((menu) => menu.MenuMaestroId === 1)
            );
            setUserMenuSolicitud(
                filteredMenus.filter((menu) => menu.MenuMaestroId === 5)
            );
        }
    }, [
        dataLoadedInfoUser,
        dataLoadedMenuDetalle,
        dataLoadedMenuRol,
        infoMenuxRol,
        infoUserRol,
        infoMenu,
    ]);

    const handleClickSolicitudes = (event) => {
        setAnchorSolicitudes(event.currentTarget);
    };

    const handleCloseSolicitudes = () => {
        setAnchorSolicitudes(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const handleClickAdmin = (event) => {
        setAnchorAdmin(event.currentTarget);
    };

    const handleCloseAdmin = () => {
        setAnchorAdmin(null);
    };

    return (
        <>
            {props.authLink ? (
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar id="nav" position="static">
                        <Toolbar>
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{ flexGrow: 1 }}
                            >
                                <Link to="/home">
                                    <img
                                        id="logo"
                                        src={Logo}
                                        height="50"
                                        alt="No se encontro"
                                    />
                                </Link>
                            </Typography>
                            {[
                                {
                                    anchorEl: anchorSolicitudes,
                                    handleClick: handleClickSolicitudes,
                                    menuItems: usersMenuSolicitud,
                                    handleClose: handleCloseSolicitudes,
                                    label: "Solicitudes",
                                },
                                {
                                    anchorEl: anchorEl1,
                                    handleClick: handleClick1,
                                    menuItems: userMenusTables,
                                    handleClose: handleClose1,
                                    label: "Tablas de Referencia",
                                },
                                {
                                    anchorEl: anchorEl,
                                    handleClick: handleClick2,
                                    menuItems: userMenusReport,
                                    handleClose: handleClose,
                                    label: "Cambios de Evento",
                                },
                                {
                                    anchorEl: anchorEl2,
                                    handleClick: handleClick,
                                    menuItems: userMenusEvent,
                                    handleClose: handleClose2,
                                    label: "Reportes",
                                },
                                {
                                    anchorEl: anchorAdmin,
                                    handleClick: handleClickAdmin,
                                    menuItems: userMenusAdmin,
                                    handleClose: handleCloseAdmin,
                                    label: "Administrador",
                                },
                            ].map(
                                ({
                                    anchorEl,
                                    handleClick,
                                    menuItems,
                                    handleClose,
                                    label,
                                }) => (
                                    <div key={label} className="Menunavbar">
                                        <Button
                                            id="button-menu"
                                            aria-controls={
                                                anchorEl
                                                    ? "basic-menu"
                                                    : undefined
                                            }
                                            aria-haspopup="true"
                                            aria-expanded={Boolean(anchorEl)}
                                            onClick={(event) =>
                                                handleClick(event, anchorEl)
                                            }
                                        >
                                            {label}
                                        </Button>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={() =>
                                                handleClose(anchorEl)
                                            }
                                            MenuListProps={{
                                                "aria-labelledby":
                                                    "basic-button",
                                            }}
                                        >
                                            <List>
                                                {menuItems
                                                    .sort((a, b) =>
                                                        a.Nombre.localeCompare(
                                                            b.Nombre
                                                        )
                                                    )
                                                    .map((menu) => (
                                                        <NavLink
                                                            key={menu.id}
                                                            to={menu.Path}
                                                            style={{
                                                                textDecoration:
                                                                    "none",
                                                            }}
                                                        >
                                                            <MenuItem
                                                                id="button-menu"
                                                                onClick={() =>
                                                                    handleClose(
                                                                        anchorEl
                                                                    )
                                                                }
                                                            >
                                                                {menu.Nombre}
                                                            </MenuItem>
                                                        </NavLink>
                                                    ))}
                                            </List>
                                        </Menu>
                                    </div>
                                )
                            )}
                            <MenuItem>
                                <Link id="button-menu" to="/calendar">
                                    Calendario
                                </Link>
                            </MenuItem>
                            <MenuItem>
                                <Button
                                    className="btn-logout"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        logoutSubmit(event);
                                        handleMenuOf();
                                    }}
                                >
                                    Cerrar sesión
                                </Button>
                            </MenuItem>
                        </Toolbar>
                    </AppBar>
                </Box>
            ) : (
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar id="nav" position="static">
                        <Toolbar>
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{ flexGrow: 1 }}
                            >
                                <img
                                    src={Logo}
                                    height="50"
                                    alt="No se encontro"
                                />
                            </Typography>
                            <MenuItem>
                                <Link id="calendar" to="/calendar">
                                    Calendario
                                </Link>
                            </MenuItem>
                            <MenuItem>
                                <NavLink
                                    id="signin"
                                    to={{
                                        pathname: "/signin",
                                        state: { login },
                                    }}
                                >
                                    Iniciar sesión
                                </NavLink>
                            </MenuItem>
                        </Toolbar>
                    </AppBar>
                </Box>
            )}
        </>
    );
}
