import { TextField } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";

const CustomModal = ({ isOpen, toggle, infoModal, updateTable, token }) => {
    const [hogar, setHogar] = useState([]);
    const [userHogarId, setUserHogarId] = useState(null);
    const [hogarId, setHogarId] = useState("");
    const [persona, setPersona] = useState("");
    const [formId, setFormId] = useState("");
    const [personaId, setPersonaId] = useState("");
    const [tipoPersona, setTipoPersona] = useState("");
    const [title, setTitle] = useState("");
    const modalRef = useRef(null);

    useEffect(() => {
        setFormId(infoModal.FormularioId);
        setPersonaId(infoModal.PersonaId);
        setPersona(infoModal.NombreCompleto);
        setHogarId(infoModal.HogarId);
        setTitle("Reasignación");
    }, [infoModal]);

    useEffect(() => {
        if (token) {
            axios
                .get("/hogar", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setHogar(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const hogarOptions = hogar.map((option) => ({
        value: option.id,
        label: option.Nombre,
    }));

    const handleCreateSubmit = (event) => {
        event.preventDefault();
        // Construir los datos del paciente
        const patientData = {
            FormularioId: formId,
            HogarId: userHogarId,
        };

        // Enviar los datos del paciente y de los acompañantes
        axios
            .put("/asignacion/actualizacionHogar/reasignaciones", patientData, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: "Actualizado!",
                    text: "La reasignación ha sido registrada con exito.",
                    showCloseButton: true,
                }).then(() => {
                    updateTable();
                    toggle();
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: error.response.data.message,
                    showCloseButton: true,
                });
            });
    };

    const handleUserHogarChange = (selectedOption) => {
        setUserHogarId(selectedOption.value);
    };

    return (
        <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <label>Usuario</label>
                    <TextField
                        disabled
                        className="form-control"
                        name="Texto"
                        type="text"
                        value={persona}
                    />
                </FormGroup>
                <FormGroup>
                    <label htmlFor="habitacionId">Seleccionar Hogar:</label>
                    <Select
                        options={hogarOptions}
                        onChange={handleUserHogarChange}
                        className="form-control"
                    ></Select>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={handleCreateSubmit}
                    className="mb-4"
                    color="success"
                    type="submit"
                    id="ButtonSub"
                    variant="contained"
                >
                    Confirmar
                </Button>

                <Button
                    onClick={toggle}
                    className="mb-4"
                    type="submit"
                    color="danger"
                    id="ButtonClose"
                    variant="contained"
                >
                    Cancelar
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CustomModal;
