import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";

const ModalCreate = ({
  isOpen,
  toggle,
  title,
  token,
}) => {
  const [tipoServicio, setTipoServicio] = useState("");
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);

  const handleTiposervicioChange = (event) => {
    setTipoServicio(event.target.value);
  };
  
  const handleCreateSubmit = (event) => {
    event.preventDefault();

    // Enviar los datos de ls IPs
    if(token){
    axios
      .post("/tiposerviciohogar", {
        Texto: tipoServicio,
      }, {headers: { Authorization: `Bearer ${token}` }})
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Creado!",
          text: "el tipo de servicio ha sido creado con exito",
          showCloseButton: true,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Ha ocurrido un error al crear el tipo de servicio.",
          showCloseButton: true,
        });
      });
    }
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label>Tipo de servicio:</label>
          <input
            className="form-control"
            name="tipoSolicitud"
            type="text"
            value={tipoServicio}
            onChange={handleTiposervicioChange}
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleCreateSubmit} color="primary" otype="submit">
          Insertar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalCreate;
