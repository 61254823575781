import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";

const ModalEdit = ({
  isOpen,
  toggle,
  title,
  id,
  PlacaInicial,
  ReferenciaInicial,
  CapacidadInicial,
  RampaIni,
  token,
}) => {
  const [vehiculo, setVehiculo] = useState("");
  const [referencia, setReferencia] = useState("");
  const [capacidad, setCapacidad] = useState("");
  const [rampa, setRampa] = useState("");
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);

  useEffect(() => {
    setVehiculo(PlacaInicial);
    setReferencia(ReferenciaInicial);
    setCapacidad(CapacidadInicial);
    setRampa(RampaIni);
  }, [PlacaInicial, ReferenciaInicial, CapacidadInicial, RampaIni]);


  const handleVehiucloChange = (event) => {
    setVehiculo(event.target.value);
  };
  const handleReferenciaChange = (event) => {
    setReferencia(event.target.value);
  };
  const handleCapacidadChange = (event) => {
    setCapacidad(event.target.value);
  };
  const handleRampaChange = (selectedOption) => {
    setRampa(selectedOption.value);
  };

  const rampaOptions = [
    {
      value: "si",
      label: "Si",
    },
    {
      value: "no",
      label: "No",
    },
  ];

  const handleEditSubmit = (event) => {
    event.preventDefault();
    axios
      .put(
        `/vehiculo/${id}`,
        {
            Placa: vehiculo,
            Referencia: referencia,
            Capacidad: capacidad,
            Rampa: rampa === "si" ? true : false,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Actualizado!",
          text: "el Vehiculo ha sido actualizado con exito.",
          showCloseButton: true,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "el vehiculo no se pudo actualizar",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label>Placa vehiculo:</label>
          <input
            className="form-control"
            name="rol"
            type="text"
            value={vehiculo}
            onChange={handleVehiucloChange}
          />
        </FormGroup>
        <FormGroup>
          <label>Referencia vehiculo:</label>
          <input
            className="form-control"
            name="rol"
            type="text"
            value={referencia}
            onChange={handleReferenciaChange}
          />
        </FormGroup>
        <FormGroup>
          <label>Capacidad del vehiculo:</label>
          <input
            className="form-control"
            name="rol"
            type="text"
            value={capacidad}
            onChange={handleCapacidadChange}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="CiudadOrigenId">¿Rampa?:</label>
          <Select
            options={rampaOptions}
            onChange={handleRampaChange}
          ></Select>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleEditSubmit} color="primary" otype="submit">
          Guardar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalEdit;
