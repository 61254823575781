import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import ModalCreate from "./ModalCreate";
import ModalEdit from "./ModalEdit";
import "./EditService.css";
import Swal from "sweetalert2";
import { Button, FormGroup } from "reactstrap";
import Select from "react-select";
import BreadCrum from "../../breadCrum/breadCrum";
import { Helmet } from "react-helmet";
import withTokenCheck from "../../withTokenCheck";

const AppTypeCompanion = ({ token }) => {
    const [numberCase, setNumberCase] = useState([]);
    const [selectedCase, setSelectedCase] = useState("");
    const [selectedCase2, setSelectedCase2] = useState("");
    const [service, setService] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [modalId, setModalId] = useState({});
    const [servicioDetalleId, setServicioDetalleId] = useState({});
    const [servicioMaestroId, setServicioMaestroId] = useState({});
    const [serviceId, setServiceId] = useState("");
    const [fechafirstService, setFechaFirstService] = useState("");
    const [firstIps, setFirstIps] = useState("");
    const [firstAdmision, setFirstAdmision] = useState("");
    const [modalEditcontent, setModalEditContent] = useState({});
    const [loading, setLoading] = useState(true);
    const [dataLoaded, setDataLoaded] = useState(false);
    const tableRef = useRef(null);
    const etiquetas = [
        "Inicio Lossman",
        "Cambios De Evento",
        "Edición De Servicios",
    ];

    useEffect(() => {
        if (token) {
            axios
                .get("/edicion-formulario", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setNumberCase(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const numberCaseoptions = numberCase.map((option) => ({
        value: option.id,
        label: option.NumeroCaso ?? option.NumeroCaso,
    }));

    const handleNumberCaseChange = (selectedOption) => {
        setSelectedCase2(selectedOption.value);
        setSelectedCase(
            numberCaseoptions.find(
                (option) => option.value === selectedOption.value
            )
        );
        setModalId(selectedOption);
    };

    const handleSelectCase = () => {
        if (token) {
            axios
                .get(`/edicion-formulario/tiposervicio/${selectedCase2}`, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setService(response.data.servicio_maestro.servicio_detalle);
                    setDataLoaded(true);
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        title: "Error!",
                        text: "Ha ocurrido un error al Consultar los tipos de servicio.",
                        showCloseButton: true,
                    });
                });
        }
    };

    const handleModalToggle = () => setModalOpen(!modalOpen);
    const handleEditModalToggle = () => setModalEditOpen(!modalEditOpen);

    const handleButtonClick = (content) => {
        setModalContent(content);
        handleModalToggle();
    };

    const handleEditClick = (element) => {
        setServiceId(element.tipo_servicio);
        setFechaFirstService(element.FechaConsulta);
        setFirstIps(element.ips.id);
        setFirstAdmision(element.tipo_admision.id);
        setServicioDetalleId(element.id);
        setServicioMaestroId(element.ServicioMaestroId);
        handleEditModalToggle();
    };

    const handleDeleteClick = (serviceToDelete) => {
        axios
            .delete(`/edicion-formulario/tiposervicio/${serviceToDelete.id}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                setNumberCase(
                    service.filter(
                        (service) => service.id !== serviceToDelete.id
                    )
                );
                Swal.fire({
                    icon: "success",
                    title: "Eliminado!",
                    text: "el Servicio ha sido eliminado con exito.",
                    showCloseButton: true,
                }).then(() => {
                    window.location.reload();
                });
            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "Ha ocurrido un error al eliminar el servicio.",
                });
            });
    };

    useEffect(() => {
        let dataTable;
        if (!loading) {
            $(tableRef.current).DataTable({
                paging: true,
                ordering: true,
                info: true,
                dom: "<'row'<'col-sm-2'l><'col-sm-6'B><'col-sm-4'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-6 text-right'i><'col-sm-6'p>>",
                language: {
                    url: "//cdn.datatables.net/plug-ins/1.13.1/i18n/es-ES.json",
                },
                buttons: {
                    dom: {
                        button: {
                            tag: "button",
                            className: "btn",
                        },
                    },
                    buttons: [
                        {
                            extend: "excel",
                            className: "btn-success",
                            text: "<i class='far fa-file-excel'></i> Excel",
                            extension: ".xlsx",
                        },
                    ],
                },
            });
        }
        return () => {
            if (dataTable) {
                dataTable.destroy();
            }
        };
    }, [loading]);

    return (
        <>
            <Helmet>
                <title>Editar Servicio</title>
            </Helmet>
            {loading ? (
                <div>Cargando...</div>
            ) : (
                <div style={{ maxWidth: "1200px", margin: "50px auto" }}>
                    <div>
                        <BreadCrum etiquetas={etiquetas} />
                    </div>
                    <div style={{ width: "100%", overflowX: "auto" }}>
                        <label htmlFor="servicioId">
                            Seleccione numero de caso
                        </label>
                        <FormGroup id="FormEdit">
                            <Select
                                id="SelectEdit"
                                options={numberCaseoptions}
                                value={selectedCase}
                                onChange={handleNumberCaseChange}
                            ></Select>
                            <Button onClick={handleSelectCase}>
                                Consultar
                            </Button>
                        </FormGroup>
                        <td>
                            <Button
                                color="primary"
                                onClick={() =>
                                    handleButtonClick({
                                        title: "Crear Tipo de servicio",
                                        footer: (
                                            <Button
                                                className="mb-3"
                                                color="secondary"
                                                onClick={handleModalToggle}
                                            >
                                                Cancelar
                                            </Button>
                                        ),
                                    })
                                }
                            >
                                Crear tipo de servicio
                            </Button>
                        </td>
                        <div style={{ height: "20px" }}></div>
                        <table
                            ref={tableRef}
                            id="myTable"
                            className="table"
                            style={{ width: "100%" }}
                        >
                            <thead>
                                <tr>
                                    <th>Editar</th>
                                    <th>Eliminar</th>
                                    <th>Tipo de Servicio</th>
                                    <th>IPS prestadora</th>
                                    <th>Tipo admisión</th>
                                    <th>Fecha consulta</th>
                                    ...
                                </tr>
                            </thead>
                            {dataLoaded ? (
                                <tbody>
                                    {service.map((element) => (
                                        <tr key={element.id}>
                                            <td>
                                                <Button
                                                    className="mb-3"
                                                    color="secondary"
                                                    onClick={() =>
                                                        handleEditClick(
                                                            element,
                                                            {
                                                                title: "Actualizar Tipo de servicio",
                                                                footer: (
                                                                    <Button
                                                                        className="mb-3"
                                                                        color="secondary"
                                                                        onClick={
                                                                            handleModalToggle
                                                                        }
                                                                    >
                                                                        Cancelar
                                                                    </Button>
                                                                ),
                                                            }
                                                        )
                                                    }
                                                >
                                                    Editar
                                                </Button>
                                            </td>
                                            <td>
                                                <Button
                                                    color="danger"
                                                    onClick={() =>
                                                        handleDeleteClick(
                                                            element
                                                        )
                                                    }
                                                >
                                                    Eliminar
                                                </Button>
                                            </td>
                                            <td>
                                                {element.tipo_servicio.Texto}
                                            </td>
                                            <td>{element.ips.Texto}</td>
                                            <td>
                                                {element.tipo_admision.Texto}
                                            </td>
                                            <td>{element.FechaConsulta}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td
                                            style={{
                                                textAlign: "center",
                                                verticalAlign: "middle",
                                            }}
                                            colSpan="6"
                                        >
                                            {"No hay información en la tabla"}
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                        <ModalCreate
                            isOpen={modalOpen}
                            toggle={handleModalToggle}
                            title={modalContent.title}
                            content={modalContent.content}
                            footer={modalContent.footer}
                            formId={modalId.value}
                            token={token}
                        />
                        <ModalEdit
                            isOpen={modalEditOpen}
                            toggle={handleEditModalToggle}
                            title={modalEditcontent.title}
                            id={modalId.value}
                            serviceInitial={serviceId}
                            ipsInitial={firstIps}
                            admision={firstAdmision}
                            fechaInicial={fechafirstService}
                            footer={modalEditcontent.footer}
                            servicioMaestroId={servicioMaestroId}
                            servicioDetalleId={servicioDetalleId}
                            token={token}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default withTokenCheck(AppTypeCompanion);
