import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import { Person } from "@mui/icons-material";

const ModalEdit = ({
  isOpen,
  toggle,
  modalContent,
  footer,
  token,
  updateTable
}) => {

  const {
    title,
    id,
    Nombre1,
    Nombre2,
    Apellido1,
    Apellido2,
    TipoDocumentoId,
    NumeroIdent,
    FechaNacimiento,
    PacienteId,
    TipoAcompananteId
  } = modalContent;

  const [formData, setFormData] = useState({
    selectedPaciente: "",
    selectedTipoAcompanante: "",
    nombre1: "",
    nombre2: "",
    apellido1: "",
    apellido2: "",
    selectedTipoDocumento: "",
    numeroIdentificacion: "",
    fechaNacimiento: "",
  });

  const [pacienteOptions, setPacienteOptions] = useState([]);
  const [tipoAcompananteOptions, setTipoAcompananteOptions] = useState([]);
  const [tipoDocumentoOptions, setTipoDocumentoOptions] = useState([]);

  const modalRef = useRef(null);

  useEffect(() => {
    if (token) {
      axios
        .get("/tipoacompanante", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setTipoAcompananteOptions(
            response.data.map((option) => ({
              value: option.id,
              label: option.Texto || "",
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get("/tipodocumento", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setTipoDocumentoOptions(
            response.data.map((option) => ({
              value: option.id,
              label: option.Codigo + " - " + (option.Texto || ""),
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get("/paciente", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setPacienteOptions(
            response.data.map((option) => ({
              value: option.id,
              label:
                (option.NumeroIdent || "") +
                " " +
                (option.Nombre1 || "") +
                " " +
                (option.Apellido1 || ""),
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleEditSubmit = (event) => {
    event.preventDefault();
    axios
      .put(`/acompanante/${id}`, {
        TipoAcompananteId: parseInt(formData.selectedTipoAcompanante)
          ? parseInt(formData.selectedTipoAcompanante)
          : parseInt(TipoAcompananteId),
        PacienteId: parseInt(formData.selectedPaciente)
          ? parseInt(formData.selectedPaciente)
          : parseInt(PacienteId),
        Nombre1: formData.nombre1 ? formData.nombre1 : Nombre1,
        Nombre2: formData.nombre2 ? formData.nombre2 : Nombre2,
        Apellido1: formData.apellido1 ? formData.apellido1 : Apellido1,
        Apellido2: formData.apellido2 ? formData.apellido2 : Apellido2,
        TipoDocumentoId: parseInt(formData.selectedTipoDocumento)
          ? parseInt(formData.selectedTipoDocumento)
          : parseInt(TipoDocumentoId),
        NumeroIdent: parseInt(formData.numeroIdentificacion)
          ? parseInt(formData.numeroIdentificacion)
          : parseInt(NumeroIdent),
        FechaNacimiento: formData.fechaNacimiento
          ? formData.fechaNacimiento
          : FechaNacimiento,
      }, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Actualizado!",
          text: "el acompañante a sido actualizada con exito.",
          showCloseButton: true,
        }).then((result) => {
          updateTable();
          toggle();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "el acompañante no se pudo actualizar",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label htmlFor="PacienteId">Paciente relacionado:</label>
          <Select
            options={pacienteOptions}
            value={
              formData.selectedPaciente
                ? formData.selectedPaciente
                : pacienteOptions.find(
                  (option) => option.value === PacienteId
                )
            }
            onChange={(selectedOption) =>
              handleChange("selectedPaciente", selectedOption.value)
            }
          ></Select>
        </FormGroup>
        <FormGroup>
          <label htmlFor="TipoAcompananteId">Tipo de Acompañante:</label>
          <Select
            options={tipoAcompananteOptions}
            value={
              formData.selectedTipoAcompanante
                ? formData.selectedTipoAcompanante
                : tipoAcompananteOptions.find(
                  (option) => option.value === TipoAcompananteId
                )
            }
            onChange={(selectedOption) =>
              handleChange("selectedTipoAcompanante", selectedOption.value)
            }
          ></Select>
        </FormGroup>
        <FormGroup>
          <label>Primer Nombre:</label>
          <input
            className="form-control"
            name="nombre1"
            type="text"
            onChange={(event) =>
              handleChange("nombre1", event.target.value)
            }
            value={formData.nombre1 ? formData.nombre1 : Nombre1}
          />
        </FormGroup>
        <FormGroup>
          <label>Segundo Nombre:</label>
          <input
            className="form-control"
            name="nombre2"
            type="text"
            onChange={(event) =>
              handleChange("nombre2", event.target.value)
            }
            value={formData.nombre2 ? formData.nombre2 : Nombre2}
          />
        </FormGroup>
        <FormGroup>
          <label>Primer Apellido:</label>
          <input
            className="form-control"
            name="apellido1"
            type="text"
            onChange={(event) =>
              handleChange("apellido1", event.target.value)
            }
            value={formData.apellido1 ? formData.apellido1 : Apellido1}
          />
        </FormGroup>
        <FormGroup>
          <label>Segundo Apellido:</label>
          <input
            className="form-control"
            name="apellido2"
            type="text"
            onChange={(event) =>
              handleChange("apellido2", event.target.value)
            }
            value={formData.apellido2 ? formData.apellido2 : Apellido2}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="TipoDocumentoId">Tipo de Documento:</label>
          <Select
            options={tipoDocumentoOptions}
            value={
              formData.selectedTipoDocumento
                ? formData.selectedTipoDocumento
                : tipoDocumentoOptions.find(
                  (option) => option.value === TipoDocumentoId
                )
            }
            onChange={(selectedOption) =>
              handleChange("selectedTipoDocumento", selectedOption.value)
            }
          ></Select>
        </FormGroup>
        <FormGroup>
          <label>Numero de Documento:</label>
          <input
            className="form-control"
            name="numeroIdentificacion"
            type="text"
            onChange={(event) =>
              handleChange("numeroIdentificacion", event.target.value)
            }
            value={
              formData.numeroIdentificacion
                ? formData.numeroIdentificacion
                : NumeroIdent
            }
          />
        </FormGroup>
        <FormGroup>
          <label>Fecha de Nacimiento:</label>
          <input
            className="form-control"
            name="fechaNacimiento"
            type="date"
            onChange={(event) =>
              handleChange("fechaNacimiento", event.target.value)
            }
            value={
              formData.fechaNacimiento
                ? formData.fechaNacimiento
                : FechaNacimiento
            }
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleEditSubmit} color="primary" otype="submit">
          Editar
        </Button>
        <Button color="danger" onClick={toggle}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalEdit;