import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button, Modal } from "reactstrap";
import { Helmet } from "react-helmet";
import Spinner from "../../Spinner/spinner";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { localeEs } from "../../locale/ag-grid-es";
import ModalReasignacion from "./ModalReasignacion";
import BreadCrum from "../../breadCrum/breadCrum";
import withTokenCheck from "../../withTokenCheck";

const AppReasignacionHogar = ({ token }) => {
    const [infoHogarAsignado, setInfoHogarAsignado] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const gridApi = useRef(null);
    const etiquetas = [
        "Inicio Lossman",
        "Cambios De Evento",
        "Reasignación De Hogar",
    ];

    useEffect(() => {
        if (token) {
            axios
                .get("/asignacion/reporteHogar/reasignaciones", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setInfoHogarAsignado(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        editable: true,
    };

    const columnDefs = [
        {
            field: "id",
            headerName: "Reasignar Cama",
            cellRenderer: (params) => (
                <Button
                    color="secondary"
                    onClick={() => handleButtonClick(params.data)}
                >
                    Reasignar Hogar
                </Button>
            ),
        },
        {
            field: "NombreHogar",
            headerName: "Hogar Asignado",
        },
        {
            field: "NumeroCaso",
            headerName: "Numero de caso",
        },
        {
            field: "FechaInicial",
            headerName: "Fecha Inicial",
        },
        {
            field: "FechaFinal",
            headerName: "Fecha Final",
        },
        {
            field: "NombreCompleto",
            headerName: "Nombre",
        },
        {
            field: "TipoDocumento",
            headerName: "Tipo de documento",
        },
        {
            field: "Documento",
            headerName: "Numero Documento",
        },
        {
            field: "created_by.name",
            headerName: "Usuario creación",
        },
        {
            field: "created_by.created_at",
            headerName: "Fecha creación",
        },
        {
            field: "updated_by.name",
            headerName: "Usuario Actualización",
        },
        {
            field: "updated_by.updated_at",
            headerName: "Fecha Actualización",
        },
    ];

    const handleModalToggle = () => setModalOpen(!modalOpen);

    const handleButtonClick = (content) => {
        setModalContent(content);
        handleModalToggle();
    };

    const handleExportCSV = () => {
        gridApi.current.exportDataAsCsv();
    };

    const onGridReady = (params) => {
        gridApi.current = params.api;
    };

    const handleUpdateTable = () => {
        setLoading(true);
        if (token) {
            axios
                .get("/asignacion/reporteHogar/reasignaciones", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setInfoHogarAsignado(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    return (
        <>
            <Helmet>
                <title>Reasginación Hogar</title>
            </Helmet>
            <div style={{ position: "relative" }}>
                {loading && <Spinner />}
                <div>
                    <BreadCrum etiquetas={etiquetas} />
                </div>
                <div style={{ opacity: loading ? 0.5 : 1 }}>
                    <div className="container-fluid mt-5">
                        <Button color="success" onClick={handleExportCSV}>
                            Exportar CSV (Excel)
                        </Button>
                        <div
                            className="mt-3"
                            style={{ height: "500px", width: "100%" }}
                        >
                            <div
                                className="ag-theme-alpine"
                                style={{ height: "100%", width: "100%" }}
                            >
                                <AgGridReact
                                    rowData={infoHogarAsignado}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    pagination={true}
                                    onGridReady={onGridReady}
                                    localeText={localeEs}
                                    paginationPageSize={20}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalReasignacion
                isOpen={modalOpen}
                toggle={handleModalToggle}
                infoModal={modalContent}
                footer={modalContent.footer}
                token={token}
                updateTable={handleUpdateTable}
            />
        </>
    );
};

export default withTokenCheck(AppReasignacionHogar);
