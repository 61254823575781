import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import * as jzip from "jszip";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, FormGroup } from "reactstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import withTokenCheck from "../withTokenCheck";

window.JSZip = jzip;

const AppReport = ({ token }) => {
    const [form, setForm] = useState([]);
    const [numberCase, setNumberCase] = useState([]);
    const [selectedCase, setSelectedCase] = useState("");
    const [selectedCase2, setSelectedCase2] = useState("");
    const navigate = useNavigate();
    const tableRef = useRef(null);

    useEffect(() => {
        if (token) {
            axios
                .get("/edicion-formulario", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setNumberCase(response.data);
                    /*  setLoading(false); */
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const numberCaseoptions = numberCase.map((option) => ({
        value: option.id,
        label: option.NumeroCaso ?? option.NumeroCaso,
    }));

    const handleNumberCaseChange = (selectedOption) => {
        setSelectedCase2(selectedOption.value);
        setSelectedCase(
            numberCaseoptions.find(
                (option) => option.value === selectedOption.value
            )
        );
    };

    const handleSelectCase = () => {
        if (token) {
            axios
                .get(`/formulario/consulta/lista/${selectedCase2}`, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setForm(response.data);

                    // Inicializa Datatables en la tabla
                    const dataTable = $("#myTable").DataTable();
                    dataTable.clear().draw();

                    // Agrega las filas a la tabla
                    response.data.forEach((row) => {
                        dataTable.row.add([
                            `<button class="edit-button btn btn-warning" data-id="${row.id}">Editar</button>`,
                            row.paciente.Nombre1 +
                                " " +
                                (row.paciente.Nombre2
                                    ? row.paciente.Nombre2
                                    : " ") +
                                " " +
                                row.paciente.Apellido1 +
                                " " +
                                (row.paciente.Apellido2
                                    ? row.paciente.Apellido2
                                    : " "),
                            row.paciente.tipo_documento.Texto,
                            row.paciente.NumeroIdent,
                            row.NumeroCaso,
                        ]);
                    });

                    dataTable.draw();

                    // Agrega el controlador de eventos delegado para los botones de edición
                    $("#myTable").on("click", ".edit-button", function () {
                        const id = $(this).data("id");
                        editRow(id);
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    function editRow(id) {
        // Navega a la ruta del componente de formulario con el ID del elemento como parámetro
        navigate(`/FormEdition`, { state: { id } });
    }

    useEffect(() => {
        $(document).ready(() => {
            $(tableRef.current).DataTable({
                paging: true,
                ordering: true,
                info: true,
                dom: "<'row'<'col-sm-4'l><'col-sm-4 text-center'B><'col-sm-4'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-6 text-right'i><'col-sm-6'p>>",
                language: {
                    url: "//cdn.datatables.net/plug-ins/1.13.1/i18n/es-ES.json",
                },
                buttons: {
                    dom: {
                        button: {
                            tag: "button",
                            className: "btn",
                        },
                    },
                    buttons: [
                        {
                            extend: "excel",
                            className: "btn-success",
                            text: "<i class='far fa-file-excel'></i> Excel",
                            extension: ".xlsx",
                        },
                    ],
                },
                /* buttons: ["copy", "csv", "excel", "pdf", "print"], */
            });
        });
    }, []);

    return (
        <>
            <div style={{ maxWidth: "1200px", margin: "50px auto" }}>
                <div style={{ width: "100%", overflowX: "auto" }}>
                    <label htmlFor="servicioId">
                        Seleccione numero de caso
                    </label>
                    <FormGroup id="FormEdit">
                        <Select
                            id="SelectEdit"
                            options={numberCaseoptions}
                            value={selectedCase}
                            onChange={handleNumberCaseChange}
                        ></Select>
                        <Button onClick={handleSelectCase}>Consultar</Button>
                    </FormGroup>
                    <table
                        ref={tableRef}
                        id="myTable"
                        className="table"
                        style={{ width: "100%" }}
                    >
                        <thead>
                            <tr>
                                <th>Edición del formulario</th>
                                <th>Nombre Paciente</th>
                                <th>Tipo de Documento</th>
                                <th>Numero de Documento</th>
                                <th>Numero de Caso</th>
                            </tr>
                        </thead>
                        <tbody>{}</tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default withTokenCheck(AppReport);
