import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";

const ModalEdit = ({ isOpen, toggle, title, id, textoInicial, token }) => {
  const [tipoadmision, setTipoAdmision] = useState("");
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);

  useEffect(() => {
    setTipoAdmision(textoInicial);
  }, [textoInicial]);

  const handleTipoAdmisionChange = (event) => {
    setTipoAdmision(event.target.value);
  };

  const handleEditSubmit = (event) => {
    event.preventDefault();
    axios
      .put(`/tipoadmision/${id}`, {
        Texto: tipoadmision,
      }, {headers: { Authorization: `Bearer ${token}` }})
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Actualizado!",
          text: "el tipo servicio a sido actualizada con exito.",
          showCloseButton: true,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "el tipo servicio no se pudo actualizar",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label>Tipo Admisión:</label>
          <input
            className="form-control"
            name="nombre"
            type="text"
            value={tipoadmision}
            onChange={handleTipoAdmisionChange}
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleEditSubmit} color="primary" otype="submit">
          Guardar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalEdit;
