import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button } from "reactstrap";
import { Helmet } from "react-helmet";
import Spinner from "../../Spinner/spinner";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { localeEs } from "../../locale/ag-grid-es";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { TextField } from "@material-ui/core";
import BreadCrum from "../../breadCrum/breadCrum";
import withTokenCheck from "../../withTokenCheck";

const AppReportBed = ({ token }) => {
    const [reportBeds, setReportBeds] = useState([]);
    const [totales, setTotales] = useState([]);
    const [totalCamas, setTotalCamas] = useState("");
    const [disponibilidadHogar, setDisponibilidadHogar] = useState([]);
    const [totalCamasOcupadas, setTotalCamasOcupadas] = useState("");
    const [loading, setLoading] = useState(true);
    const gridApi = useRef(null);
    const etiquetas = [
        "Inicio Lossman",
        "Reportes",
        "Reporte Disponibilidad De Camas ",
    ];

    useEffect(() => {
        if (token) {
            setLoading(true);
            axios
                .get("/asignacion/reporte/habilitados", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setReportBeds(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            axios
                .get("/asignacion/reporte/totales", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setTotales(response.data);
                    const disponibilidadArray = Object.values(
                        response.data.DisponibilidadPorHogar
                    );
                    setDisponibilidadHogar(disponibilidadArray);
                    setTotalCamas(response.data.Disponibles);
                    setTotalCamasOcupadas(response.data.NoDisponibles);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        editable: true,
    };

    const columnDefs = [
        {
            headerName: "Hogar",
            field: "hogar.Nombre",
            flex: 1,
        },
        { headerName: "Cama", field: "NumeroHabitacion", flex: 1 },
        { headerName: "Estado", field: "estado", flex: 1 },
    ];

    const handleExportCSV = () => {
        gridApi.current.exportDataAsCsv();
    };

    const onGridReady = (params) => {
        gridApi.current = params.api;
    };

    return (
        <>
            <Helmet>
                <title>Reporte de camas</title>
            </Helmet>
            <div style={{ position: "relative" }}>
                {loading && <Spinner />}
                <div>
                    <BreadCrum etiquetas={etiquetas} />
                </div>
                <div style={{ opacity: loading ? 0.5 : 1 }}>
                    <div className="container-fluid mt-5">
                        <Button color="success" onClick={handleExportCSV}>
                            Exportar CSV (Excel)
                        </Button>
                        <div
                            className="mt-3"
                            style={{ height: "500px", width: "100%" }}
                        >
                            <div
                                className="ag-theme-alpine"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                }}
                            >
                                <AgGridReact
                                    rowData={reportBeds}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    pagination={true}
                                    onGridReady={onGridReady}
                                    localeText={localeEs}
                                    paginationPageSize={20}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                        {disponibilidadHogar.map((element) => (
                            <div key={element.HogarId}>
                                <Divider className="mb-4">
                                    <Chip
                                        label={
                                            "total disponibilidad " +
                                            element.NombreHogar
                                        }
                                    />
                                </Divider>
                                <div className="input-group input-group-sm  d-flex justify-content-center align-items-center">
                                    <TextField
                                        disabled
                                        label="Numero de camas disponibles"
                                        className="form-control "
                                        type="Text"
                                        name="camasDisponibles"
                                        value={element.Disponibles}
                                    />
                                    <TextField
                                        disabled
                                        label="Numero de camas ocupadas"
                                        className="form-control "
                                        type="Text"
                                        name="camasOcupadas"
                                        value={element.Ocupadas}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default withTokenCheck(AppReportBed);
