import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@material-ui/core";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Spinner from "../../Spinner/spinner";

const ModalCreate = ({
    isOpen,
    toggle,
    title,
    token,
    updatedTable,
    content,
    setModalOpen,
    modalOpen,
}) => {
    const [fechaServicio, setFechaServicio] = useState("");
    const [hogarTraslado, setHogarTraslado] = useState([]);
    const [numeroCaso, setNumeroCaso] = useState([]);
    const [rutaTraslado, setRutraTraslado] = useState("");
    const [horaCita, setHoraCita] = useState("");
    const [horaSalida, setHoraSalida] = useState("");
    const [tipoServicioHogar, setTipoServicioHogar] = useState([]);
    const [selectedServicioHogar, setSelectedServicioHogar] = useState("");
    const [selectedServicioHogar2, setSelectedServicioHogar2] = useState("");
    const [selectedHogarTraslado, setSelectedHogarTraslado] = useState("");
    const [selectedHogarTraslado2, setSelectedHogarTraslado2] = useState("");
    const [selectedNumeroCaso, setSelectedNumeroCaso] = useState("");
    const [selectedNumeroCaso2, setSelectedNumeroCaso2] = useState("");
    const [horaLlegada, setHoraLlegada] = useState("");
    const [observaciones, setObservaciones] = useState("");
    const [mostrarServicios, setMostrarServicios] = useState(false);
    const [selectedServicio, setSelectedServicio] = useState("");
    const [selectedServicio2, setSelectedServicio2] = useState("");
    const [servicios, setServicios] = useState([]);
    const [hogarInicial, setHogarInicial] = useState("");
    const [formMode, setFormMode] = useState("none");
    const [nombrePaciente, setNombrePaciente] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [acompanantesMax, setAcompanantesMax] = useState("");
    const [numeroAcompanantes, setNumeroAcompanantes] = useState("");
    const modalRef = useRef(null);

    const handleTimeChange = (newTime) => {
        const timeZone = "America/Bogota";
        const horas = newTime.getHours();
        const minutos = newTime.getMinutes();
        const segundos = newTime.getSeconds();
        const formattedTime = `${horas.toString().padStart(2, "0")}:${minutos
            .toString()
            .padStart(2, "0")}:${segundos.toString().padStart(2, "0")}`;
        setHoraCita(formattedTime);
    };
    const handleTimeEndChange = (newTime) => {
        const timeZone = "America/Bogota";
        const horas = newTime.getHours();
        const minutos = newTime.getMinutes();
        const segundos = newTime.getSeconds();
        const formattedTime = `${horas.toString().padStart(2, "0")}:${minutos
            .toString()
            .padStart(2, "0")}:${segundos.toString().padStart(2, "0")}`;
        setHoraSalida(formattedTime);
    };

    useEffect(() => {
        if (token) {
            axios
                .get("/programacionformulario/case-number", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setNumeroCaso(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            axios
                .get("/hogar", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setHogarTraslado(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (token && selectedNumeroCaso2) {
            axios
                .get(`/formulario/consulta/servicios/${selectedNumeroCaso2}`, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    const servicioDetalle =
                        response.data ?? response.data.servicio_maestro;
                    setServicios(servicioDetalle);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token, selectedNumeroCaso2]);

    useEffect(() => {
        if (token && selectedNumeroCaso2) {
            axios
                .get(`/formulario/consulta/hogar/${selectedNumeroCaso2}`, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setHogarInicial(response.data);
                    setNombrePaciente(response.data.paciente.Nombre);
                    setAcompanantesMax(response.data.cantidad_acompanantes);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token, selectedNumeroCaso2]);

    useEffect(() => {
        if (token) {
            axios
                .get("/tiposerviciohogar", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setTipoServicioHogar(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const numeroCasoOptions = numeroCaso.map((option) => ({
        value: option.Id,
        label: option.NumeroCaso ?? option.NumeroCaso,
    }));

    const hogarTrasladoOptions = hogarTraslado.map((option) => ({
        value: option.id,
        label: option.Nombre ?? option.Nombre,
    }));

    const tipoServicioHogarOptions = tipoServicioHogar.map((option) => ({
        value: option.id,
        label: option.Texto ?? option.Texto,
    }));

    const servicioDiscriminado = servicios.flatMap((option) => {
        if (option.servicio_maestro && option.servicio_maestro.FormularioId) {
            return option.servicio_maestro.servicio_detalle.map((detalle) => ({
                value: detalle.id,
                label: detalle.tipo_servicio.Texto,
            }));
        } else {
            return [
                {
                    value: "N.A",
                    label: "N.A",
                },
            ];
        }
    });

    const handleServicioChange = (selectedOption) => {
        setSelectedServicio2(selectedOption.value);
        setSelectedServicio(selectedOption);
    };

    const handleNumeroCasoChange = (selectedOption) => {
        setSelectedNumeroCaso2(selectedOption.value);
        setSelectedNumeroCaso(selectedOption);
    };

    const handleTipoServicioHogarChange = (selectedOption) => {
        setSelectedServicioHogar2(selectedOption.value);
        setSelectedServicioHogar(selectedOption);
    };

    const handleHogarTrasladoChange = (selectedOption) => {
        setSelectedHogarTraslado2(selectedOption.value);
        setSelectedHogarTraslado(selectedOption);
    };

    const handleFechaSolicitudChange = (event) => {
        setFechaServicio(event.target.value);
    };

    const handleRutaTrasladoChange = (event) => {
        setRutraTraslado(event.target.value);
    };
    const handleNumeroAcompanantes = (event) => {
        if (event.target.value <= acompanantesMax) {
            setNumeroAcompanantes(event.target.value);
        } else {
            Swal.fire({
                icon: "error",
                title: "Error!",
                text:
                    "El numero de acompañantes debe ser menor o igual al numero de acompañantes registrados " +
                    acompanantesMax,
                showCloseButton: true,
            });
        }
    };
    const handleObservacionesChange = (event) => {
        setObservaciones(event.target.value);
    };

    const handleModalToggle2 = () => {
        setModalOpen(!modalOpen);
        setSelectedNumeroCaso();
        setNombrePaciente();
        setHogarInicial("");
        setSelectedServicioHogar();
        setFechaServicio();
        setHoraCita();
        setHoraSalida();
        setObservaciones();
        setRutraTraslado();
    };

    const handleCreateSubmit = (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        axios
            .post(
                "/programacionformularios",
                {
                    FechaServicio: fechaServicio,
                    HogarId: selectedHogarTraslado2,
                    FormularioId: selectedNumeroCaso2,
                    TipoServicioHogarId: selectedServicioHogar2,
                    ServicioDetalleId: selectedServicio2
                        ? selectedServicio2
                        : " ",
                    RutaTraslado: rutaTraslado,
                    HoraCita: horaCita,
                    HoraSalida: horaSalida,
                    Observacion: observaciones,
                    CantidadAcompanantes: numeroAcompanantes,
                },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: "Creado!",
                    text: "La información de traslado a sido creada con exito",
                    showCloseButton: true,
                }).then(() => {
                    updatedTable();
                    handleModalToggle2();
                    setIsSubmitting(false);
                });
            })
            .catch((error) => {
                setIsSubmitting(false);
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "Ha ocurrido un error al guardar la información de traslado.",
                    showCloseButton: true,
                });
            });
    };

    useEffect(() => {
        if (hogarInicial) {
            if (
                hogarInicial.condiciones_especiales.some(
                    (element) => element.Texto === "Discap. con Rampa"
                ) &&
                observaciones === ""
            ) {
                setObservaciones("Se necesita carro con Rampa");
            }
        }
    });

    return (
        <Modal ref={modalRef} isOpen={isOpen} toggle={handleModalToggle2}>
            <ModalHeader toggle={handleModalToggle2}>{title}</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <label htmlFor="NumeroCaso">Numero de Caso:</label>
                    <Select
                        options={numeroCasoOptions}
                        value={
                            selectedNumeroCaso
                                ? selectedNumeroCaso
                                : numeroCasoOptions.find(
                                      (option) => option.value === numeroCaso.id
                                  )
                        }
                        onChange={handleNumeroCasoChange}
                    ></Select>
                </FormGroup>
                <FormGroup>
                    <label>Paciente:</label>
                    <input
                        className="form-control"
                        name="apellido2"
                        type="text"
                        value={nombrePaciente}
                    />
                </FormGroup>
                <FormGroup>
                    <label htmlFor="ServicioHogar">Hogar:</label>
                    <Select
                        isDisabled
                        options={hogarTrasladoOptions}
                        value={
                            selectedHogarTraslado
                                ? selectedHogarTraslado
                                : hogarTrasladoOptions.find(
                                      (option) =>
                                          option.value === hogarInicial.HogarId
                                  )
                        }
                        onChange={handleHogarTrasladoChange}
                    ></Select>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="ServicioHogar">Servicio del hogar:</label>
                    <Select
                        options={tipoServicioHogarOptions}
                        value={
                            selectedServicioHogar
                                ? selectedServicioHogar
                                : tipoServicioHogarOptions.find(
                                      (option) =>
                                          option.value === tipoServicioHogar.id
                                  )
                        }
                        onChange={handleTipoServicioHogarChange}
                    ></Select>
                </FormGroup>
                <FormGroup>
                    <label>
                        ¿Desea añadir información sobre el servicio a la ruta de
                        traslado? :
                    </label>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        onChange={(e) =>
                            setMostrarServicios(e.target.value === "Si")
                        }
                    >
                        <FormControlLabel
                            value="Si"
                            control={<Radio />}
                            label="SI"
                        />
                        <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </FormGroup>
                {mostrarServicios && (
                    <FormGroup>
                        <label htmlFor="ServicioHogar">Cita Medica:</label>
                        <Select
                            options={servicioDiscriminado}
                            value={
                                selectedServicio
                                    ? selectedServicio
                                    : servicioDiscriminado.find(
                                          (option) =>
                                              option.value === servicios.id
                                      )
                            }
                            onChange={handleServicioChange}
                        ></Select>
                    </FormGroup>
                )}
                <FormGroup>
                    <label>Fecha de Servicio</label>
                    <TextField
                        className="form-control"
                        type="date"
                        name="FechaSolicitud"
                        value={fechaServicio}
                        onChange={handleFechaSolicitudChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <label>Ruta de traslado:</label>
                    <input
                        className="form-control"
                        name="rutaTraslado"
                        type="text"
                        value={rutaTraslado}
                        onChange={handleRutaTrasladoChange}
                    />
                </FormGroup>
                <FormGroup>
                    <label>Numero de Acompañantes en el traslado:</label>
                    <input
                        className="form-control"
                        name="rutaTraslado"
                        type="number"
                        value={numeroAcompanantes}
                        onChange={handleNumeroAcompanantes}
                    />
                </FormGroup>
                <FormGroup>
                    <label>Hora de la cita:</label>
                    <br />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                            value={horaCita}
                            onChange={handleTimeChange}
                        />
                    </LocalizationProvider>
                </FormGroup>
                <FormGroup>
                    <label>Hora de salida:</label>
                    <br />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                            value={horaSalida}
                            onChange={handleTimeEndChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </FormGroup>
                <FormGroup>
                    <label>observaciones:</label>
                    <input
                        className="form-control"
                        name="observaciones"
                        type="text"
                        value={observaciones}
                        onChange={handleObservacionesChange}
                    />
                </FormGroup>
            </ModalBody>

            <ModalFooter>
                <Button
                    onClick={handleCreateSubmit}
                    color="primary"
                    otype="submit"
                    disabled={isSubmitting}
                >
                    {isSubmitting ? "Enviando..." : "Crear"}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalCreate;
