import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import ModalCreate from "./ModalCreate";
import ModalEdit from "./ModalEdit";
import { Button } from "reactstrap";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import withTokenCheck from "../../withTokenCheck";

const AppPaciente = ({ token }) => {
    const [paciente, setPaciente] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [modalEditcontent, setModalEditContent] = useState({});
    const [modalTittleContent, setModalTittleContent] = useState({});
    const [habilitar] = useState("");
    const [loading, setLoading] = useState(true);
    const tableRef = useRef(null);

    useEffect(() => {
        if (token) {
            axios
                .get("/paciente", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setPaciente(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const handleModalToggle = () => setModalOpen(!modalOpen);
    const handleEditModalToggle = () => setModalEditOpen(!modalEditOpen);

    const handleButtonClick = (content) => {
        setModalContent(content);
        handleModalToggle();
    };

    const handleEditClick = (element, content) => {
        setModalEditContent(element);
        setModalTittleContent(content);
        handleEditModalToggle();
    };

    const handleDeleteClick = (element) => {
        Swal.fire({
            title: "¿Estás seguro de bloquear este paciente?",
            input: "text",
            inputLabel: "Motivo del bloqueo",
            showCancelButton: true,
            confirmButtonText: "Bloquear",
            cancelButtonText: "Cancelar",
            inputValidator: (value) => {
                // Validar que se haya ingresado un motivo
                if (!value) {
                    return "Por favor, ingresa el motivo del bloqueo.";
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const motivo = result.value;
                axios
                    .post(
                        `/paciente/deshabilitar/${element.id}`,
                        {
                            Motivo: motivo,
                            Habilitado: false,
                        },
                        { headers: { Authorization: `Bearer ${token}` } }
                    )
                    .then((response) => {
                        Swal.fire({
                            icon: "success",
                            title: "Actualizado!",
                            text: "el paciente ha sido bloqueado con exito.",
                            showCloseButton: true,
                        }).then(() => {
                            window.location.reload();
                        });
                    })
                    .catch((error) => {
                        Swal.fire({
                            icon: "error",
                            title: "Error!",
                            text: "el paciente no se pudo bloquear",
                            showCloseButton: true,
                        });
                    });
            }
        });
    };

    const handleHabilitarChange = (element) => {
        Swal.fire({
            title: "¿Estás seguro de Habilitar este paciente?",
            showCancelButton: true,
            confirmButtonText: "Habilitar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .post(
                        `/paciente/deshabilitar/${element.id}`,
                        {
                            Habilitado: true,
                            Motivo: "",
                        },
                        { headers: { Authorization: `Bearer ${token}` } }
                    )
                    .then((response) => {
                        Swal.fire({
                            icon: "success",
                            title: "Actualizado!",
                            text: "el paciente a sido Habilitado con exito.",
                            showCloseButton: true,
                        }).then(() => {
                            window.location.reload();
                        });
                    })
                    .catch((error) => {
                        Swal.fire({
                            icon: "error",
                            title: "Error!",
                            text: "el paciente no fue Habilitado",
                            showCloseButton: true,
                        });
                    });
            }
        });
    };

    useEffect(() => {
        let dataTable;
        if (!loading) {
            $(tableRef.current).DataTable({
                paging: true,
                ordering: true,
                info: true,
                dom: "<'row'<'col-sm-2'l><'col-sm-6'B><'col-sm-4'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-6 text-right'i><'col-sm-6'p>>",
                language: {
                    url: "//cdn.datatables.net/plug-ins/1.13.1/i18n/es-ES.json",
                },
                buttons: {
                    dom: {
                        button: {
                            tag: "button",
                            className: "btn",
                        },
                    },
                    buttons: [
                        {
                            extend: "excel",
                            className: "btn-success",
                            text: "<i class='far fa-file-excel'></i> Excel",
                            extension: ".xlsx",
                        },
                    ],
                },
            });
        }
        return () => {
            if (dataTable) {
                dataTable.destroy();
            }
        };
    }, [loading]);

    return (
        <>
            <Helmet>
                <title>Paciente</title>
            </Helmet>
            {loading ? (
                <div>Cargando...</div>
            ) : (
                <div style={{ maxWidth: "1200px", margin: "50px auto" }}>
                    <div style={{ width: "100%", overflowX: "auto" }}>
                        <td>
                            <Button
                                color="primary"
                                onClick={() =>
                                    handleButtonClick({
                                        title: "Crear Paciente",
                                        footer: (
                                            <Button
                                                className="mb-3"
                                                color="secondary"
                                                onClick={handleModalToggle}
                                            >
                                                Cancelar
                                            </Button>
                                        ),
                                    })
                                }
                            >
                                Crear Paciente
                            </Button>
                        </td>
                        <div style={{ height: "20px" }}></div>
                        <table
                            ref={tableRef}
                            id="myTable"
                            className="table"
                            style={{ width: "100%" }}
                        >
                            <thead>
                                <tr>
                                    <th>Editar</th>
                                    <th>Deshabilitar</th>
                                    <th>Habilitar</th>
                                    <th>Estado</th>
                                    <th>Motivo suspensión</th>
                                    <th>Primer Nombre</th>
                                    <th>Segundo Nombre</th>
                                    <th>Primer Apellido</th>
                                    <th>Segundo Apellido</th>
                                    <th>Tipo Documento</th>
                                    <th>Numero de Identificación</th>
                                    <th>Fecha Nacimiento</th>
                                    <th>Numero de Contacto</th>
                                    <th>Ciudad de Origen</th>
                                    <th>Dirección residencia</th>
                                    <th>Teléfono fijo</th>
                                    <th>Contacto de emergencia</th>
                                    <th>Teléfono contacto de emergencia</th>
                                    <th>usuario creación</th>
                                    <th>Fecha creación</th>
                                    <th>Usuario edición</th>
                                    <th>Fecha de edición</th>
                                    <th>Usuario que deshabilito</th>
                                    <th>Fecha Deshabilitación</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paciente.map((element) => (
                                    <tr
                                        key={element.id}
                                        style={{
                                            backgroundColor:
                                                element.Habilitado != 1
                                                    ? "#FFCCCC"
                                                    : "",
                                        }}
                                    >
                                        <td>
                                            <Button
                                                className="mb-3"
                                                color="secondary"
                                                onClick={() =>
                                                    handleEditClick(element, {
                                                        title: "Actualizar Paciente",
                                                        footer: (
                                                            <Button
                                                                className="mb-3"
                                                                color="secondary"
                                                                onClick={
                                                                    handleModalToggle
                                                                }
                                                            >
                                                                Cancelar
                                                            </Button>
                                                        ),
                                                    })
                                                }
                                            >
                                                Editar
                                            </Button>
                                        </td>
                                        <td>
                                            <Button
                                                className="mb-3"
                                                color="danger"
                                                onClick={() =>
                                                    handleDeleteClick(element, {
                                                        title: "deshabilitar Paciente",
                                                        footer: (
                                                            <Button
                                                                className="mb-3"
                                                                color="secondary"
                                                                onClick={
                                                                    handleModalToggle
                                                                }
                                                            >
                                                                Cancelar
                                                            </Button>
                                                        ),
                                                    })
                                                }
                                            >
                                                Deshabilitar paciente
                                            </Button>
                                        </td>
                                        <td>
                                            <Button
                                                className="mb-3"
                                                color="success"
                                                onClick={() =>
                                                    handleHabilitarChange(
                                                        element,
                                                        {
                                                            title: "Habilitar Paciente",
                                                            footer: (
                                                                <Button
                                                                    className="mb-3"
                                                                    color="secondary"
                                                                    onClick={
                                                                        handleModalToggle
                                                                    }
                                                                >
                                                                    Cancelar
                                                                </Button>
                                                            ),
                                                        }
                                                    )
                                                }
                                            >
                                                Habilitar paciente
                                            </Button>
                                        </td>
                                        <td>
                                            {element.Habilitado == 1
                                                ? "Habilitado"
                                                : "Deshabilitado"}
                                        </td>
                                        <td>
                                            {element.MotivoDeshabilitacion
                                                ? element.MotivoDeshabilitacion
                                                : "N.A"}
                                        </td>
                                        <td>{element.Nombre1}</td>
                                        <td>{element.Nombre2}</td>
                                        <td>{element.Apellido1}</td>
                                        <td>{element.Apellido2}</td>
                                        <td>{element.tipo_documento.Texto}</td>
                                        <td>{element.NumeroIdent}</td>
                                        <td>{element.FechaNacimiento}</td>
                                        <td>{element.TelContacto}</td>
                                        <td>{element.ciudad.Texto}</td>
                                        <td>{element.DireccionResidencia}</td>
                                        <td>{element.TelFijo}</td>
                                        <td>{element.ContEmergencia}</td>
                                        <td>{element.TelEmergencia}</td>
                                        <td>
                                            {element.created_at
                                                ? element.created_at
                                                : "Información no disponible"}
                                        </td>
                                        <td>
                                            {element.created_by
                                                ? element.created_by.id
                                                    ? element.created_by.name
                                                    : "no existe información"
                                                : "no existe información"}
                                        </td>
                                        <td>
                                            {element.updated_by
                                                ? element.updated_by.id
                                                    ? element.updated_by.name
                                                    : "no existe información"
                                                : "no existe información"}
                                        </td>
                                        <td>
                                            {element.updated_at
                                                ? element.updated_at
                                                : "Información no disponible"}
                                        </td>
                                        <td>
                                            {element.usuario_deshabilitacion
                                                ? element
                                                      .usuario_deshabilitacion
                                                      .id
                                                    ? element
                                                          .usuario_deshabilitacion
                                                          .name
                                                    : "no existe información"
                                                : "no existe información"}
                                        </td>
                                        <td>
                                            {element.FechaDeshabilitacion
                                                ? element.FechaDeshabilitacion
                                                : "Información no disponible"}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <ModalCreate
                            isOpen={modalOpen}
                            toggle={handleModalToggle}
                            title={modalContent.title}
                            content={modalContent.content}
                            footer={modalContent.footer}
                            token={token}
                        />
                        <ModalEdit
                            isOpen={modalEditOpen}
                            toggle={handleEditModalToggle}
                            title={modalTittleContent.title}
                            id={modalEditcontent.id}
                            Nombre1Inicial={modalEditcontent.Nombre1}
                            Nombre2Inicial={modalEditcontent.Nombre2}
                            Apellido1Inicial={modalEditcontent.Apellido1}
                            Apellido2Inicial={modalEditcontent.Apellido2}
                            TipoDocumentoId={modalEditcontent.TipoDocumentoId}
                            NumeroIdentInicial={modalEditcontent.NumeroIdent}
                            FechaNacimientoInicial={
                                modalEditcontent.FechaNacimiento
                            }
                            TelInicial={modalEditcontent.TelContacto}
                            CiudadId={modalEditcontent.CiudadOrigenId}
                            DireccionResidencia={
                                modalEditcontent.DireccionResidencia
                            }
                            TelFijo={modalEditcontent.TelFijo}
                            ContactoEmergencia={modalEditcontent.ContEmergencia}
                            TelEmergencia={modalEditcontent.TelEmergencia}
                            footer={modalEditcontent.footer}
                            token={token}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default withTokenCheck(AppPaciente);
