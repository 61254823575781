import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";

const ModalEdit = ({
  isOpen,
  toggle,
  title,
  formId,
  pacienteId,
  acompananteInicialId,
  token,
}) => {
  const [acompanantes, setAcompanantes] = useState([]);
  const [acompanantesOptions, setAcompanantesOptions] = useState([]);
  const [selectedAcompanante, setSelectedAcompanante] = useState("");
  const [selectedAcompanante2, setSelectedAcompanante2] = useState("");
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);

  useEffect(() => {
    if (token) {
      axios
        .get(`/acompanante/paciente/${pacienteId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setAcompanantes(response.data);
          generateOptions(response.data);
        })
        .catch((error) => {
          console.log(error);
        });

      const generateOptions = (data) => {
        if (data.length > 0) {
          const options = data.map((option) => ({
            value: option.id,
            label:
              option.Nombre1 + " " +
              (option.Nombre2 ? option.Nombre2 : " ") +
              " " +
              option.Apellido1 + " " +
              (option.Apellido2 ? option.Apellido2 : "" ) +
              " " +
              (option.NumeroIdent ? option.NumeroIdent : " ") 
          }));
          setAcompanantesOptions(options);
        }
      };
    }
  }, [pacienteId, token]);

  const handleAcompananteChange = (selectedOption) => {
    setSelectedAcompanante2(selectedOption.value);
    setSelectedAcompanante(
      acompanantesOptions.find(
        (option) => option.value === selectedOption.value
      )
    );
  };

  const handleEditSubmit = (event) => {
    event.preventDefault();
    axios
      .put(`/edicion-formulario/acompanantes/${pacienteId}`, {
        FormularioId: formId,
        AcompananteId: selectedAcompanante2
          ? selectedAcompanante2
          : acompananteInicialId,
        AcompananteInicialId: acompananteInicialId,
      }, {headers: { Authorization: `Bearer ${token}` }})
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Actualizado!",
          text: "el tipo acompañante ha sido actualizada con exito.",
          showCloseButton: true,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "El tipo de acompañante no se pudo actualizar",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label htmlFor="servicioId">Acompañante</label>
          <Select
            value={
              selectedAcompanante
                ? selectedAcompanante
                : acompanantesOptions.find(
                    (option) => option.value === acompananteInicialId
                  )
            }
            onChange={handleAcompananteChange}
            options={acompanantesOptions}
          ></Select>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleEditSubmit} color="primary" otype="submit">
          Guardar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalEdit;
