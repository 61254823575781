import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";

const ModalEdit = ({ isOpen, toggle, title, id, textoInicial, token, 
  DireccionInicial, }) => {
  const [texto, setTexto] = useState("");
  const [direccion, setDireccion] = useState("");
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);
  useEffect(() => {
    setTexto(textoInicial);
    setDireccion(DireccionInicial)
  }, [textoInicial, DireccionInicial]);

  const handleTextoChange = (event) => {
    setTexto(event.target.value);
  };

  const handleDireccionChange = (event) => {
    setDireccion(event.target.value);
  };

  const handleEditSubmit = (event) => {
    event.preventDefault();
    axios
      .put(`/ips/${id}`, {
        Texto: texto,
        Direccion: direccion ? direccion : DireccionInicial,
      }, {headers: { Authorization: `Bearer ${token}` }})
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Actualizado!",
          text: "La IPS a sido actualizada con exito.",
          showCloseButton: true,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "La IPS no se pudo actualizar",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label>IPS:</label>
          <input
            className="form-control"
            name="nombre"
            type="text"
            value={texto}
            onChange={handleTextoChange}
          />
        </FormGroup>
        <FormGroup>
          <label>Dirección:</label>
          <input
            className="form-control"
            name="dirección"
            type="text"
            value={direccion}
            onChange={handleDireccionChange}
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleEditSubmit} color="primary" otype="submit">
          Editar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalEdit;
