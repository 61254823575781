import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button, Modal } from "reactstrap";
import { Helmet } from "react-helmet";
import Spinner from "../../Spinner/spinner";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { localeEs } from "../../locale/ag-grid-es";
import withTokenCheck from "../../withTokenCheck";

const AppReasignacionHogar = ({ token }) => {
    const [infoBitacora, setInfoBitacora] = useState([]);
    const [loading, setLoading] = useState(true);
    const gridApi = useRef(null);

    useEffect(() => {
        if (token) {
            axios
                .get("/accionesformularios", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    console.log(response.data, "info logs");
                    setInfoBitacora(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        editable: true,
    };

    const columnDefs = [
        {
            field: "formulario.NumeroCaso",
            headerName: "Numero de Caso",
        },
        {
            field: "Accion",
            headerName: "Accion",
        },
        {
            field: "Observacion",
            headerName: "Observación",
        },
        {
            field: "Fecha",
            headerName: "Fecha Acción",
        },
        {
            field: "usuario.name",
            headerName: "Usuario que modifico",
        },
    ];

    const handleExportCSV = () => {
        gridApi.current.exportDataAsCsv();
    };

    const onGridReady = (params) => {
        gridApi.current = params.api;
    };

    return (
        <>
            <Helmet>
                <title>Bitacora</title>
            </Helmet>
            {loading ? (
                <Spinner />
            ) : (
                <div className="container-fluid mt-5">
                    <Button color="success" onClick={handleExportCSV}>
                        Exportar CSV (Excel)
                    </Button>
                    <div
                        className="mt-3"
                        style={{ height: "500px", width: "100%" }}
                    >
                        <div
                            className="ag-theme-alpine"
                            style={{ height: "100%", width: "100%" }}
                        >
                            <AgGridReact
                                rowData={infoBitacora}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                pagination={true}
                                onGridReady={onGridReady}
                                localeText={localeEs}
                                paginationPageSize={20}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default withTokenCheck(AppReasignacionHogar);
