import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";

const ModalEdit = ({
  isOpen,
  toggle,
  title,
  id,
  NombreInicial,
  token,
  CelularInicial,
}) => {
  const [nombre, setNombre] = useState("");
  const [numeroContacto, setNumeroContacto] = useState("");
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);

  useEffect(() => {
    setNombre(NombreInicial);
    setNumeroContacto(CelularInicial);
  }, [NombreInicial, CelularInicial]);


  const handleNombreChange = (event) => {
    setNombre(event.target.value);
  };
  const handleNumeroContactoChange = (event) => {
    setNumeroContacto(event.target.value);
  };

  const handleEditSubmit = (event) => {
    event.preventDefault();
    axios
      .put(
        `/conductor/${id}`,
        {
          Nombre: nombre,
          Celular: parseInt(numeroContacto),
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Actualizado!",
          text: "el conductor a sido actualizado con exito.",
          showCloseButton: true,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "el conductor no se pudo actualizar",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label>Nombre conductor:</label>
          <input
            className="form-control"
            name="rol"
            type="text"
            value={nombre}
            onChange={handleNombreChange}
          />
        </FormGroup>
        <FormGroup>
          <label>Numero de contacto:</label>
          <input
            className="form-control"
            name="rol"
            type="text"
            value={numeroContacto}
            onChange={handleNumeroContactoChange}
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleEditSubmit} color="primary" otype="submit">
          Guardar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalEdit;
