import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import React, { useState, useEffect} from "react";
import axios from "axios";


const locales = {
    "es": require("date-fns/locale/es"),
};
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});


function AppCalendario(token) {

    const [allEvents, setAllEvents] = useState([]);

    useEffect(() => {
      if(token){
        axios
          .get("/calendario", { headers: { Authorization: `Bearer ${token}` } })
          .then((response) => {
            setAllEvents(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
        }
      }, [token]);

      console.log(allEvents, "eventos")
    return (
        <div className="App">
            <h1>Estimado en base a las solicitudes </h1>
            <Calendar localizer={localizer} events={allEvents} startAccessor="start" endAccessor="end" style={{ height: 1000, margin: "50px" }} />
        </div>
    );
}

export default AppCalendario;