import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const withTokenCheck = (WrappedComponent) => {
    return (props) => {
        const navigate = useNavigate();

        useEffect(() => {
            const checkToken = () => {
                const storedToken = Cookies.get("token");
                if (!storedToken) {
                    window.location.reload();
                    localStorage.setItem("loggedIn", false);
                    localStorage.setItem("session", 0);
                }
            };

            checkToken();
        }, [navigate]);

        return <WrappedComponent {...props} />;
    };
};

export default withTokenCheck;
