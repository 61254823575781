import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";

const ModalCreate = ({
  isOpen,
  toggle,
  title,
  token,
}) => {
  const [Rol, setRol] = useState("");
  const [formMode, setFormMode] = useState("none");
  const [hogar, setHogar] = useState([]);
  const [selectedHogar, setSelectedHogar] = useState("");
  const [selectedHogar2, setSelectedHogar2] = useState("");
  const modalRef = useRef(null);

  const handleRolChange = (event) => {
    setRol(event.target.value);
  };

  useEffect(() => {
    if (token) {
      axios
        .get("/hogar", { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          setHogar(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  const hogarOptions = hogar.map((option) => ({
    value: option.id,
    label: option.Nombre ?? option.nombre,
  }));

  const handleHogarChange = (selectedOption) => {
    setSelectedHogar2(selectedOption.value);
    setSelectedHogar(
      hogarOptions.find((option) => option.value === selectedOption.value)
    );
  };

  const handleCreateSubmit = (event) => {
    event.preventDefault();
    axios
      .post("/roles", {
        Nombre: Rol,
        HogarId: parseInt(selectedHogar2),
      }, {headers: { Authorization: `Bearer ${token}` }})
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Creado!",
          text: "el tipo de Rol ha sido creada con exito",
          showCloseButton: true,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Ha ocurrido un error al crear el tipo de Rol.",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label>Nombre de Rol:</label>
          <input
            className="form-control"
            name="rol"
            type="text"
            value={Rol}
            onChange={handleRolChange}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="MenuId">Hogar asociado:</label>
          <Select options={hogarOptions} onChange={handleHogarChange}></Select>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleCreateSubmit} color="primary" otype="submit">
          Insertar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalCreate;
