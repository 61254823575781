export const localeEs = {
    selectAll: "Seleccionar todo",
    selectAllSearchResults: "Seleccionar todos los resultados de búsqueda",
    searchOoo: "Buscar...",
    blanks: "Vacíos",
    noMatches: "Sin coincidencias",

    filterOoo: "Filtro...",
    equals: "Igual",
    notEqual: "Diferente",

    // Number Filter
    lessThan: "Menor que",
    greaterThan: "Mayor que",
    lessThanOrEqual: "Menor o igual que",
    greaterThanOrEqual: "Mayor o igual que",
    inRange: "En el rango",
    inRangeStart: "Desde",
    inRangeEnd: "Hasta",

    // Text Filter
    contains: "Contiene",
    notContains: "No contiene",
    startsWith: "Empieza con",
    endsWith: "Termina con",

    // Date Filter
    dateFormatOoo: "dd-mm-Yyyy",

    // Filter Conditions
    andCondition: "Y",
    orCondition: "O",

    // Filter Buttons
    applyFilter: "Aplicar",
    resetFilter: "Reiniciar",
    clearFilter: "Borrar",
    cancelFilter: "Cancelar",

    // Side Bar
    columns: "Columnas",
    filters: "Filtros",

    // columns tool panel
    pivotMode: "Modo Pivote",
    groups: "Grupos de columnas",
    rowGroupColumnsEmptyMessage: "Soltar aquí para crear un grupo de columnas",
    values: "Valores",
    valueColumnsEmptyMessage: "Soltar aquí para agregar",
    pivots: "Column Labels",
    pivotColumnsEmptyMessage: "Soltar aquí para indicar etiquetas de columna",

    // Header of the Default Group Column
    group: "Grupo",

    // Other
    loadingOoo: "Cargando...",
    noRowsToShow: "No hay filas para mostrar",
    enabled: "Activado",

    // Menu
    pinColumn: "Fijar Columna",
    pinLeft: "Fijar a Izquierda",
    pinRight: "Fijar a Derecha",
    noPin: "Soltar",
    valueAggregation: "Agregación por valor",
    autosizeThiscolumn: "Tamaño automático para esta Columna",
    autosizeAllColumns: "Tamaño automático para todas las Columnas",
    groupBy: "Agrupar por",
    ungroupBy: "Desagrupar por",
    resetColumns: "Reiniciar Columnas",
    expandAll: "Expandir Todo",
    collapseAll: "Cerrar Todo",
    copy: "Copiar",
    ctrlC: "Ctrl+C",
    copyWithHeaders: "Copiar con Cabeceras",
    paste: "Pegar",
    ctrlV: "Ctrl+V",
    export: "Exportar",
    csvExport: "Exportar a CSV",
    excelExport: "Exportar a Excel (.xlsx)",
    excelXmlExport: "Exportar a Excel antiguo (.xml)",

    // Enterprise Menu Aggregation and Status Bar
    sum: "Sum",
    first: "First",
    last: "Last",
    min: "Min",
    max: "Max",
    none: "Nada",
    count: "Cuenta",
    avg: "Media",
    filteredRows: "Filtrado",
    selectedRows: "Seleccionado",
    totalRows: "Total Filas",
    totalAndFilteredRows: "Filas",
    more: "Más",
    to: "a",
    of: "de",
    page: "Página",
    pageLastRowUnknown: "?",
    nextPage: "Siguiente",
    lastPage: "Ultimo",
    firstPage: "Primero",
    previousPage: "Anterior",
    pageSizeSelectorLabel: "Tamaño página:",
    footerTotal: "Total",

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: "Gráfico de Pivote y Modo Pivote",
    pivotChart: "Gráfico de Pivote",
    chartRange: "Gráfico de Intervalo",

    columnChart: "Columna",
    groupedColumn: "Agrupado",
    stackedColumn: "Apilado",
    normalizedColumn: "100% Apilado",

    barChart: "Barra",
    groupedBar: "Agrupado",
    stackedBar: "Apilado",
    normalizedBar: "100% Apilado",

    pieChart: "Tarta",
    pie: "Tarta",
    doughnut: "Donut",

    line: "Línea",

    xyChart: "X Y (Dispersión)",
    scatter: "Dispersión",
    bubble: "Burbuja",

    areaChart: "Area",
    area: "Area",
    stackedArea: "Apilado",
    normalizedArea: "100% Apilado",

    histogramChart: "Histograma",

    // Charts
    pivotChartTitle: "Gráfico de Pivote",
    rangeChartTitle: "Gráfico de Intervalo",
    settings: "Configuración",
    data: "Datos",
    format: "Formato",
    categories: "Categorías",
    defaultCategory: "(Ninguna)",
    series: "Series",
    xyValues: "Valores X Y",
    paired: "Mode pareado",
    axis: "Eje",
    navigator: "Navegador",
    color: "Color",
    thickness: "Grosor",
    xType: "Tipo X",
    automatic: "Automático",
    category: "Categoría",
    number: "Número",
    time: "Tiempo",
    xRotation: "Rotación X",
    yRotation: "Rotación Y",
    ticks: "Ticks",
    width: "Ancho",
    height: "Alto",
    length: "Longitud",
    padding: "Relleno",
    spacing: "Espaciado",
    chart: "Gráfico",
    title: "Título",
    titlePlaceholder: "Título del Gráfico - doble clic para editar",
    background: "Fondo",
    font: "Fuente",
    top: "Arriba",
    right: "Derecha",
    bottom: "Abajo",
    left: "Izquierda",
    labels: "Etiquetas",
    size: "Tamaño",
    minSize: "Tamaño mínimo",
    maxSize: "Tamaño máximo",
    legend: "Leyenda",
    position: "Posición",
    markerSize: "Ancho pincel",
    markerStroke: "Trazo pincel",
    markerPadding: "Relleno pincel",
    itemSpacing: "Espaciado del ítem",
    itemPaddingX: "Relleno X del ítem",
    itemPaddingY: "Relleno Y del ítem",
    layoutHorizontalSpacing: "Espaciado horizontal",
    layoutVerticalSpacing: "Espaciado vertical",
    strokeWidth: "Ancho del trazo",
    offset: "Desplazamiento",
    offsets: "Desplazamiento",
    tooltips: "Tooltips",
    callout: "Rótulo",
    markers: "Pinceles",
    shadow: "Sombra",
    blur: "Difuminado",
    xOffset: "Desplazamiento X",
    yOffset: "Desplazamiento Y",
    lineWidth: "Ancho de línea",
    normal: "Normal",
    bold: "Negrita",
    italic: "Itálica",
    boldItalic: "Negrita Itálica",
    predefined: "Predefinido",
    fillOpacity: "Opacidad de relleno",
    strokeOpacity: "Opacidad de línea",
    histogramBinCount: "Total papelera",
    columnGroup: "Columna",
    barGroup: "Barra",
    pieGroup: "Tarta",
    lineGroup: "Línea",
    scatterGroup: "X Y (Dispersión)",
    areaGroup: "Area",
    histogramGroup: "Histograma",
    groupedColumnTooltip: "Agrupado",
    stackedColumnTooltip: "Apilado",
    normalizedColumnTooltip: "100% Apilado",
    groupedBarTooltip: "Agrupado",
    stackedBarTooltip: "Apilado",
    normalizedBarTooltip: "100% Apilado",
    pieTooltip: "Tsrta",
    doughnutTooltip: "Donut",
    lineTooltip: "Línea",
    groupedAreaTooltip: "Area",
    stackedAreaTooltip: "Apilado",
    normalizedAreaTooltip: "100% Apilado",
    scatterTooltip: "Dispersión",
    bubbleTooltip: "Burbuja",
    histogramTooltip: "Histograma",
    noDataToChart: "No hay datos disponibles para hacer el gráfico.",
    pivotChartRequiresPivotMode:
        "Gráfico de Pivote requiere activar el modo Pivote.",
};
