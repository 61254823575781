import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button } from "reactstrap";
import { Helmet } from "react-helmet";
import Spinner from "../../Spinner/spinner";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import ModalCreate from "./ModalCreate";
import ModalEdit from "./ModalEdit";
import { localeEs } from "../../locale/ag-grid-es";
import withTokenCheck from "../../withTokenCheck";
import Swal from "sweetalert2";
import DescripcionCubrimiento from "../DescriptionCRUD/DescripcionCubrimiento";
import { setDefaultLocale } from "react-datepicker";

const AppCodigos = ({ token }) => {
    const [codigoDescripcion, setCodigoDescripcion] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [modalEditcontent, setModalEditContent] = useState({});
    const [modalTittleContent, setModalTittleContent] = useState({});
    const gridApi = useRef(null);

    useEffect(() => {
        if (token) {
            axios
                .get("/codigoDescripcion", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setCodigoDescripcion(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        editable: true,
    };

    const columnDefs = [
        { headerName: "Codigo", field: "CodigoServicio", width: 250 },
        { headerName: "Descripción", field: "descripcion.Texto", flex: 1 },
        {
            headerName: "Editar",
            cellRenderer: (params) => (
                <Button
                    color="secondary"
                    onClick={() => handleEditClick(params.data)}
                >
                    Editar
                </Button>
            ),
            width: 300
        },
    ];

    const handleModalToggle = () => setModalOpen(!modalOpen);
    const handleEditModalToggle = () => setModalEditOpen(!modalEditOpen);

    const handleButtonClick = () => {
        setModalContent({
            title: "asociar codigo",
            footer: (
                <Button
                    className="mb-3"
                    color="secondary"
                    onClick={handleModalToggle}
                >
                    Cancelar
                </Button>
            ),
        });
        handleModalToggle();
    };

    const handleEditClick = (element) => {
        console.log(element);
        setModalEditContent(element);
        setModalTittleContent({
            title: "Actualizar Acompañante",
            footer: (
                <Button
                    className="mb-3"
                    color="secondary"
                    onClick={handleEditModalToggle}
                >
                    Cancelar
                </Button>
            ),
        });
        handleEditModalToggle();
    };

    const handleExportCSV = () => {
        gridApi.current.exportDataAsCsv();
    };

    const onGridReady = (params) => {
        gridApi.current = params.api;
    };

    const handleUpdateTable = () => {
        setLoading(true);
        if (token) {
            axios
                .get("/codigoDescripcion", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setCodigoDescripcion(response.data);
                    setLoading(false);
                    
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };


    return (
        <>
            <Helmet>
                <title>codigo Descripción</title>
            </Helmet>
            <div style={{ position: "relative" }}>
                {loading && <Spinner />}
                <div style={{ opacity: loading ? 0.5 : 1 }}>
                    <div className="container-fluid mt-5">
                        <Button color="primary" onClick={handleButtonClick}>
                            Añadir Codigo
                        </Button>
                        <Button color="success" onClick={handleExportCSV}>
                            Exportar CSV (Excel)
                        </Button>
                        <div
                            className="mt-3"
                            style={{ height: "500px", width: "100%" }}
                        >
                            <div
                                className="ag-theme-alpine"
                                style={{ height: "100%", width: "100%" }}
                            >
                                <AgGridReact
                                    rowData={codigoDescripcion}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    pagination={true}
                                    onGridReady={onGridReady}
                                    localeText={localeEs}
                                    paginationPageSize={20}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalCreate
                isOpen={modalOpen}
                toggle={handleModalToggle}
                title={modalContent.title}
                content={modalContent.content}
                footer={modalContent.footer}
                token={token}
                updateTable={handleUpdateTable}
            />
            <ModalEdit
                isOpen={modalEditOpen}
                toggle={handleEditModalToggle}
                modalContent={modalEditcontent}
                footer={modalEditcontent.footer}
                token={token}
                updateTable={handleUpdateTable}
                codigoDescripcion={modalEditcontent.CodigoServicio}
                descripcionAsosiadaId={modalEditcontent.descripcion ? modalEditcontent.descripcion.id : ""}
                id={modalEditcontent.id ? modalEditcontent.id : ""}
            />
        </>
    );
};

export default withTokenCheck(AppCodigos);
