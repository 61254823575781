import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";

const ModalCreate = ({ isOpen, toggle, title, token }) => {
  const [rol, setRol] = useState([]);
  const [selectedRol, setSelectedRol] = useState("");
  const [selectedRol2, setSelectedRol2] = useState("");
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);

  const handleNombreChange = (event) => {
    setNombre(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRolChange = (selectedOption) => {
    setSelectedRol2(selectedOption.value);
    setSelectedRol(
      rolOptions.find((option) => option.value === selectedOption.value)
    );
  };

  useEffect(() => {
    if (token) {
      axios
        .get("/roles", { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          setRol(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  const rolOptions = rol.map((option) => ({
    value: option.id,
    label: option.Nombre ?? option.nombre,
  }));

  const handleCreateSubmit = (event) => {
    event.preventDefault();
    axios
      .post(
        "/usuario",
        {
          RolId: selectedRol2,
          name: nombre,
          email: email,
          password: password,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Creado!",
          text: "el usuario ha sido creado con exito",
          showCloseButton: true,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Ha ocurrido un error al crear el usuario",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label>Nombre</label>
          <input
            className="form-control"
            name="MenuMasterId"
            type="text"
            value={nombre}
            onChange={handleNombreChange}
          />
        </FormGroup>
        <FormGroup>
          <label>Email</label>
          <input
            className="form-control"
            name="MenuMasterId"
            type="text"
            value={email}
            onChange={handleEmailChange}
          />
        </FormGroup>
        <FormGroup>
          <label>Password</label>
          <input
            className="form-control"
            name="MenuMasterId"
            type="text"
            value={password}
            onChange={handlePasswordChange}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="RolId">Rol:</label>
          <Select options={rolOptions} onChange={handleRolChange}></Select>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleCreateSubmit} color="primary" otype="submit">
          Insertar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalCreate;
