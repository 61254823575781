import { TextField } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";

const CustomModal = ({ isOpen, toggle, infoModal, updateTable, token }) => {
    const [room, setRoom] = useState([]);
    const [patientHabitacionId, setPatientHabitacionId] = useState(null);
    const [persona, setPersona] = useState("");
    const [formId, setFormId] = useState("");
    const [personaId, setPersonaId] = useState("");
    const [tipoPerId, setTipoPerId] = useState("");
    const [tipoPersona, setTipoPersona] = useState("");
    const [title, setTitle] = useState("");
    const modalRef = useRef(null);

    useEffect(() => {
        setTipoPerId(infoModal.TipoPersonaId);
        setFormId(infoModal.FormularioId);
        setPersonaId(infoModal.PersonaId);
        setPersona(infoModal.Persona);
        setTipoPersona(infoModal.Texto);
        setTitle("Reasignación");
    }, [infoModal]);

    useEffect(() => {
        if (token) {
            axios
                .get("/habitacion", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setRoom(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const roomOptions = room.map((option) => ({
        value: option.id,
        label:
            option.hogar.Nombre + ", Habitación: " + option.NumeroHabitacion ??
            option.NumeroHabitacion,
    }));

    const handleCreateSubmit = (event) => {
        event.preventDefault();
        // Construir los datos del paciente
        const patientData = {
            FormularioId: formId,
            TipoPersonaId: tipoPerId,
            PersonaId: personaId,
            HabitacionId: parseInt(patientHabitacionId),
        };

        // Enviar los datos del paciente y de los acompañantes
        axios
            .put("/asignacion/actualizacion/reasignaciones", patientData, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: "Actualizado!",
                    text: "La reasignación ha sido registrada con exito.",
                    showCloseButton: true,
                }).then(() => {
                    updateTable();
                    toggle();
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: error.response.data.message,
                    showCloseButton: true,
                });
            });
    };

    const handlePatientHabitacionChange = (selectedOption) => {
        setPatientHabitacionId(selectedOption.value);
    };

    return (
        <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <label>Tipo usuario</label>
                    <TextField
                        disabled
                        value={tipoPersona}
                        className="form-control"
                        name="tipoPersona"
                        type="text"
                    />
                </FormGroup>
                <FormGroup>
                    <label>Usuario</label>
                    <TextField
                        disabled
                        className="form-control"
                        name="Texto"
                        type="text"
                        value={persona}
                    />
                </FormGroup>
                <FormGroup>
                    <label htmlFor="habitacionId">
                        Seleccionar habitación:
                    </label>
                    <Select
                        options={roomOptions}
                        onChange={handlePatientHabitacionChange}
                        className="form-control"
                    ></Select>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={handleCreateSubmit}
                    className="mb-4"
                    color="success"
                    type="submit"
                    id="ButtonSub"
                    variant="contained"
                >
                    Confirmar
                </Button>

                <Button
                    onClick={toggle}
                    className="mb-4"
                    type="submit"
                    color="danger"
                    id="ButtonClose"
                    variant="contained"
                >
                    Cancelar
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CustomModal;
