import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";

const ModalEdit = ({ isOpen, toggle, title, id, textoInicial, token }) => {
  const [tipoServicio, setTipoServicio] = useState("");
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);

  useEffect(() => {
    setTipoServicio(textoInicial);
  }, [textoInicial]);

  const handleTipoServicioChange = (event) => {
    setTipoServicio(event.target.value);
  };

  useEffect(() => {
    if (token) {
      axios
        .get("/tiposerviciohogar", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setTipoServicio(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);


  const handleEditSubmit = (event) => {
    event.preventDefault();
    axios
      .put(
        `/tiposerviciohogar/${id}`,
        {
          Texto: tipoServicio,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Actualizado!",
          text: "el tipo servicio a sido actualizado con exito.",
          showCloseButton: true,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "el tipo servicio no se pudo actualizar",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label>Tipo de servicio:</label>
          <input
            className="form-control"
            name="nombre"
            type="text"
            value={tipoServicio}
            onChange={handleTipoServicioChange}
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleEditSubmit} color="primary" otype="submit">
          Guardar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalEdit;
