/* import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import * as jzip from "jszip";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import ModalCreate from "./ModalCreate";
import ModalEdit from "./ModalEdit";
import Swal from "sweetalert2";
import { Button, FormGroup } from "reactstrap";
import Select from "react-select";

const AppTypeCompanion = ({ token }) => {
  const [numberCase, setNumberCase] = useState([]);
  const [selectedCase, setSelectedCase] = useState("");
  const [selectedCase2, setSelectedCase2] = useState("");
  const [formId, setFormId] = useState({});
  const [patient, setPatient] = useState("");
  const [patientId, setPatientId] = useState("");
  const [acompananteInicial, setAcompananteInicial] = useState("");
  const [companion, setCompanion] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [modalEditcontent, setModalEditContent] = useState({});
  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const tableRef = useRef(null);

  useEffect(() => {
    if (token) {
      axios
        .get("/edicion-formulario", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setNumberCase(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  const numberCaseoptions = numberCase.map((option) => ({
    value: option.id,
    label: option.NumeroCaso ?? option.NumeroCaso,
  }));

  const handleNumberCaseChange = (selectedOption) => {
    setSelectedCase2(selectedOption.value);
    setSelectedCase(
      numberCaseoptions.find((option) => option.value === selectedOption.value)
    );
    setFormId(selectedOption.value);
  };
  const handleSelectCase = () => {
    if (token) {
      axios
        .get(`/edicion-formulario/acompanantes/${selectedCase2}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setCompanion(response.data.acompanantes);
          console.log(response.data.acompanantes);
          setPatient(
            response.data.paciente.Nombre1 +
              " " +
              (response.data.paciente.Nombre2
                ? response.data.paciente.Nombre2
                : "") +
              " " +
              response.data.paciente.Apellido1 +
              " " +
              (response.data.paciente.Apellido2
                ? response.data.paciente.Apellido2
                : "")
          );
          setPatientId(response.data.PacienteId);
          setDataLoaded(true);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "Ha ocurrido un error al Consultar los acompañantes para el numero de caso.",
            showCloseButton: true,
          });
        });
    }
  };
  const handleModalToggle = () => setModalOpen(!modalOpen);
  const handleEditModalToggle = () => setModalEditOpen(!modalEditOpen);

  const handleButtonClick = (content) => {
    setModalContent(content);
    handleModalToggle();
  };

  const handleEditClick = (element) => {
    setAcompananteInicial(element.id);
    setModalEditContent(element);
    handleEditModalToggle();
  };

  const handleDeleteClick = (acompananteToDelete) => {
    axios
      .post(
        "/edicion-formulario/acompanantes/eliminar",
        {
          FormularioId: formId,
          AcompananteId: acompananteToDelete.id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setCompanion(
          companion.filter(
            (companion) => companion.id !== acompananteToDelete.id
          )
        );
        Swal.fire({
          icon: "success",
          title: "Eliminado!",
          text: "el acompañante ha sido eliminado con exito.",
          showCloseButton: true,
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Ha ocurrido un error al eliminar el acompañante.",
        });
      });
  };

  useEffect(() => {
    let dataTable;
    if (!loading) {
      $(tableRef.current).DataTable({
        paging: true,
        ordering: true,
        info: true,
        dom: "<'row'<'col-sm-2'l><'col-sm-6'B><'col-sm-4'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-6 text-right'i><'col-sm-6'p>>",
        language: {
          url: "//cdn.datatables.net/plug-ins/1.13.1/i18n/es-ES.json",
        },
        buttons: {
          dom: {
            button: {
              tag: "button",
              className: "btn",
            },
          },
          buttons: [
            {
              extend: "excel",
              className: "btn-success",
              text: "<i class='far fa-file-excel'></i> Excel",
              extension: ".xlsx",
            },
          ],
        },
      });
    }
    return () => {
      if (dataTable) {
        dataTable.destroy();
      }
    };
  }, [loading]);

  return (
    <>
      {loading ? (
        <div>Cargando...</div>
      ) : (
        <div style={{ maxWidth: "1200px", margin: "50px auto" }}>
          <div style={{ width: "100%", overflowX: "auto" }}>
            <label htmlFor="servicioId">Seleccione numero de caso</label>
            <FormGroup id="FormEdit">
              <Select
                id="SelectEdit"
                options={numberCaseoptions}
                value={selectedCase}
                onChange={handleNumberCaseChange}
              ></Select>
              <Button onClick={handleSelectCase}>Consultar</Button>
            </FormGroup>
            <td>
              <Button
                color="primary"
                onClick={() =>
                  handleButtonClick({
                    title: "añadir acompañante",
                    footer: (
                      <Button
                        className="mb-3"
                        color="secondary"
                        onClick={handleModalToggle}
                      >
                        Cancelar
                      </Button>
                    ),
                  })
                }
              >
                Añadir acompañante
              </Button>
            </td>
            <div style={{ height: "20px" }}></div>
            <table
              ref={tableRef}
              id="myTable"
              className="table"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th>Editar</th>
                  <th>Eliminar</th>
                  <th>Paciente</th>
                  <th>Acompañante</th>
                  ...
                </tr>
              </thead>
              {dataLoaded ? (
                <tbody>
                  {companion.map((element) => (
                    <tr key={element.id}>
                       <td>
                        <Button
                          className="mb-3"
                          color="secondary"
                          onClick={() =>
                            handleEditClick(element, {
                              title: "Actualizar acompañante asignado",
                              footer: (
                                <Button
                                  className="mb-3"
                                  color="secondary"
                                  onClick={handleModalToggle}
                                >
                                  Cancelar
                                </Button>
                              ),
                            })
                          }
                        >
                          Editar
                        </Button>
                      </td>
                      <td>
                        <Button
                          color="danger"
                          onClick={() => handleDeleteClick(element)}
                        >
                          Eliminar
                        </Button>
                      </td>
                      <td>{patient}</td>
                      <td>
                        {element.Nombre1 +
                          " " +
                          (element.Nombre2 ? element.Nombre2 : " ") +
                          " " +
                          element.Apellido1 +
                          " " +
                          (element.Apellido2 ? element.Apellido2 : " ")}
                      </td>
                     
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                      colSpan="6"
                    >
                      {"No hay información en la tabla"}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              )}
            </table>
            <ModalCreate
              isOpen={modalOpen}
              toggle={handleModalToggle}
              title={modalContent.title}
              content={modalContent.content}
              footer={modalContent.footer}
              formId={formId}
              pacienteId={patientId}
              token={token}
            />
            <ModalEdit
              isOpen={modalEditOpen}
              toggle={handleEditModalToggle}
              title={modalEditcontent.title}
              pacienteId={patientId}
              formId={formId}
              footer={modalEditcontent.footer}
              acompananteInicialId={acompananteInicial}
              token={token}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AppTypeCompanion;
 */

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button, FormGroup } from "reactstrap";
import { Helmet } from "react-helmet";
import Spinner from "../../Spinner/spinner";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { localeEs } from "../../locale/ag-grid-es";
import ModalCreate from "./ModalCreate";
import ModalEdit from "./ModalEdit";
import Swal from "sweetalert2";
import Select from "react-select";
import BreadCrum from "../../breadCrum/breadCrum";
import withTokenCheck from "../../withTokenCheck";

const AppEditCompanion = ({ token }) => {
    const [numberCase, setNumberCase] = useState([]);
    const [selectedCase, setSelectedCase] = useState("");
    const [selectedCase2, setSelectedCase2] = useState("");
    const [formId, setFormId] = useState({});
    const [patient, setPatient] = useState("");
    const [patientId, setPatientId] = useState("");
    const [acompananteInicial, setAcompananteInicial] = useState("");
    const [companion, setCompanion] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [modalEditcontent, setModalEditContent] = useState({});
    const [loading, setLoading] = useState(true);
    const [dataLoaded, setDataLoaded] = useState(false);
    const etiquetas = [
        "Inicio Lossman",
        "Cambios De Evento",
        "Modificar Acompañantes",
    ];

    const gridApi = useRef(null);

    useEffect(() => {
        if (token) {
            axios
                .get("/edicion-formulario", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setNumberCase(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const numberCaseoptions = numberCase.map((option) => ({
        value: option.id,
        label: option.NumeroCaso ?? option.NumeroCaso,
    }));

    const handleNumberCaseChange = (selectedOption) => {
        setSelectedCase2(selectedOption.value);
        setSelectedCase(
            numberCaseoptions.find(
                (option) => option.value === selectedOption.value
            )
        );
        setFormId(selectedOption.value);
    };
    const handleSelectCase = () => {
        setLoading(true);
        if (token) {
            axios
                .get(`/edicion-formulario/acompanantes/${selectedCase2}`, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setCompanion(response.data.acompanantes);
                    console.log(response.data.acompanantes);
                    setPatient(
                        response.data.paciente.Nombre1 +
                            " " +
                            (response.data.paciente.Nombre2
                                ? response.data.paciente.Nombre2
                                : "") +
                            " " +
                            response.data.paciente.Apellido1 +
                            " " +
                            (response.data.paciente.Apellido2
                                ? response.data.paciente.Apellido2
                                : "")
                    );
                    setPatientId(response.data.PacienteId);
                    setDataLoaded(true);
                    setLoading(false);
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        title: "Error!",
                        text: "Ha ocurrido un error al Consultar los acompañantes para el numero de caso.",
                        showCloseButton: true,
                    });
                });
        }
    };
    const handleModalToggle = () => setModalOpen(!modalOpen);
    const handleEditModalToggle = () => setModalEditOpen(!modalEditOpen);

    const handleButtonClick = (content) => {
        setModalContent(content);
        handleModalToggle();
    };

    const handleEditClick = (element) => {
        setAcompananteInicial(element.id);
        setModalEditContent(element);
        handleEditModalToggle();
    };

    const handleDeleteClick = (acompananteToDelete) => {
        axios
            .post(
                "/edicion-formulario/acompanantes/eliminar",
                {
                    FormularioId: formId,
                    AcompananteId: acompananteToDelete.id,
                },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((response) => {
                setCompanion(
                    companion.filter(
                        (companion) => companion.id !== acompananteToDelete.id
                    )
                );
                Swal.fire({
                    icon: "success",
                    title: "Eliminado!",
                    text: "el acompañante ha sido eliminado con exito.",
                    showCloseButton: true,
                });
            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "Ha ocurrido un error al eliminar el acompañante.",
                });
            });
    };

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        editable: true,
    };

    const columnDefs = [
        {
            field: "id",
            headerName: "Editar acompañante",
            cellRenderer: (params) => (
                <Button
                    color="secondary"
                    onClick={() => handleEditClick(params.data)}
                >
                    Editar
                </Button>
            ),
            flex: 1,
        },
        {
            field: "id",
            headerName: "Eliminar acompañante",
            cellRenderer: (params) => (
                <Button
                    color="secondary"
                    onClick={() => handleDeleteClick(params.data)}
                >
                    Elminiar
                </Button>
            ),
            flex: 1,
        },
        {
            headerName: "Nombre del paciente",
            field: "formulario.paciente.Nombre1",
            cellRenderer: (params) => {
                const nombre1 = params.data.Nombre1 || " ";
                const nombre2 = params.data.Nombre2 || " ";
                const apellido1 = params.data.Apellido1 || " ";
                const apellido2 = params.data.Apellido2 || " ";
                return (
                    nombre1 + " " + nombre2 + " " + apellido1 + " " + apellido2
                );
            },
            flex: 1,
        },
        {
            headerName: "Nombre del Acompañante",
            field: "formulario.paciente.Nombre1",
            cellRenderer: (params) => {
                const nombre1 = params.data.Nombre1 || " ";
                const nombre2 = params.data.Nombre2 || " ";
                const apellido1 = params.data.Apellido1 || " ";
                const apellido2 = params.data.Apellido2 || " ";
                return (
                    nombre1 + " " + nombre2 + " " + apellido1 + " " + apellido2
                );
            },
            flex: 1,
        },
    ];

    const handleExportCSV = () => {
        gridApi.current.exportDataAsCsv();
    };

    const onGridReady = (params) => {
        gridApi.current = params.api;
    };

    const handleUpdateTable = () => {
        if (token) {
            setLoading(true);
            axios
                .get("/asignacion", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setCompanion(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    return (
        <>
            <Helmet>
                <title>Editar Acompañante</title>
            </Helmet>
            <div style={{ position: "relative" }}>
                {loading && <Spinner />}
                <div>
                    <BreadCrum etiquetas={etiquetas} />
                </div>
                <div
                    style={{
                        width: "90%",
                        margin: "0 auto",
                        marginTop: "2rem",
                    }}
                >
                    <label htmlFor="servicioId">
                        Seleccione el numero de caso que desea modificar
                    </label>
                    <FormGroup id="FormEdit">
                        <Select
                            id="SelectEdit"
                            options={numberCaseoptions}
                            value={selectedCase}
                            onChange={handleNumberCaseChange}
                        ></Select>
                        <Button onClick={handleSelectCase}>Consultar</Button>
                    </FormGroup>
                </div>
                <div style={{ opacity: loading ? 0.5 : 1 }}>
                    <div className="container-fluid mt-5">
                        <div>
                            <Button
                                color="success"
                                onClick={handleExportCSV}
                                style={{ marginRight: "10px" }}
                            >
                                Exportar CSV (Excel)
                            </Button>
                            <Button
                                color="primary"
                                onClick={() =>
                                    handleButtonClick({
                                        title: "añadir acompañante",
                                        footer: (
                                            <Button
                                                className="mb-3"
                                                color="secondary"
                                                onClick={handleModalToggle}
                                            >
                                                Cancelar
                                            </Button>
                                        ),
                                    })
                                }
                            >
                                Añadir acompañante
                            </Button>
                        </div>

                        <div
                            className="mt-3"
                            style={{ height: "500px", width: "100%" }}
                        >
                            <div
                                className="ag-theme-alpine"
                                style={{ height: "100%", width: "100%" }}
                            >
                                <AgGridReact
                                    rowData={companion}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    pagination={true}
                                    onGridReady={onGridReady}
                                    localeText={localeEs}
                                    paginationPageSize={20}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalCreate
                isOpen={modalOpen}
                toggle={handleModalToggle}
                title={modalContent.title}
                content={modalContent.content}
                footer={modalContent.footer}
                formId={formId}
                pacienteId={patientId}
                token={token}
            />
            <ModalEdit
                isOpen={modalEditOpen}
                toggle={handleEditModalToggle}
                title={modalEditcontent.title}
                pacienteId={patientId}
                formId={formId}
                footer={modalEditcontent.footer}
                acompananteInicialId={acompananteInicial}
                token={token}
            />
        </>
    );
};

export default withTokenCheck(AppEditCompanion);
