import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@material-ui/core";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";

const ModalEdit = ({
    isOpen,
    toggle,
    updatedTable,
    title,
    token,
    HogarId,
    FormularioId,
    Nombre,
    NumeroCaso,
    Celular,
    RutaTraslado,
    HoraCita,
    HoraSalida,
    Conductor,
    PlacaVehiculo,
    Observacion,
    TipoServicioId,
    ServicioId,
    FechaServicioIni,
    SolicitudId,
}) => {
    const [formMode, setFormMode] = useState("none");
    const modalRef = useRef(null);
    const [fechaServicio, setFechaServicio] = useState("");
    const [hogarTraslado, setHogarTraslado] = useState([]);
    const [numeroCaso, setNumeroCaso] = useState([]);
    const [rutaTraslado, setRutraTraslado] = useState("");
    const [horaCita, setHoraCita] = useState("");
    const [horaSalida, setHoraSalida] = useState("");
    const [horaCitaInicial, setHoraCitaInicial] = useState("");
    const [horaSalidaInicial, setHoraSalidaInicial] = useState("");
    const [horaCitaApi, setHoraCitaApi] = useState("");
    const [horaSalidaApi, setHoraSalidaApi] = useState("");
    const [tipoServicioHogar, setTipoServicioHogar] = useState([]);
    const [selectedServicioHogar, setSelectedServicioHogar] = useState("");
    const [selectedServicioHogar2, setSelectedServicioHogar2] = useState("");
    const [selectedHogarTraslado, setSelectedHogarTraslado] = useState("");
    const [selectedHogarTraslado2, setSelectedHogarTraslado2] = useState("");
    const [selectedNumeroCaso, setSelectedNumeroCaso] = useState("");
    const [selectedNumeroCaso2, setSelectedNumeroCaso2] = useState("");
    const [placaVehiculo, setPlacaVehiculo] = useState("");
    const [servicios, setServicios] = useState([]);
    const [selectedServicio, setSelectedServicio] = useState("");
    const [selectedServicio2, setSelectedServicio2] = useState("");
    const [mostrarServicios, setMostrarServicios] = useState(false);
    const [conductor, setConductor] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [observaciones, setObservaciones] = useState("");

    useEffect(() => {
        setRutraTraslado(RutaTraslado);
        setObservaciones(Observacion);
        if (ServicioId) {
            setMostrarServicios("SI");
        }
        const fechaFormateada = FechaServicioIni.split(" ")[0];
        setFechaServicio(fechaFormateada);
    }, [
        Nombre,
        NumeroCaso,
        Celular,
        RutaTraslado,
        Observacion,
        FechaServicioIni,
    ]);

    useEffect(() => {
        // Parse la hora militar en un objeto Date
        const horaCitaDate = new Date(`1970-01-01T${HoraCita}`);
        const horaSalidaDate = new Date(`1970-01-01T${HoraSalida}`);
        setHoraSalidaInicial(horaSalidaDate);
        setHoraCitaInicial(horaCitaDate);
        const horas = horaCitaDate.getHours();
        const minutos = horaCitaDate.getMinutes();
        const segundos = horaCitaDate.getSeconds();
        const formattedTime = `${horas.toString().padStart(2, "0")}:${minutos
            .toString()
            .padStart(2, "0")}:${segundos.toString().padStart(2, "0")}`;
        setHoraCitaApi(formattedTime);
        const horasS = horaSalidaDate.getHours();
        const minutosS = horaSalidaDate.getMinutes();
        const segundosS = horaSalidaDate.getSeconds();
        const formattedTimeS = `${horasS.toString().padStart(2, "0")}:${minutosS
            .toString()
            .padStart(2, "0")}:${segundosS.toString().padStart(2, "0")}`;
        setHoraSalidaApi(formattedTimeS);
    }, [HoraCita]);

    const handleTimeChange = (newTime) => {
        const timeZone = "America/Bogota";
        const horas = newTime.getHours();
        const minutos = newTime.getMinutes();
        const segundos = newTime.getSeconds();
        const formattedTime = `${horas.toString().padStart(2, "0")}:${minutos
            .toString()
            .padStart(2, "0")}:${segundos.toString().padStart(2, "0")}`;
        setHoraCita(formattedTime);
    };

    const handleTimeEndChange = (newTime) => {
        const timeZone = "America/Bogota";
        const horas = newTime.getHours();
        const minutos = newTime.getMinutes();
        const segundos = newTime.getSeconds();
        const formattedTime = `${horas.toString().padStart(2, "0")}:${minutos
            .toString()
            .padStart(2, "0")}:${segundos.toString().padStart(2, "0")}`;
        setHoraSalida(formattedTime);
    };

    useEffect(() => {
        if (token) {
            axios
                .get("/programacionformulario/case-number", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setNumeroCaso(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            axios
                .get("/tiposerviciohogar", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setTipoServicioHogar(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            axios
                .get("/hogar", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setHogarTraslado(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (token && FormularioId) {
            axios
                .get(`/formulario/consulta/servicios/${FormularioId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    const servicioDetalle =
                        response.data ?? response.data.servicio_maestro;

                    setServicios(servicioDetalle);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token, FormularioId]);

    const numeroCasoOptions = numeroCaso.map((option) => ({
        value: option.Id,
        label: option.NumeroCaso ?? option.NumeroCaso,
    }));

    const hogarTrasladoOptions = hogarTraslado.map((option) => ({
        value: option.id,
        label: option.Nombre ?? option.Nombre,
    }));

    const tipoServicioHogarOptions = tipoServicioHogar.map((option) => ({
        value: option.id,
        label: option.Texto ?? option.Texto,
    }));

    const servicioDiscriminado = servicios.flatMap((option) => {
        if (option.servicio_maestro && option.servicio_maestro.FormularioId) {
            return option.servicio_maestro.servicio_detalle.map((detalle) => ({
                value: detalle.id,
                label: detalle.tipo_servicio.Texto,
            }));
        } else {
            return [
                {
                    value: "N.A",
                    label: "N.A",
                },
            ];
        }
    });

    const handleServicioChange = (selectedOption) => {
        setSelectedServicio2(selectedOption.value);
        setSelectedServicio(selectedOption);
    };
    const handleFechaSolicitudChange = (event) => {
        setFechaServicio(event.target.value);
    };

    const handleNumeroCasoChange = (selectedOption) => {
        setSelectedNumeroCaso2(selectedOption.value);
        setSelectedNumeroCaso(selectedOption);
    };

    const handleTipoServicioHogarChange = (selectedOption) => {
        setSelectedServicioHogar2(selectedOption.value);
        setSelectedServicioHogar(selectedOption);
    };

    const handleHogarTrasladoChange = (selectedOption) => {
        setSelectedHogarTraslado2(selectedOption.value);
        setSelectedHogarTraslado(selectedOption);
    };

    const handleRutaTrasladoChange = (event) => {
        setRutraTraslado(event.target.value);
    };
    const handleObservacionesChange = (event) => {
        setObservaciones(event.target.value);
    };
    const handlePlacaVehicularChange = (event) => {
        setPlacaVehiculo(event.target.value);
    };
    const handleConductorChange = (event) => {
        setConductor(event.target.value);
    };

    useEffect(() => {
        if (!mostrarServicios) {
            setSelectedServicio2(null);
        }
    });

    const handleEditSubmit = (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        axios
            .put(
                `/programacionformularios/${SolicitudId}`,
                {
                    HogarTrasladoId: selectedHogarTraslado2
                        ? selectedHogarTraslado2
                        : HogarId,
                    TipoServicioHogarId: selectedServicioHogar2
                        ? selectedServicioHogar2
                        : TipoServicioId,
                    ServicioDetalleId:
                        selectedServicio2 || selectedServicio2 === null
                            ? selectedServicio2
                            : ServicioId,
                    RutaTraslado: rutaTraslado,
                    HoraCita: horaCita ? horaCita : horaCitaApi,
                    HoraSalida: horaSalida ? horaSalida : horaSalidaApi,
                    Observacion: observaciones,
                    PlacaVehiculo: placaVehiculo
                        ? placaVehiculo
                        : PlacaVehiculo,
                    Conductor: conductor ? conductor : Conductor,
                    FormularioId: selectedNumeroCaso2
                        ? selectedNumeroCaso2
                        : FormularioId,
                    FechaServicio: fechaServicio
                        ? fechaServicio
                        : FechaServicioIni,
                },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: "Actualizado!",
                    text: "el tipo servicio a sido actualizado con exito.",
                    showCloseButton: true,
                }).then(() => {
                    toggle();
                    updatedTable();
                    setIsSubmitting(false);
                });
            })
            .catch((error) => {
                setIsSubmitting(false);
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "el tipo servicio no se pudo actualizar",
                    showCloseButton: true,
                });
            });
    };

    return (
        <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <label htmlFor="NumeroCaso">Numero de Caso:</label>
                    <Select
                        options={numeroCasoOptions}
                        value={
                            selectedNumeroCaso
                                ? selectedNumeroCaso
                                : numeroCasoOptions.find(
                                      (option) => option.value === FormularioId
                                  )
                        }
                        onChange={handleNumeroCasoChange}
                    ></Select>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="ServicioHogar">Hogar:</label>
                    <Select
                        options={hogarTrasladoOptions}
                        value={
                            selectedHogarTraslado
                                ? selectedHogarTraslado
                                : hogarTrasladoOptions.find(
                                      (option) => option.value === HogarId
                                  )
                        }
                        onChange={handleHogarTrasladoChange}
                    ></Select>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="ServicioHogar">Servicio del hogar:</label>
                    <Select
                        options={tipoServicioHogarOptions}
                        value={
                            selectedServicioHogar
                                ? selectedServicioHogar
                                : tipoServicioHogarOptions.find(
                                      (option) =>
                                          option.value === TipoServicioId
                                  )
                        }
                        onChange={handleTipoServicioHogarChange}
                    ></Select>
                </FormGroup>
                <FormGroup>
                    <label>Fecha de Servicio</label>
                    <TextField
                        className="form-control"
                        type="date"
                        name="FechaSolicitud"
                        value={fechaServicio}
                        onChange={handleFechaSolicitudChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <label>Ruta de traslado:</label>
                    <input
                        className="form-control"
                        name="rutaTraslado"
                        type="text"
                        value={rutaTraslado}
                        onChange={handleRutaTrasladoChange}
                    />
                </FormGroup>
                <FormGroup>
                    <label>Hora de la cita:</label>
                    <br />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                            value={horaCita ? horaCita : horaCitaInicial}
                            onChange={handleTimeChange}
                        />
                    </LocalizationProvider>
                </FormGroup>

                <FormGroup>
                    <label>Hora de salida:</label>
                    <br />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                            value={horaSalida ? horaSalida : horaSalidaInicial}
                            onChange={handleTimeEndChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </FormGroup>
                <FormGroup>
                    <label>observaciones:</label>
                    <input
                        className="form-control"
                        name="observaciones"
                        type="text"
                        value={observaciones}
                        onChange={handleObservacionesChange}
                    />
                </FormGroup>
            </ModalBody>

            <ModalFooter>
                <Button
                    onClick={handleEditSubmit}
                    color="primary"
                    otype="submit"
                    disabled={isSubmitting}
                >
                    {isSubmitting ? "Actualizando" : "Editar"}
                </Button>
                <Button color="danger">
                    Cancelar
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalEdit;
