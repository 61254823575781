import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button } from "reactstrap";
import { Helmet } from "react-helmet";
import Spinner from "../../Spinner/spinner";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import ModalCreate from "./ModalCreate";
import ModalEdit from "./ModalEdit";
import { localeEs } from "../../locale/ag-grid-es";
import BreadCrum from "../../breadCrum/breadCrum";
import withTokenCheck from "../../withTokenCheck";
import Swal from "sweetalert2";

const AppTarifas = ({ token }) => {
    const [form, setFormt] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [modalEditcontent, setModalEditContent] = useState({});
    const [modalTittleContent, setModalTittleContent] = useState({});
    const [consultClicked, setConsultClicked] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [horaSalidaMasivo, setHoraSalidaMasivo] = useState("");
    const gridApi = useRef(null);
    const etiquetas = [
        "Inicio Lossman",
        "Tablas Referencia",
        "Tarifas Lossman",
    ];

    useEffect(() => {
        if (token) {
            setLoading(true);
            axios
                .get("/tarifasLossman", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    console.log(response.data, "info");
                    setFormt(response.data);
                    setConsultClicked(true);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const handleButtonClick = (content) => {
        setModalContent(content);
        handleModalToggle();
    };

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        editable: true,
    };

    function formatTarifaHospedaje(element) {
        const tarifaHospedaje = element.data.TarifaHospedaje
            ? element.data.TarifaHospedaje
            : "No existe Tarifa";

        const tarifaFormateada =
            "$ " + Intl.NumberFormat().format(tarifaHospedaje);
        return tarifaFormateada;
    }

    function formatTarifaAlimentacion(element) {
        const tarifaAlimentacion = element.data.TarifaAlimentacion
            ? element.data.TarifaAlimentacion
            : "No existe Tarifa";

        const tarifaFormateada =
            "$ " + Intl.NumberFormat().format(tarifaAlimentacion);
        return tarifaFormateada;
    }

    function formatTarifaTransporte(element) {
        const tarifaTransporte = element.data.TarifaTransporte
            ? element.data.TarifaTransporte
            : "No existe Tarifa";

        const tarifaFormateada =
            "$ " + Intl.NumberFormat().format(tarifaTransporte);
        return tarifaFormateada;
    }

    function formatTarifaAlimentacionEspecial(element) {
        const tarifaAlimentacionEspecial = element.data
            .TarifaRecargoAlimentacion
            ? element.data.TarifaRecargoAlimentacion
            : "No existe Tarifa";

        const tarifaFormateada =
            "$ " + Intl.NumberFormat().format(tarifaAlimentacionEspecial);
        return tarifaFormateada;
    }

    function formatTarifaAislamiento(element) {
        const tarifaAislamiento = element.data.TarifaRecargoAislamiento
            ? element.data.TarifaRecargoAislamiento
            : "No existe Tarifa";

        const tarifaFormateada =
            "$ " + Intl.NumberFormat().format(tarifaAislamiento);
        return tarifaFormateada;
    }

    const columnDefs = [
        {
            headerName: "Editar",
            cellRenderer: (params) => (
                <Button
                    color="secondary"
                    onClick={() => handleEditClick(params.data)}
                >
                    Editar
                </Button>
            ),
        },
        {
            headerName: "Eliminar",
            cellRenderer: (element) => (
                <Button
                    color="secondary"
                    onClick={() => handleDeleteClick(element.data)}
                >
                    Eliminar
                </Button>
            ),
        },
        { headerName: "Tipo de solicitud", field: "solicitud.Texto" },
        {
            headerName: "Fecha Vigencia desde",
            field: "VigenciaDesde",
        },
        {
            headerName: "Fecha Vigencia hasta",
            field: "VigenciaHasta",
        },
        {
            headerName: "Tarifa Hospedaje por Noche",
            field: "TarifaHospedaje",
            valueFormatter: formatTarifaHospedaje,
        },
        {
            headerName: "Tarifa de alimentación diaria",
            field: "TarifaHospedaje",
            valueFormatter: formatTarifaAlimentacion,
        },
        {
            headerName: "Tarifa de transporte por trayecto",
            field: "TarifaTransporte",
            valueFormatter: formatTarifaTransporte,
        },
        {
            headerName: "Recargo Alimentación especial",
            field: "TarifaRecargoAlimentacion",
            valueFormatter: formatTarifaAlimentacionEspecial,
        },
        {
            headerName: "Recargo Aislamiento",
            field: "TarifaRecargoAislamiento",
            valueFormatter: formatTarifaAislamiento,
        },
    ];

    const handleDeleteClick = (tarifaToDelete) => {
        console.log(tarifaToDelete, "tarifa delete");
        axios
            .delete(`/tarifasLossman/${tarifaToDelete.id}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                setFormt(form.filter((form) => form.id !== tarifaToDelete.id));
                Swal.fire({
                    icon: "success",
                    title: "Eliminado!",
                    text: "las Tarifas han sido eliminadas con exito",
                    showCloseButton: true,
                }).then(() => {
                    handleUpdateTable();
                });
            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "Ha ocurrido un error al eliminar las tarifas.",
                });
            });
    };

    const handleModalToggle = () => {
        setModalOpen(!modalOpen);
    };
    const handleEditModalToggle = () => setModalEditOpen(!modalEditOpen);

    const handleEditClick = (element) => {
        setModalEditContent(element);
        setModalTittleContent({
            title: "Editar Tarifas",
            footer: (
                <Button
                    className="mb-3"
                    color="secondary"
                    onClick={handleEditModalToggle}
                >
                    Cancelar
                </Button>
            ),
        });
        handleEditModalToggle();
    };

    const handleExportCSV = () => {
        gridApi.current.exportDataAsCsv();
    };

    const onGridReady = (params) => {
        gridApi.current = params.api;
    };

    const handleUpdateTable = () => {
        if (token) {
            setLoading(true);
            axios
                .get("/tarifasLossman", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setFormt(response.data);
                    setConsultClicked(true);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    return (
        <>
            <Helmet>
                <title>Tarifas</title>
            </Helmet>
            <div style={{ position: "relative" }}>
                {loading && <Spinner />}
                <div>
                    <BreadCrum etiquetas={etiquetas} />
                </div>
                <div style={{ opacity: loading ? 0.5 : 1 }}>
                    <div className="container-fluid mt-5">
                        <Button
                            color="primary"
                            onClick={() =>
                                handleButtonClick({
                                    title: "Insertar Tarifas",
                                    footer: (
                                        <Button
                                            className="mb-3"
                                            color="secondary"
                                            onClick={handleModalToggle}
                                        >
                                            Cancelar
                                        </Button>
                                    ),
                                })
                            }
                        >
                            Insertar Tarifas
                        </Button>

                        <Button
                            style={{ marginLeft: "10px" }}
                            color="success"
                            onClick={handleExportCSV}
                        >
                            Exportar CSV (Excel)
                        </Button>
                        <div
                            className="mt-3"
                            style={{ height: "500px", width: "100%" }}
                        >
                            <div
                                className="ag-theme-alpine"
                                style={{ height: "100%", width: "100%" }}
                            >
                                <AgGridReact
                                    rowData={form}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    rowSelection={"multiple"}
                                    pagination={true}
                                    onGridReady={onGridReady}
                                    localeText={localeEs}
                                    paginationPageSize={20}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalCreate
                isOpen={modalOpen}
                toggle={handleModalToggle}
                infoModal={modalContent}
                token={token}
                updateTable={handleUpdateTable}
            />
            <ModalEdit
                isOpen={modalEditOpen}
                toggle={handleEditModalToggle}
                infoModal={modalEditcontent}
                token={token}
                updateTable={handleUpdateTable}
                title={modalEditcontent.title}
            />
        </>
    );
};

export default withTokenCheck(AppTarifas);
