import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import "./styles.css";
import { event } from "jquery";

const ModalEdit = ({
  isOpen,
  toggle,
  title,
  id,
  Apellido1,
  Apellido1Acom1,
  Apellido1Acom2,
  Apellido2,
  Apellido2Acom1,
  Apellido2Acom2,
  Ciudad,
  CiudadOrigen,
  CodigoServicio,
  Descripcion,
  DescripcionCubrimiento,
  Direccion,
  Edad,
  Edad1Acompanante,
  Edad2Acompanante,
  FechaNacimiento,
  FechaNacimiento1Acom,
  FechaNacimiento2acom,
  FechaConsulta1,
  FechaConsulta2,
  FechaConsulta3,
  FechaConsulta4,
  FechaConsulta5,
  FechaInicialServicio,
  FechaFinalServicio,
  FechaSolicitud,
  IPS1,
  IPS2,
  IPS3,
  IPS4,
  IPS5,
  Nombre1,
  Nombre1Acom1,
  Nombre1Acom2,
  Nombre2,
  Nombre2Acom1,
  Nombre2Acom2,
  NumeroCaso,
  NumeroDocumento,
  NumeroDocumenAcom1,
  NumeroDocumenAcom2,
  NumeroMIPRES,
  Observaciones,
  Prestador,
  TelefonoMovil,
  TelefonosContacto,
  TipoAdmision1,
  TipoAdmision2,
  TipoAdmision3,
  TipoAdmision4,
  TipoAdmision5,
  TipoDocumento,
  TipoDocumentoAcompanante1,
  TipoDocumentoAcompanante2,
  TipoServicio1,
  TipoServicio2,
  TipoServicio3,
  TipoServicio4,
  TipoServicio5,
  TipoSolicitud,
  token,
}) => {
  const [apellido1, setApellido1] = useState("");
  const [apellido1Acom1, setApellido1Acom1] = useState("");
  const [apellido1Acom2, setApellido1Acom2] = useState("");
  const [apellido2, setApellido2] = useState("");
  const [apellido2Acom1, setApellido2Acom1] = useState("");
  const [apellido2Acom2, setApellido2Acom2] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [ciudadOrigen, setCiudadOrigen] = useState("");
  const [codigoServicio, setCodigoServicio] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [descripcionCubrimiento, setDescripcionCubrimiento] = useState("");
  const [edad, setEdad] = useState("");
  const [edadAcom1, setEdadAcom1] = useState("");
  const [edadAcom2, setEdadAcom2] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [fechaNacimientoAcom1, setFechaNacimientoAcom1] = useState("");
  const [fechaNacimientoAcom2, setFechaNacimientoAcom2] = useState("");
  const [fechaConsulta1, setFechaConsulta1] = useState("");
  const [fechaConsulta2, setFechaConsulta2] = useState("");
  const [fechaConsulta3, setFechaConsulta3] = useState("");
  const [fechaConsulta4, setFechaConsulta4] = useState("");
  const [fechaConsulta5, setFechaConsulta5] = useState("");
  const [fechaInicialServicio, setFechaInicialServicio] = useState("");
  const [fechaFinalServicio, setFechaFinalServicio] = useState("");
  const [fechaSolicitud, setFechaSolitcitud] = useState("");
  const [ips1, setIps1] = useState("");
  const [ips2, setIps2] = useState("");
  const [ips3, setIps3] = useState("");
  const [ips4, setIps4] = useState("");
  const [ips5, setIps5] = useState("");
  const [nombre1, setNombre1] = useState("");
  const [nombre1Acom1, setNombre1Acom1] = useState("");
  const [nombre1Acom2, setNombre1Acom2] = useState("");
  const [nombre2, setNombre2] = useState("");
  const [nombre2Acom1, setNombre2Acom1] = useState("");
  const [nombre2Acom2, setNombre2Acom2] = useState("");
  const [numeroCaso, setNumeroCaso] = useState("");
  const [numeroDocumento, setNumeroDocumento] = useState("");
  const [numeroDocumenAcom1, setNumeroDocumenAcom1] = useState("");
  const [numeroDocumenAcom2, setNumeroDocumenAcom2] = useState("");
  const [numeroMipres, setNumeroMipres] = useState("");
  const [observaciones, setObervaciones] = useState("");
  const [telMovil, setTelMovil] = useState("");
  const [telContacto, setTelContacto] = useState("");
  const [tipoDocumento, setTipoDocumento] = useState("");
  const [tipoAdmision1, setTipoAdmision1] = useState("");
  const [tipoAdmision2, setTipoAdmision2] = useState("");
  const [tipoAdmision3, setTipoAdmision3] = useState("");
  const [tipoAdmision4, setTipoAdmision4] = useState("");
  const [tipoAdmision5, setTipoAdmision5] = useState("");
  const [tipoServicio1, setTipoServicio1] = useState("");
  const [tipoServicio2, setTipoServicio2] = useState("");
  const [tipoServicio3, setTipoServicio3] = useState("");
  const [tipoServicio4, setTipoServicio4] = useState("");
  const [tipoServicio5, setTipoServicio5] = useState("");
  const [tipoSolicitud, setTipoSolicitud] = useState("");
  const [prestador, setPrestador] = useState("");
  const [direccion, setDireccion] = useState("");
  const [tipoDocumenAcom1, setTipoDocumentoAcom1] = useState("");
  const [tipoDocumenAcom2, setTipoDocumentoAcom2] = useState("");
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);
  useEffect(() => {
    setApellido1(Apellido1);
    setApellido1Acom1(Apellido1Acom1);
    setApellido1Acom2(Apellido1Acom2);
    setApellido2(Apellido2);
    setApellido2Acom1(Apellido2Acom1);
    setApellido2Acom2(Apellido2Acom2);
    setCiudad(Ciudad);
    setCiudadOrigen(CiudadOrigen);
    setCodigoServicio(CodigoServicio);
    setEdad(Edad);
    setEdadAcom1(Edad1Acompanante);
    setEdadAcom2(Edad2Acompanante);
    setDescripcion(Descripcion);
    setDescripcionCubrimiento(DescripcionCubrimiento);
    setFechaConsulta1(FechaConsulta1);
    setFechaConsulta2(FechaConsulta2);
    setFechaConsulta3(FechaConsulta3);
    setFechaConsulta4(FechaConsulta4);
    setFechaConsulta5(FechaConsulta5);
    setFechaNacimiento(FechaNacimiento);
    setFechaNacimientoAcom1(FechaNacimiento1Acom);
    setFechaNacimientoAcom2(FechaNacimiento2acom);
    setFechaInicialServicio(FechaInicialServicio);
    setFechaFinalServicio(FechaFinalServicio);
    setFechaSolitcitud(FechaSolicitud);
    setIps1(IPS1);
    setIps2(IPS2);
    setIps3(IPS3);
    setIps4(IPS4);
    setIps5(IPS5);
    setNombre1(Nombre1);
    setNombre1Acom1(Nombre1Acom1);
    setNombre1Acom2(Nombre1Acom2);
    setNombre2(Nombre2);
    setNombre2Acom1(Nombre2Acom1);
    setNombre2Acom2(Nombre2Acom2);
    setNumeroCaso(NumeroCaso);
    setNumeroDocumento(NumeroDocumento);
    setNumeroDocumenAcom1(NumeroDocumenAcom1);
    setNumeroDocumenAcom2(NumeroDocumenAcom2);
    setNumeroMipres(NumeroMIPRES);
    setObervaciones(Observaciones);
    setTelMovil(TelefonoMovil);
    setTelContacto(TelefonosContacto);
    setTipoDocumento(TipoDocumento);
    setTipoDocumentoAcom1(TipoDocumentoAcompanante1);
    setTipoDocumentoAcom2(TipoDocumentoAcompanante2);
    setTipoServicio1(TipoServicio1);
    setTipoAdmision1(TipoAdmision1);
    setTipoAdmision2(TipoAdmision2);
    setTipoAdmision3(TipoAdmision3);
    setTipoAdmision4(TipoAdmision4);
    setTipoAdmision5(TipoAdmision5);
    setTipoServicio2(TipoServicio2);
    setTipoServicio3(TipoServicio3);
    setTipoServicio4(TipoServicio4);
    setTipoServicio5(TipoServicio5);
    setTipoSolicitud(TipoSolicitud);
    setPrestador(Prestador);
    setDireccion(Direccion);
  }, [
    Apellido1,
    Apellido1Acom1,
    Apellido1Acom2,
    Apellido2,
    Apellido2Acom1,
    Apellido2Acom2,
    Ciudad,
    CiudadOrigen,
    CodigoServicio,
    Descripcion,
    Edad,
    Edad1Acompanante,
    Edad2Acompanante,
    FechaNacimiento,
    FechaNacimiento1Acom,
    FechaNacimiento2acom,
    FechaConsulta1,
    FechaConsulta2,
    FechaConsulta3,
    FechaConsulta4,
    FechaConsulta5,
    FechaInicialServicio,
    FechaFinalServicio,
    Nombre1,
    Nombre1Acom1,
    Nombre1Acom2,
    Nombre2,
    Nombre2Acom1,
    Nombre2Acom2,
    NumeroCaso,
    NumeroDocumenAcom2,
    NumeroMIPRES,
    Observaciones,
    TipoDocumento,
    TelefonoMovil,
    TelefonosContacto,
    TipoAdmision1,
    TipoAdmision2,
    TipoAdmision3,
    TipoAdmision4,
    TipoAdmision5,
    TipoServicio1,
    TipoServicio2,
    TipoServicio3,
    TipoServicio4,
    TipoServicio5,
    TipoSolicitud,
    TipoDocumentoAcompanante1,
    TipoDocumentoAcompanante2,
  ]);

  const handleApellido1Change = (event) => {
    setApellido1(event.target.value);
  };
  const handleApellido1Acom1Change = (event) => {
    setApellido1Acom1(event.target.value);
  };
  const handleApellido1Acom2Change = (event) => {
    setApellido1Acom2(event.target.value);
  };
  const handleApellido2Change = (event) => {
    setApellido2(event.target.value);
  };
  const handleApellido2Acom1Change = (event) => {
    setApellido2Acom1(event.target.value);
  };
  const handleApellido2Acom2Change = (event) => {
    setApellido2Acom2(event.target.value);
  };
  const handleCiudadOrigenChange = (event) => {
    setCiudadOrigen(event.target.value);
  };
  const handleCodigoServicioChange = (event) => {
    setCodigoServicio(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescripcion(event.target.value);
  };
  const handleDescripcionCubrimientoChange = (event) => {
    setDescripcionCubrimiento(event.target.value);
  };
  const handleFechaConsulta1Change = (event) => {
    setFechaConsulta1(event.target.value);
  };
  const handleFechaConsulta2Change = (event) => {
    setFechaConsulta2(event.target.value);
  };
  const handleFechaConsulta3Change = (event) => {
    setFechaConsulta3(event.target.value);
  };
  const handleFechaConsulta4Change = (event) => {
    setFechaConsulta4(event.target.value);
  };
  const handleFechaConsulta5Change = (event) => {
    setFechaConsulta5(event.target.value);
  };
  const handleFechaFinalChange = (event) => {
    setFechaFinalServicio(event.target.value);
  };
  const handlePrestadorChange = (event) => {
    setPrestador(event.target.value);
  };
  const handleDireccionChange = (event) => {
    setDireccion(event.target.value);
  };
  const handleTelContactoChange = (event) => {
    setTelContacto(event.target.value);
  };
  const handleCiudadChange = (event) => {
    setCiudad(event.target.value);
  };
  const handleFechaInicialChange = (event) => {
    setFechaInicialServicio(event.target.value);
  };
  const handleFechaSolicitudChange = (event) => {
    setFechaSolitcitud(event.target.value);
  };
  const handleIPS1Change = (event) => {
    setIps1(event.target.value);
  };
  const handleIPS2Change = (event) => {
    setIps2(event.target.value);
  };
  const handleIPS3Change = (event) => {
    setIps3(event.target.value);
  };
  const handleIPS4Change = (event) => {
    setIps4(event.target.value);
  };
  const handleIPS5Change = (event) => {
    setIps5(event.target.value);
  };
  const handleNombre1Change = (event) => {
    setNombre1(event.target.value);
  };
  const handleNombre1Acom1Change = (event) => {
    setNombre1Acom1(event.target.value);
  };
  const handleNombre1Acom2Change = (event) => {
    setNombre1Acom2(event.target.value);
  };
  const handleNombre2Change = (event) => {
    setNombre2(event.target.value);
  };
  const handleNombre2Acom1Change = (event) => {
    setNombre2Acom1(event.target.value);
  };
  const handleNombre2Acom2Change = (event) => {
    setNombre2Acom2(event.target.value);
  };
  const handleNumeroCasoChange = (event) => {
    setNumeroCaso(event.target.value);
  };
  const handleNumeroDocumenChange = (event) => {
    setNumeroDocumento(event.target.value);
  };
  const handleNumeroMovilChange = (event) => {
    setTelMovil(event.target.value);
  };
  const handleEdadChange = (event) => {
    setEdad(event.target.value);
  };
  const handleFechaNacimientoChange = (event) => {
    setFechaNacimiento(event.target.value);
  };
  const handleFechaNacimientoAcom1Change = (event) => {
    setFechaNacimientoAcom1(event.target.value);
  };
  const handleFechaNacimientoAcom2Change = (event) => {
    setFechaNacimientoAcom2(event.target.value);
  };
  const handleNumeroDocumenAcom1Change = (event) => {
    setNumeroDocumenAcom1(event.target.value);
  };
  const handleTipoDocumenAcom1Change = (event) => {
    setTipoDocumentoAcom1(event.target.value);
  };
  const handleTipoDocumenAcom2Change = (event) => {
    setTipoDocumentoAcom2(event.target.value);
  };
  const handleEdadAcom1Change = (event) => {
    setEdadAcom1(event.target.value);
  };
  const handleNumeroDocumenAcom2Change = (event) => {
    setNumeroDocumenAcom2(event.target.value);
  };
  const handleEdadAcom2Change = (event) => {
    setEdadAcom2(event.target.value);
  };
  const handleNumeroMipresChange = (event) => {
    setNumeroMipres(event.target.value);
  };
  const handleObservacionesChange = (event) => {
    setObervaciones(event.target.value);
  };
  const handleTipoAdmisionChange = (event) => {
    setTipoAdmision1(event.target.value);
  };
  const handleTipoAdmision2Change = (event) => {
    setTipoAdmision2(event.target.value);
  };
  const handleTipoAdmision3Change = (event) => {
    setTipoAdmision3(event.target.value);
  };
  const handleTipoAdmision4Change = (event) => {
    setTipoAdmision4(event.target.value);
  };
  const handleTipoAdmision5Change = (event) => {
    setTipoAdmision5(event.target.value);
  };
  const handleTipoServicio1Change = (event) => {
    setTipoServicio1(event.target.value);
  };
  const handleTipoServicio2Change = (event) => {
    setTipoServicio2(event.target.value);
  };
  const handleTipoServicio3Change = (event) => {
    setTipoServicio3(event.target.value);
  };
  const handleTipoServicio4Change = (event) => {
    setTipoServicio4(event.target.value);
  };
  const handleTipoServicio5Change = (event) => {
    setTipoServicio5(event.target.value);
  };
  const handleTipoSolicitudChange = (event) => {
    setTipoSolicitud(event.target.value);
  };
  const handleTipoDocumentoChange = (event) => {
    setTipoDocumento(event.target.value);
  };
  const handleEditSubmit = (event) => {
    event.preventDefault();
    axios
      .put(
        `/formularioautomatico/${id}`,
        {
          Apellido1: apellido1,
          Apellido1Acompanante1: apellido1Acom1,
          Apellido1Acompanante2: apellido1Acom2,
          Apellido2: apellido2,
          Apellido2Acompanante1: apellido2Acom1,
          Apellido2Acompanante2: apellido2Acom2,
          CiudadOrigen: ciudadOrigen,
          Prestador: prestador,
          Ciudad: ciudad,
          Direccion: direccion,
          CodigoServicio: codigoServicio,
          Descripcion: descripcion,
          DescripcionCubrimiento: descripcionCubrimiento,
          Edad: edad,
          EdadAcompanante1: edadAcom1,
          EdadAcompanante2: edadAcom2,
          FechaNacimiento: fechaNacimiento,
          FechaNacimientoAcompanante1: fechaNacimientoAcom1,
          FechaNacimientoAcompanante2: fechaNacimientoAcom2,
          FechaConsulta1: fechaConsulta1,
          FechaConsulta2: fechaConsulta2,
          FechaConsulta3: fechaConsulta3,
          FechaConsulta4: fechaConsulta4,
          FechaConsulta5: fechaConsulta5,
          FechaFinalServicio: fechaFinalServicio,
          FechaInicialServivio: fechaInicialServicio,
          FechaSolicitud: fechaSolicitud,
          IPS1: ips1,
          IPS2: ips2,
          IPS3: ips3,
          IPS4: ips4,
          IPS5: ips5,
          Nombre1: nombre1,
          Nombre1Acompanante1: nombre1Acom1,
          Nombre1Acompanante2: nombre1Acom2,
          Nombre2: nombre2,
          Nombre2Acompanante1: nombre2Acom1,
          Nombre2Acompanante2: nombre2Acom2,
          NumeroCaso: numeroCaso,
          NumeroDocumento: numeroDocumento,
          NumeroDocumentoAcompanante1: numeroDocumenAcom1,
          NumeroDocumentoAcompanante2: numeroDocumenAcom2,
          NumeroMIPRES: numeroMipres,
          Observaciones: observaciones,
          TelefonoMovil: telMovil,
          TelefonosContacto: telContacto,
          TipoAdmision1: tipoAdmision1,
          TipoAdmision2: tipoAdmision2,
          TipoAdmision3: tipoAdmision3,
          TipoAdmision4: tipoAdmision4,
          TipoAdmision5: tipoAdmision5,
          TipoDocumento: tipoDocumento,
          TipoDocumentoAcompanante1: tipoDocumenAcom1,
          TipoDocumentoAcompanante2: tipoDocumenAcom2,
          TipoServicio1: tipoServicio1,
          TipoServicio2: tipoServicio2,
          TipoServicio3: tipoServicio3,
          TipoServicio4: tipoServicio4,
          TipoServicio5: tipoServicio5,
          TipoSolicitud: tipoSolicitud,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Actualizado!",
          text: "El formulario a sido actualizado con exito.",
          showCloseButton: true,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "El formulario no se pudo actualizar",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal className="modalEdit" ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <div className="modalEdicion">
          <div className="columna">
            <FormGroup>
              <label>Primer Nombre Paciente:</label>
              <input
                className="form-control"
                name="PrimerNombre"
                type="text"
                value={nombre1}
                onChange={handleNombre1Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Segundo Nombre Paciente:</label>
              <input
                className="form-control"
                name="SegundoNombre"
                type="text"
                value={nombre2}
                onChange={handleNombre2Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Primer Apellido Paciente:</label>
              <input
                className="form-control"
                name="PrimerApellido"
                type="text"
                value={apellido1}
                onChange={handleApellido1Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Segundo Apellido Paciente:</label>
              <input
                className="form-control"
                name="SegundoApellido"
                type="text"
                value={apellido2}
                onChange={handleApellido2Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Tipo de Documento:</label>
              <input
                className="form-control"
                name="TipoDocumento"
                type="text"
                value={tipoDocumento}
                onChange={handleTipoDocumentoChange}
              />
            </FormGroup>
            <FormGroup>
              <label>Numero de Documento:</label>
              <input
                className="form-control"
                name="NumeroDocumento"
                type="text"
                value={numeroDocumento}
                onChange={handleNumeroDocumenChange}
              />
            </FormGroup>
            <FormGroup>
              <label>Numero de contacto:</label>
              <input
                className="form-control"
                name="numeroContacto"
                type="text"
                value={telMovil}
                onChange={handleNumeroMovilChange}
              />
            </FormGroup>
            <FormGroup>
              <label>Fecha Nacimiento:</label>
              <input
                className="form-control"
                name="fechaNacimiento"
                type="text"
                value={fechaNacimiento}
                onChange={handleFechaNacimientoChange}
              />
            </FormGroup>
            <FormGroup>
              <label>Edad:</label>
              <input
                className="form-control"
                name="edad"
                type="text"
                value={edad}
                onChange={handleEdadChange}
              />
            </FormGroup>
            <FormGroup>
              <label>Ciudad de Origen:</label>
              <input
                className="form-control"
                name="ciudadOrigen"
                type="text"
                value={ciudadOrigen}
                onChange={handleCiudadOrigenChange}
              />
            </FormGroup>
            <FormGroup>
              <label>Fecha Solicitud:</label>
              <input
                className="form-control"
                name="fechaSolicitud"
                type="text"
                value={fechaSolicitud}
                onChange={handleFechaSolicitudChange}
              />
            </FormGroup>
            <FormGroup>
              <label>Numero de Caso:</label>
              <input
                className="form-control"
                name="numeroCaso"
                type="text"
                value={numeroCaso}
                onChange={handleNumeroCasoChange}
              />
            </FormGroup>
            <FormGroup>
              <label>Fecha Inicial del servicio:</label>
              <input
                className="form-control"
                name="fechaInicial"
                type="text"
                value={fechaInicialServicio}
                onChange={handleFechaInicialChange}
              />
            </FormGroup>
            <FormGroup>
              <label>Fecha Final del Servicio:</label>
              <input
                className="form-control"
                name="fechaFinal"
                type="text"
                value={fechaFinalServicio}
                onChange={handleFechaFinalChange}
              />
            </FormGroup>
            <FormGroup>
              <label>Prestador:</label>
              <input
                className="form-control"
                name="prestador"
                type="text"
                value={prestador}
                onChange={handlePrestadorChange}
              />
            </FormGroup>
            <FormGroup>
              <label>Dirección:</label>
              <input
                className="form-control"
                name="direccion"
                type="text"
                value={direccion}
                onChange={handleDireccionChange}
              />
            </FormGroup>
            <FormGroup>
              <label>Telefono Contacto:</label>
              <input
                className="form-control"
                name="telContacto"
                type="text"
                value={telContacto}
                onChange={handleTelContactoChange}
              />
            </FormGroup>
            <FormGroup>
              <label>Ciudad Prestador:</label>
              <input
                className="form-control"
                name="ciudad"
                type="text"
                value={ciudad}
                onChange={handleCiudadChange}
              />
            </FormGroup>
            <FormGroup>
              <label>Numero MIPRES:</label>
              <input
                className="form-control"
                name="numeroMIORES"
                type="text"
                value={numeroMipres}
                onChange={handleNumeroMipresChange}
              />
            </FormGroup>
            <FormGroup>
              <label>Tipo de Solicitud:</label>
              <input
                className="form-control"
                name="tipoSolicitud"
                type="text"
                value={tipoSolicitud}
                onChange={handleTipoSolicitudChange}
              />
            </FormGroup>
            <FormGroup>
              <label>Tipo de Servicio:</label>
              <input
                className="form-control"
                name="tipoServicio"
                type="text"
                value={descripcionCubrimiento}
                onChange={handleDescripcionCubrimientoChange}
              />
            </FormGroup>
            <FormGroup>
              <label>Codigo de Servicio:</label>
              <input
                className="form-control"
                name="codigoServicio"
                type="text"
                value={codigoServicio}
                onChange={handleCodigoServicioChange}
              />
            </FormGroup>
            <FormGroup>
              <label>Descripción de Servicio:</label>
              <input
                className="form-control"
                name="descricpion"
                type="text"
                value={descripcion}
                onChange={handleDescriptionChange}
              />
            </FormGroup>

            <FormGroup>
              <label>Primer Servicio:</label>
              <input
                className="form-control"
                name="PrimerServicio"
                type="text"
                value={TipoServicio1}
                onChange={handleTipoServicio1Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Fecha Consulta Primer Servicio:</label>
              <input
                className="form-control"
                name="fechaPrimerServicio"
                type="text"
                value={fechaConsulta1}
                onChange={handleFechaConsulta1Change}
              />
            </FormGroup>
            <FormGroup>
              <label>IPS Primer Servicio:</label>
              <input
                className="form-control"
                name="ipsPrimerServicio"
                type="text"
                value={IPS1}
                onChange={handleIPS1Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Tipo Admision Primer Servicio:</label>
              <input
                className="form-control"
                name="tipoAdmisionPrimerServicio"
                type="text"
                value={tipoAdmision1}
                onChange={handleTipoAdmisionChange}
              />
            </FormGroup>
            <FormGroup>
              <label>Segundo Servicio:</label>
              <input
                className="form-control"
                name="segundoServicio"
                type="text"
                value={TipoServicio2}
                onChange={handleTipoServicio2Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Fecha Consulta Segundo Servicio:</label>
              <input
                className="form-control"
                name="fechaSegundoServicio"
                type="text"
                value={fechaConsulta2}
                onChange={handleFechaConsulta2Change}
              />
            </FormGroup>
            <FormGroup>
              <label>IPS Segundo Servicio:</label>
              <input
                className="form-control"
                name="ipsSegundoServicio"
                type="text"
                value={IPS2}
                onChange={handleIPS2Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Tipo Admision Segundo Servicio:</label>
              <input
                className="form-control"
                name="tipoAdmisionSegundoServicio"
                type="text"
                value={tipoAdmision2}
                onChange={handleTipoAdmision2Change}
              />
            </FormGroup>
          </div>
          <div className="columna">
            <FormGroup>
              <label>Tercer Servicio:</label>
              <input
                className="form-control"
                name="TercerServicio"
                type="text"
                value={TipoServicio3}
                onChange={handleTipoServicio3Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Fecha Consulta Tercer Servicio:</label>
              <input
                className="form-control"
                name="fechaTercerServicio"
                type="text"
                value={fechaConsulta3}
                onChange={handleFechaConsulta3Change}
              />
            </FormGroup>
            <FormGroup>
              <label>IPS Tercer Servicio:</label>
              <input
                className="form-control"
                name="ipsPrimerServicio"
                type="text"
                value={IPS3}
                onChange={handleIPS3Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Tipo Admision Tercer Servicio:</label>
              <input
                className="form-control"
                name="tipoAdmisionTercerServicio"
                type="text"
                value={tipoAdmision3}
                onChange={handleTipoAdmision3Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Cuarto Servicio:</label>
              <input
                className="form-control"
                name="CuartoServicio"
                type="text"
                value={TipoServicio4}
                onChange={handleTipoServicio4Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Fecha Consulta Cuarto Servicio:</label>
              <input
                className="form-control"
                name="fechaCuartoServicio"
                type="text"
                value={fechaConsulta4}
                onChange={handleFechaConsulta4Change}
              />
            </FormGroup>
            <FormGroup>
              <label>IPS Cuarto Servicio:</label>
              <input
                className="form-control"
                name="ipsPrimerServicio"
                type="text"
                value={IPS4}
                onChange={handleIPS4Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Tipo Admision Cuarto Servicio:</label>
              <input
                className="form-control"
                name="tipoAdmisionCuartoServicio"
                type="text"
                value={tipoAdmision4}
                onChange={handleTipoAdmision4Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Quinto Servicio:</label>
              <input
                className="form-control"
                name="QuintoServicio"
                type="text"
                value={TipoServicio5}
                onChange={handleTipoServicio5Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Fecha Consulta Quinto Servicio:</label>
              <input
                className="form-control"
                name="fechaQuintoServicio"
                type="text"
                value={fechaConsulta5}
                onChange={handleFechaConsulta5Change}
              />
            </FormGroup>
            <FormGroup>
              <label>IPS Quinto Servicio:</label>
              <input
                className="form-control"
                name="ipsQuintoServicio"
                type="text"
                value={IPS5}
                onChange={handleIPS5Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Tipo Admision Quinto Servicio:</label>
              <input
                className="form-control"
                name="tipoAdmisionQuintoServicio"
                type="text"
                value={tipoAdmision5}
                onChange={handleTipoAdmision5Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Primer Nombre Primer Acompañante:</label>
              <input
                className="form-control"
                name="PrimerNombreACom"
                type="text"
                value={nombre1Acom1}
                onChange={handleNombre1Acom1Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Segundo Nombre Primer ACompañante:</label>
              <input
                className="form-control"
                name="SegundoNombreACom1"
                type="text"
                value={nombre2Acom1}
                onChange={handleNombre2Acom1Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Primer Apellido Primer Acompañante:</label>
              <input
                className="form-control"
                name="PrimerApellidoAcom1"
                type="text"
                value={apellido1Acom1}
                onChange={handleApellido1Acom1Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Segundo Apellido Primer Acompañante:</label>
              <input
                className="form-control"
                name="SegundoApellido"
                type="text"
                value={apellido2Acom1}
                onChange={handleApellido2Acom1Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Tipo de Documento Primer Acompañante:</label>
              <input
                className="form-control"
                name="tipoDocumenAcom1"
                type="text"
                value={tipoDocumenAcom1}
                onChange={handleTipoDocumenAcom1Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Numero de Documento Primer Acompañante:</label>
              <input
                className="form-control"
                name="NumeroDocumentoACom1"
                type="text"
                value={numeroDocumenAcom1}
                onChange={handleNumeroDocumenAcom1Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Fecha Nacimiento Primer Acompañante:</label>
              <input
                className="form-control"
                name="fechaNacimientoAcom1"
                type="text"
                value={fechaNacimientoAcom1}
                onChange={handleFechaNacimientoAcom1Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Edad Primer Acompañante:</label>
              <input
                className="form-control"
                name="edadAcom1"
                type="text"
                value={edadAcom1}
                onChange={handleEdadAcom1Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Primer Nombre Segundo Acompañante:</label>
              <input
                className="form-control"
                name="PrimerNombreACom2"
                type="text"
                value={nombre1Acom2}
                onChange={handleNombre1Acom2Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Segundo Nombre Segundo ACompañante:</label>
              <input
                className="form-control"
                name="SegundoNombreACom2"
                type="text"
                value={nombre2Acom2}
                onChange={handleNombre2Acom2Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Primer Apellido Segundo Acompañante:</label>
              <input
                className="form-control"
                name="PrimerApellidoAcom2"
                type="text"
                value={apellido1Acom2}
                onChange={handleApellido1Acom2Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Segundo Apellido Segundo Acompañante:</label>
              <input
                className="form-control"
                name="SegundoApellido"
                type="text"
                value={apellido2Acom2}
                onChange={handleApellido2Acom2Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Tipo de Documento SEgundo Acompañante:</label>
              <input
                className="form-control"
                name="tipoDocumenAcom1"
                type="text"
                value={tipoDocumenAcom2}
                onChange={handleTipoDocumenAcom2Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Numero de Documento Segundo Acompañante:</label>
              <input
                className="form-control"
                name="NumeroDocumentoACom2"
                type="text"
                value={numeroDocumenAcom2}
                onChange={handleNumeroDocumenAcom2Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Fecha Nacimiento Segundo Acompañante:</label>
              <input
                className="form-control"
                name="fechaNacimientoAcom2"
                type="text"
                value={fechaNacimientoAcom2}
                onChange={handleFechaNacimientoAcom2Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Edad Segundo Acompañante:</label>
              <input
                className="form-control"
                name="edadAcom2"
                type="text"
                value={edadAcom2}
                onChange={handleEdadAcom2Change}
              />
            </FormGroup>
            <FormGroup>
              <label>Observaciones:</label>
              <input
                className="form-control"
                name="Observaciones"
                type="text"
                value={observaciones}
                onChange={handleObservacionesChange}
              />
            </FormGroup>
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleEditSubmit} color="primary" otype="submit">
          Editar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalEdit;
