import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";

const ModalChangeDate = ({
    isOpen,
    toggle,
    title,
    token,
    updateTable,
    FormId,
    FechaIni,
    FechaFin,
}) => {
    const [formData, setFormData] = useState({
        fechaInicial: "",
        fechaFinal: "",
    });
    const modalRef = useRef(null);

    const handleChange = (field, value) => {
        setFormData({
            ...formData,
            [field]: value,
        });
    };

    const handleUpdatedDate = (event) => {
        event.preventDefault();

        // Enviar los datos de ls IPs
        axios
            .put(
                `/formulario/actualizar-fechas/${FormId}`,
                {
                    FechaInicial: formData.fechaInicial
                        ? formData.fechaInicial
                        : FechaIni,
                    FechaFinal: formData.fechaFinal
                        ? formData.fechaFinal
                        : FechaFin,
                },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: "Creado!",
                    text: "Las fechas han sido actualizadas con exito",
                    showCloseButton: true,
                }).then((result) => {
                    updateTable();
                    toggle();
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "Ha ocurrido un error al actualizar las fechas.",
                    showCloseButton: true,
                });
            });
    };

    return (
        <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <label>Fecha Inicial:</label>
                    <input
                        className="form-control"
                        name="fechaInicial"
                        type="date"
                        onChange={(event) =>
                            handleChange("fechaInicial", event.target.value)
                        }
                        value={
                            formData.fechaInicial
                                ? formData.fechaInicial
                                : FechaIni
                        }
                    />
                </FormGroup>
                <FormGroup>
                    <label>Fecha Final:</label>
                    <input
                        className="form-control"
                        name="fechaFinal"
                        type="date"
                        onChange={(event) =>
                            handleChange("fechaFinal", event.target.value)
                        }
                        value={
                            formData.fechaFinal ? formData.fechaFinal : FechaFin
                        }
                    />
                </FormGroup>
            </ModalBody>

            <ModalFooter>
                <Button
                    onClick={handleUpdatedDate}
                    color="primary"
                    otype="submit"
                >
                    Actualizar
                </Button>
                <Button color="danger" onClick={toggle}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalChangeDate;
