import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button } from "reactstrap";
import { Helmet } from "react-helmet";
import Spinner from "../../Spinner/spinner";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import ModalChangeDate from "./ModalChangeDate";
import { localeEs } from "../../locale/ag-grid-es";
import BreadCrum from "../../breadCrum/breadCrum";
import withTokenCheck from "../../withTokenCheck";

const AppChangeDate = ({ token }) => {
    const [date, setDate] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [modalTittleContent, setModalTittleContent] = useState({});
    const gridApi = useRef(null);
    const etiquetas = [
        "Inicio Lossman",
        "Cambios De Evento",
        "Modificar Fechas De Estadia",
    ];

    const handleModalToggle = () => setModalOpen(!modalOpen);

    useEffect(() => {
        if (token) {
            axios
                .get("/formulario/consulta/fechas", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setDate(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        editable: true,
    };

    const columnDefs = [
        {
            headerName: "Editar",
            cellRenderer: (params) => (
                <Button
                    color="secondary"
                    onClick={() => handleEditClick(params.data)}
                >
                    Editar
                </Button>
            ),
        },
        { headerName: "Numero Caso", field: "NumeroCaso" },
        { headerName: "Paciente", field: "paciente.Nombre1" },
        { headerName: "Fecha Inicial", field: "FechaInicial" },
        { headerName: "Fecha Final", field: "FechaFinal" },
        { headerName: "Usuario Creación", field: "created_by.name" },
        { headerName: "Fecha Creación", field: "created_at" },
        { headerName: "Usuario edición", field: "updated_by.name" },
        { headerName: "Fecha Edición", field: "updated_at" },
    ];

    const handleEditClick = (element) => {
        setModalContent(element);
        setModalTittleContent({
            title: "Actualizar Fechas",
            footer: (
                <Button
                    className="mb-3"
                    color="secondary"
                    onClick={handleModalToggle}
                >
                    Cancelar
                </Button>
            ),
        });
        handleModalToggle();
    };

    const handleExportCSV = () => {
        gridApi.current.exportDataAsCsv();
    };

    const onGridReady = (params) => {
        gridApi.current = params.api;
    };

    const handleUpdateTable = () => {
        if (token) {
            setLoading(true);
            axios
                .get("/formulario/consulta/fechas", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setDate(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    return (
        <>
            <Helmet>
                <title>Actualizar Fechas</title>
            </Helmet>
            <div style={{ position: "relative" }}>
                {loading && <Spinner />}
                <div>
                    <BreadCrum etiquetas={etiquetas} />
                </div>
                <div style={{ opacity: loading ? 0.5 : 1 }}>
                    <div className="container-fluid mt-5">
                        <Button color="success" onClick={handleExportCSV}>
                            Exportar CSV (Excel)
                        </Button>
                        <div
                            className="mt-3"
                            style={{ height: "500px", width: "100%" }}
                        >
                            <div
                                className="ag-theme-alpine"
                                style={{ height: "100%", width: "100%" }}
                            >
                                <AgGridReact
                                    rowData={date}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    pagination={true}
                                    onGridReady={onGridReady}
                                    localeText={localeEs}
                                    paginationPageSize={20}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalChangeDate
                isOpen={modalOpen}
                toggle={handleModalToggle}
                title={modalContent.title}
                content={modalContent.content}
                footer={modalContent.footer}
                token={token}
                updateTable={handleUpdateTable}
                FormId={modalContent.id}
                FechaIni={modalContent.FechaInicial}
                FechaFin={modalContent.FechaFinal}
            />
        </>
    );
};

export default withTokenCheck(AppChangeDate);
