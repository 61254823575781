import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import * as jzip from "jszip";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "reactstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Helmet } from "react-helmet";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import BreadCrum from "../../breadCrum/breadCrum";
import withTokenCheck from "../../withTokenCheck";

window.JSZip = jzip;

const AppReport = ({ token }) => {
    const [form, setForm] = useState([]);
    const currentDate = dayjs();
    const [dateStart, setDateStart] = useState(currentDate);
    const [dateEnd, setDateEnd] = useState(currentDate);
    const tableRef = useRef(null);
    const etiquetas = [
        "Inicio Lossman",
        "Reportes",
        "Reporte De Casos Eliminados Del Sistema",
    ];

    const handleConsult = (event) => {
        event.preventDefault();
        if (token) {
            const params = {
                FechaInicio: dateStart.format("YYYY-MM-DD"),
                FechaFinal: dateEnd.format("YYYY-MM-DD"),
            };
            axios
                .get("/formulario/consulta/eliminados", {
                    headers: { Authorization: `Bearer ${token}` },
                    params,
                })
                .then((response) => {
                    console.log(response.data);
                    setForm(response.data);
                    // Inicializa Datatables en la tabla
                    $("#myTable").DataTable();

                    // Agrega las filas a la tabla
                    const dataTable = $("#myTable").DataTable();
                    dataTable.clear().draw();
                    response.data.forEach((row) => {
                        dataTable.row.add([
                            row.paciente.Nombre1 +
                                " " +
                                (row.paciente.Nombre2
                                    ? row.paciente.Nombre2
                                    : " ") +
                                " " +
                                row.paciente.Apellido1 +
                                " " +
                                (row.paciente.Apellido2
                                    ? row.paciente.Apellido2
                                    : " "),
                            row.FechaSolicitud,
                            row.NumeroCaso,
                            row.FechaInicial,
                            row.FechaFinal,
                            row.motivoEliminacion,
                            row.deleted_by
                                ? row.deleted_by.name
                                : "No existe información",
                            row.deleted_at
                                ? row.deleted_at
                                : "No existe información",
                        ]);
                    });
                    dataTable.draw();
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    useEffect(() => {
        $(document).ready(() => {
            $(tableRef.current).DataTable({
                paging: true,
                ordering: true,
                info: true,
                dom: "<'row'<'col-sm-4'l><'col-sm-4 text-center'B><'col-sm-4'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-6 text-right'i><'col-sm-6'p>>",
                language: {
                    url: "//cdn.datatables.net/plug-ins/1.13.1/i18n/es-ES.json",
                },
                buttons: {
                    dom: {
                        button: {
                            tag: "button",
                            className: "btn",
                        },
                    },
                    buttons: [
                        {
                            extend: "excel",
                            className: "btn-success",
                            text: "<i class='far fa-file-excel'></i> Excel",
                            extension: ".xlsx",
                        },
                    ],
                },
                /* buttons: ["copy", "csv", "excel", "pdf", "print"], */
            });
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>Reporte casos eliminados</title>
            </Helmet>
            <div style={{ maxWidth: "1200px", margin: "50px auto" }}>
                <div>
                    <BreadCrum etiquetas={etiquetas} />
                </div>
                <div style={{ width: "100%", overflowX: "auto" }}>
                    <div className="ConsultReport">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={2} sx={{ minWidth: 305 }}>
                                <DatePicker
                                    id="dateStart"
                                    label="Fecha Inical Desde"
                                    value={dateStart}
                                    onChange={setDateStart}
                                />
                            </Stack>
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={2} sx={{ minWidth: 305 }}>
                                <DatePicker
                                    label="Fecha Inicial Hasta"
                                    value={dateEnd}
                                    onChange={setDateEnd}
                                />
                            </Stack>
                        </LocalizationProvider>
                        <Button
                            id="buttonConsult"
                            className="mb-3"
                            color="secondary"
                            onClick={handleConsult}
                        >
                            Consultar
                        </Button>
                    </div>
                    <table
                        ref={tableRef}
                        id="myTable"
                        className="table"
                        style={{ width: "100%" }}
                    >
                        <thead>
                            <tr>
                                <th>Nombre Paciente</th>
                                <th>Fecha de Solicitud</th>
                                <th>Numero de Caso</th>
                                <th>Fecha Inicial</th>
                                <th>Fecha Final</th>
                                <th>Observaciones</th>
                                <th>Usuario eliminación</th>
                                <th>Fecha eliminación</th>
                                ...
                            </tr>
                        </thead>
                        <tbody>{}</tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default withTokenCheck(AppReport);
