import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, FormGroup, Form } from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { IconButton, SvgIcon } from "@material-ui/core";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { v4 as uuid } from "uuid";
import { TextField } from "@material-ui/core";
import FormHelperText from "@mui/material/FormHelperText";
import DeleteIcon from "@mui/icons-material/Delete";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControlLabel } from "@mui/material";
import withTokenCheck from "../withTokenCheck";

const AppForm = ({ token }) => {
    const [fechaSolicitud, setFechaSolicitud] = useState("");
    const [numeroCaso, setNumeroCaso] = useState("");
    const [descripcionCubrimiento, setDescripcionCubrimiento] = useState([]);
    const [selectedDescripcionCubrimiento, setSelectedDescripcionCubrimiento] =
        useState("");
    const [
        selectedDescripcionCubrimiento2,
        setSelectedDescripcionCubrimiento2,
    ] = useState("");
    const [tipoSolicitud, setTipoSolicitud] = useState([]);
    const [selectedTipoSolicitud, setSelectedTipoSolicitud] = useState("");
    const [selectedTipoSolicitud2, setSelectedTipoSolicitud2] = useState("");
    const [descripcionCodigo, setDescripcionCodigo] = useState([]);
    const [selectedDescripcion, setSelectedDescripcion] = useState("");
    const [selectedDescripcion2, setSelectedDescripcion2] = useState("");
    const [fechaInicial, setFechaInicial] = useState("");
    const [fechaFinal, setFechaFinal] = useState("");
    const [hogar, setHogar] = useState([]);
    const [selectedHogar, setSelectedHogar] = useState("");
    const [selectedHogar2, setSelectedHogar2] = useState("");
    const [direccion, setDireccion] = useState("");
    const [telContact, setTelContact] = useState("");
    const [ciudad, setCiudad] = useState([]);
    const [selectedCiudad, setSelectedCiudad] = useState("");
    const [selectedCiudad2, setSelectedCiudad2] = useState("");
    const [paciente, setPaciente] = useState([]);
    const [selectedPaciente, setSelectedPaciente] = useState("");
    const [selectedPaciente2, setSelectedPaciente2] = useState("");
    const [nombre1Paciente, setNombre1Paciente] = useState("");
    const [nombre2Paciente, setNombre2Paciente] = useState("");
    const [apellido1Paciente, setApellido1Paciente] = useState("");
    const [apellido2Paciente, setApellido2Paciente] = useState("");
    const [tipoDocumento, setTipoDocumento] = useState([]);
    const [selectedTipoDocumento, setSelectedTipoDocumento] = useState("");
    const [selectedTipoDocumento2, setSelectedTipoDocumento2] = useState("");
    const [numeroIdentificacion, setNumeroIndentificacion] = useState("");
    const [fechaNacimiento, setFechaNacimiento] = useState("");
    const [edad, setEdad] = useState("");
    const [telPaciente, setTelPaciente] = useState("");
    const [numeroMipres, setNumeroMipres] = useState("");
    const [ciudadOrigen, setCiudadOrigen] = useState([]);
    const [selectedCiudadOrigen, setSelectedCiudadOrigen] = useState("");
    const [selectedCiudadOrigen2, setSelectedCiudadOrigen2] = useState("");
    const [tipoServicio, setTipoServicio] = useState([]);
    const [ips, setIps] = useState([]);
    const [tipoAdmision, setTipoAdmision] = useState([]);
    const [codigoServicio, setCodigoServicio] = useState("");
    const [numeroDiasHospedaje, setNumeroDiasHospedaje] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [tipoAcompanante, setTipoAcompanante] = useState([]);
    const [acompanante, setAcompanante] = useState([]);
    const [habilitado, setHabilitado] = useState("");
    const [motivo, setMotivo] = useState("");
    const [tipoDocumentoAcom, setTipoDocumentoAcom] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [caracteristicasEspeciales, setCaracteristicasEspeciales] = useState(
        []
    );
    const [checkedState, setCheckedState] = useState({});
    const [observaciones, setObservaciones] = useState("");
    const [infoOpciones, setInfoOpciones] = useState({});
    const [infoIps, setInfoIps] = useState({});
    const [infoTipoAdmision, setInfoTipoAdmision] = useState({});
    const [numInputsAcom, setNumInputsAcom] = useState(1);
    const [inputsAcom, setInputsAcom] = useState([
        {
            id: uuid(),
            acompanante: "",
            tipoAcompanante: "",
            apellido1Acompanante: "",
            apellido2Acompanante: "",
            nombre1Acompanante: "",
            nombre2Acompanante: "",
            tipoDocumentoAcom: "",
            numeroIdentificacionAcom: "",
            fechaNacimientoAcom: "",
        },
    ]);
    const [numInputs, setNumInputs] = useState(1);
    const [inputs, setInputs] = useState([
        {
            id: uuid(),
            tipoServicio: "",
            Ips: "",
            tipoAdmision: "",
            fechaConsulta: "",
        },
    ]);

    function agregarInput() {
        if (numInputs < 5) {
            setNumInputs(numInputs + 1);
            setInputs([
                ...inputs,
                {
                    id: uuid(),
                    tipoServicio: "",
                    Ips: "",
                    tipoAdmision: "",
                    fechaConsulta: "",
                },
            ]);
        }
    }

    function cancelarInputs(index) {
        if (numInputs > 0) {
            setNumInputs(numInputs - 1);
            const newInputs = [...inputs];
            newInputs.splice(index, 1);
            setInputs(newInputs);
        }
    }

    function handleTipoServicioChange(selectedOption, index) {
        const list = inputs.map((input) =>
            input.id === index
                ? { ...input, tipoServicio: selectedOption.value }
                : input
        );
        setInputs(list);

        const opcionSeleccionada = tipoServicio.find(
            (o) => o.id === selectedOption.value
        );
        if (opcionSeleccionada) {
            setInfoOpciones((prevInfoOpciones) => ({
                ...prevInfoOpciones,
                [opcionSeleccionada.id]: opcionSeleccionada.Texto,
            }));
        } else {
            setInfoOpciones((prevInfoOpciones) => ({
                ...prevInfoOpciones,
                [selectedOption.value]: "",
            }));
        }
    }

    function handleIpsChange(selectedOption, index) {
        const list = inputs.map((input) =>
            input.id === index ? { ...input, Ips: selectedOption.value } : input
        );
        setInputs(list);

        const opcionSeleccionada = ips.find(
            (o) => o.id === selectedOption.value
        );
        if (opcionSeleccionada) {
            setInfoIps((prevInfoIps) => ({
                ...prevInfoIps,
                [opcionSeleccionada.id]: opcionSeleccionada.Texto,
            }));
        } else {
            setInfoIps((prevInfoIps) => ({
                ...prevInfoIps,
                [selectedOption.value]: "",
            }));
        }
    }
    function handleTipoAdmisionChange(selectedOption, index) {
        const list = inputs.map((input) =>
            input.id === index
                ? { ...input, tipoAdmision: selectedOption.value }
                : input
        );
        setInputs(list);

        const opcionSeleccionada = tipoAdmision.find(
            (o) => o.id === selectedOption.value
        );
        if (opcionSeleccionada) {
            setInfoTipoAdmision((prevInfoOpciones) => ({
                ...prevInfoOpciones,
                [opcionSeleccionada.id]: opcionSeleccionada.Texto,
            }));
        } else {
            setInfoTipoAdmision((prevInfoOpciones) => ({
                ...prevInfoOpciones,
                [selectedOption.value]: "",
            }));
        }
    }

    function handleInputChange(selectedOption, index, name2) {
        const name = name2;
        var value;
        if (selectedOption.target) {
            value = selectedOption.target.value;
        } else {
            value = selectedOption.value;
        }
        const list = inputs.map((input) =>
            input.id === index ? { ...input, [name]: value } : input
        );
        setInputs(list);
    }

    function agregarInputAcompanante() {
        if (numInputsAcom < 7) {
            setNumInputsAcom(numInputsAcom + 1);
            setInputsAcom([
                ...inputsAcom,
                {
                    id: uuid(),
                    acompanante: "",
                    tipoAcompanante: "",
                    apellido1Acompanante: "",
                    apellido2Acompanante: "",
                    nombre1Acompanante: "",
                    nombre2Acompanante: "",
                    tipoDocumentoAcom: "",
                    numeroIdentificacionAcom: "",
                    fechaNacimientoAcom: "",
                },
            ]);
        }
    }

    function cancelarInputsAcompanante(index) {
        if (numInputsAcom > 0) {
            setNumInputsAcom(numInputsAcom - 1);
            const newInputs = [...inputsAcom];
            newInputs.splice(index, 1);
            setInputsAcom(newInputs);
        }
    }

    function handleInputAcompananteChange(selectedOption, inputId) {
        const label = selectedOption ? selectedOption.label : "";
        const list = inputsAcom.map((input) =>
            input.id === inputId
                ? {
                      ...input,
                      acompanante: selectedOption.value,
                      acompananteLabel: label,
                  }
                : input
        );
        setInputsAcom(list);
    }

    const hogarOptions = hogar.map((option) => ({
        value: option.id,
        label: option.Nombre ?? option.Nombre,
    }));

    const ciudadOptions = ciudad.map((option) => ({
        value: option.id,
        label: option.Texto ?? option.Texto,
    }));

    const descripcionOptions = descripcionCubrimiento.map((option) => ({
        value: option.id,
        label: option.Texto ?? option.Texto,
    }));

    const solicitudOptions = tipoSolicitud.map((option) => ({
        value: option.id,
        label: option.Texto ?? option.Texto,
    }));

    useEffect(() => {
        if (token) {
          axios
            .get("/codigoDescripcion", { headers: { Authorization: `Bearer ${token}` } })
            .then((response) => {
              setDescripcionCodigo(response.data);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }, [token]);
    
      const codigoDescripcionOptions = descripcionCodigo.map((option) => ({
        value: option.id,
        label: option.CodigoServicio ?? option.CodigoServicio,
      }));

    const pacienteOptions = paciente.map((option) => ({
        value: option.id,
        label:
            option.NumeroIdent +
                " " +
                option.Nombre1 +
                " " +
                option.Apellido1 ??
            option.NumeroIdent + " " + option.Nombre1 + " " + option.Apellido1,
    }));

    const tipoDocumentoOptions = tipoDocumento.map((option) => ({
        value: option.id,
        label: option.Texto ?? option.Texto,
    }));

    const servicioOptions = tipoServicio.map((option) => ({
        value: option.id,
        label: option.Texto ?? option.Texto,
    }));

    const ipsOptions = ips.map((option) => ({
        value: option.id,
        label: option.Texto ?? option.Texto,
    }));

    const admisionOptions = tipoAdmision.map((option) => ({
        value: option.id,
        label: option.Texto ?? option.Texto,
    }));

    const acompananteOptions = acompanante.map((option) => ({
        value: option.id,
        label:
            option.NumeroIdent +
                " " +
                option.Nombre1 +
                " " +
                option.Apellido1 ??
            option.NumeroIdent + " " + option.Nombre1 + " " + option.Apellido1,
    }));

    // Obtener todas las ciudades al cargar el componente
    useEffect(() => {
        if (token) {
            axios
                .get("/tiposolicitud", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setTipoSolicitud(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            axios
                .get("/hogar", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setHogar(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            axios
                .get("/paciente", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setPaciente(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            axios
                .get("/tipodocumento", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setTipoDocumento(response.data);
                    setTipoDocumentoAcom(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            axios
                .get("/ips", { headers: { Authorization: `Bearer ${token}` } })
                .then((response) => {
                    setIps(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            axios
                .get("/tiposervicio", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setTipoServicio(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            axios
                .get("/tipoadmision", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setTipoAdmision(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            axios
                .get("/ciudad", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setCiudad(response.data);
                    setCiudadOrigen(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            axios
                .get("/tipoacompanante", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setTipoAcompanante(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            axios
                .get("/descripcion", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setDescripcionCubrimiento(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            axios
                .get("/condicionesespeciales", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setCaracteristicasEspeciales(response.data);
                    const initialState = response.data.reduce((acc, item) => {
                        return { ...acc, [item.id]: false };
                    }, {});
                    setCheckedState(initialState);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    // Manejar el envío del formulario para crear una nueva ciudad
    const handleCreateSubmit = (event) => {
        setIsSubmitting(true);
        event.preventDefault();
        axios
            .post(
                "/formulario",
                {
                    FechaSolicitud: fechaSolicitud,
                    NumeroCaso: parseInt(numeroCaso),
                    DescripcionId: parseInt(selectedDescripcionCubrimiento2),
                    TipoSolicitudId: parseInt(selectedTipoSolicitud2),
                    FechaInicial: fechaInicial,
                    FechaFinal: fechaFinal,
                    HogarId: parseInt(selectedHogar2),
                    PacienteId: parseInt(selectedPaciente2),
                    NumeroMIPRES: numeroMipres,
                    tipoServicios: inputs,
                    CodigoServicio: codigoServicio,
                    Descripcion: descripcion,
                    Acompanantes: inputsAcom,
                    CasosEspeciales: checkedState,
                    Observaciones: observaciones,
                    CodigoDescripcionId: selectedDescripcion2,
                    DiasHospedaje: numeroDiasHospedaje,
                },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: "Guardado!",
                    text: "La autorización ha sido guardada con exito.",
                    showCloseButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    } else if (result.isDenied) {
                        Swal.fire("Changes are not saved", "", "info");
                    }
                });
            })

            .catch((error) => {
                console.log(error);
                var errorMessage = "";
                for (var key in error.response.data.errors) {
                    errorMessage += error.response.data.errors[key] + "<br>";
                }
                Swal.fire({
                    icon: "error",
                    title: error.response.data.message,
                    html: errorMessage,
                });
                setIsSubmitting(false);
            });
    };

    // Manejar el cambio en el campo de descripción
    const handleNumeroCasoChange = (event) => {
        setNumeroCaso(event.target.value);
        if (token) {
            if (event.target.value && event.target.value.length >= 6) {
                axios
                    .get(`/formulario/numeroCaso/${event.target.value}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then((response) => {
                        Swal.fire({
                            icon: "error",
                            title: "Error!",
                            text: "El numero de caso ingresado ya esta registrado en sistema",
                            showCloseButton: true,
                        });
                    })
                    .catch((error) => {});
            }
        }
    };
    const handleFechaSolicitudChange = (event) => {
        setFechaSolicitud(event.target.value);
    };
    const handleDiasHospedajeChange = (event) => {
        setNumeroDiasHospedaje(event.target.value);
    };
    const handleDescripcionCubrimientoChange = (selectedOption) => {
        setSelectedDescripcionCubrimiento2(selectedOption.value);
        setSelectedDescripcionCubrimiento(
            descripcionOptions.find(
                (option) => option.value === selectedOption.value
            )
        );
    };
    const handleCodigoDescripcionCubrimientoChange = (selectedOption) => {
        setSelectedDescripcion2(selectedOption.value);
        setSelectedDescripcion(
            descripcionOptions.find(
                (option) => option.value === selectedOption.value
            )
        );
    };
    const handleTipoSolicitudChange = (selectedOption) => {
        setSelectedTipoSolicitud2(selectedOption.value);
        setSelectedTipoSolicitud(
            solicitudOptions.find(
                (option) => option.value === selectedOption.value
            )
        );
    };
    const handleFechaInicialChange = (event) => {
        setFechaInicial(event.target.value);
    };
    const handleFechaFinalChange = (event) => {
        setFechaFinal(event.target.value);
    };
    const handleHogarChange = (selectedOption) => {
        setSelectedHogar2(selectedOption.value);
        setSelectedHogar(
            hogarOptions.find((option) => option.value === selectedOption.value)
        );
        if (token) {
            axios
                .get(`/hogar/${selectedOption.value}`, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setSelectedCiudad(
                        ciudadOptions.find(
                            (option) => option.value === response.data.CiudadId
                        )
                    );
                    setSelectedCiudad2(response.data.CiudadId);
                    setDireccion(response.data.Direccion);
                    setTelContact(response.data.NumeroContacto);
                })

                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const handlePacienteChange = (selectedOption) => {
        setSelectedPaciente2(selectedOption.value);
        setSelectedPaciente(
            pacienteOptions.find(
                (option) => option.value === selectedOption.value
            )
        );
        if (token) {
            axios
                .get(`/paciente/${selectedOption.value}`, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setHabilitado(response.data.Habilitado);
                    setMotivo(response.data.MotivoDeshabilitacion);
                    if (response.data.Habilitado == 0) {
                        Swal.fire({
                            title: "Este paciente se encuentra bloqueado en el hogar de paso",
                            text: `Motivo de bloqueo: ${motivo}`,
                            text: "En caso de dudas por favor contactarse con Administración para desbloquear el cliente",
                            confirmButtonText: "Aceptar",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.reload();
                            }
                        });
                    }
                    setNombre1Paciente(response.data.Nombre1);
                    setNombre2Paciente(response.data.Nombre2);
                    setApellido1Paciente(response.data.Apellido1);
                    setApellido2Paciente(response.data.Apellido2);
                    setSelectedTipoDocumento(
                        tipoDocumentoOptions.find(
                            (option) =>
                                option.value === response.data.TipoDocumentoId
                        )
                    );
                    setSelectedTipoDocumento2(response.data.TipoDocumentoId);
                    setNumeroIndentificacion(response.data.NumeroIdent);
                    setFechaNacimiento(response.data.FechaNacimiento);
                    setTelPaciente(response.data.TelContacto);
                    setSelectedCiudadOrigen(
                        ciudadOptions.find(
                            (option) =>
                                option.value === response.data.CiudadOrigenId
                        )
                    );
                    setSelectedCiudadOrigen2(response.data.CiudadOrigenId);
                    setEdad(response.data.edad);
                    if (token) {
                        axios
                            .get(
                                `acompanante/paciente/${selectedOption.value}`,
                                {
                                    headers: {
                                        Authorization: `Bearer ${token}`,
                                    },
                                }
                            )
                            .then((response) => {
                                setAcompanante(response.data);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }
                })

                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const handleNumeroMipresChange = (event) => {
        setNumeroMipres(event.target.value);
    };

    const handleCodigoServicioChange = (event) => {
        setCodigoServicio(event.target.value);
    };
    const handleDescripcionChange = (event) => {
        setDescripcion(event.target.value);
    };

    const handleObservacionesChange = (event) => {
        setObservaciones(event.target.value);
    };

    const handleOnChange = (id) => {
        setCheckedState((prevState) => {
            const updatedState = { ...prevState, [id]: !prevState[id] };
            return updatedState;
        });
    };
    useEffect(() => {}, [checkedState]);

    // Manejar el clic en el botón "Crear"

    return (
        <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Form onSubmit={handleCreateSubmit}>
                <Divider className="mt-4">
                    <Chip label="Solicitud" />
                </Divider>
                <Box>
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="input-group input-group-sm  d-flex justify-content-center align-items-center">
                            <FormGroup className="col-md-4 mt-3 align-items-center">
                                <TextField
                                    label="Fecha de Solicitud"
                                    className="form-control"
                                    type="date"
                                    name="FechaSolicitud"
                                    value={fechaSolicitud}
                                    onChange={handleFechaSolicitudChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="col-md-4 mt-3 align-items-center">
                                <TextField
                                    label="Numero de Caso"
                                    className="form-control "
                                    type="Number"
                                    name="NumeroCaso"
                                    value={numeroCaso || ""}
                                    onChange={handleNumeroCasoChange}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="input-group input-group-sm mb-2 d-flex justify-content-around">
                            <FormControl size="small" className="col-md">
                                <FormGroup>
                                    <label htmlFor="descripcionId">
                                        Codigo Descripcion:
                                    </label>
                                    <Select
                                        options={codigoDescripcionOptions}
                                        onChange={
                                            handleCodigoDescripcionCubrimientoChange
                                        }
                                    ></Select>
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md">
                                <FormGroup>
                                    <label htmlFor="descripcionId">
                                        Descripción Cubrimiento:
                                    </label>
                                    <Select
                                        options={descripcionOptions}
                                        onChange={
                                            handleDescripcionCubrimientoChange
                                        }
                                    ></Select>
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md">
                                <FormGroup className="ml-1">
                                    <label htmlFor="solicitudId">
                                        Tipo de solicitud
                                    </label>
                                    <Select
                                        options={solicitudOptions}
                                        onChange={handleTipoSolicitudChange}
                                    ></Select>
                                </FormGroup>
                            </FormControl>
                            
                        </div>
                        <div className="input-group input-group-sm mb-2 d-flex justify-content-around">
                        <FormControl size="small" className="col-md">
                                <FormGroup>
                                    <TextField
                                        label="Fecha Inicial Servicio"
                                        className="form-control "
                                        type="Date"
                                        name="FechaIncial"
                                        value={fechaInicial}
                                        onChange={handleFechaInicialChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl className="col-md">
                                <FormGroup>
                                    <TextField
                                        label="Fecha Final Del Servicio"
                                        className="form-control "
                                        type="Date"
                                        name="FechaFinal"
                                        value={fechaFinal}
                                        onChange={handleFechaFinalChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-4">
                                <FormGroup>
                                    <TextField
                                        label="Cantidad de días de hospedaje"
                                        className="form-control "
                                        type="Number"
                                        name="diasHospedaje"
                                        value={numeroDiasHospedaje}
                                        onChange={handleDiasHospedajeChange}
                                    />
                                </FormGroup>
                            </FormControl>
                        </div>
                    </div>
                </Box>
                <Divider className="mb-4">
                    <Chip label="Prestador" />
                </Divider>
                <Box>
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="input-group input-group-sm mb-2 d-flex justify-content-center align-items-center">
                            <FormControl size="small" className="col-md-4 ">
                                <FormGroup>
                                    <label htmlFor="hogarId">Hogar:</label>
                                    <Select
                                        value={selectedHogar}
                                        options={hogarOptions}
                                        onChange={handleHogarChange}
                                    ></Select>
                                </FormGroup>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="input-group input-group-sm mb-2 d-flex justify-content-center align-items-center">
                            <FormControl size="small" className="col-md-4">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Telefonos de Contacto"
                                        className="form-control "
                                        type="text"
                                        name="TelContacto"
                                        value={telContact}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-4">
                                <FormGroup>
                                    <label htmlFor="ciudadId">Ciudad:</label>
                                    <Select
                                        isDisabled
                                        options={ciudadOptions}
                                        value={selectedCiudad}
                                    ></Select>
                                </FormGroup>
                            </FormControl>
                        </div>
                    </div>
                </Box>
                <Divider className="mb-4">
                    <Chip label="Paciente" />
                </Divider>
                <Box>
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="input-group input-group-sm mb-2 d-flex justify-content-center align-items-center">
                            <FormControl size="small" className="col-md-10">
                                <FormGroup>
                                    <label htmlFor="solicitudId">
                                        Seleccionar Paciente por Numero de
                                        Identificacion:
                                    </label>
                                    {
                                        <Select
                                            options={pacienteOptions}
                                            onChange={handlePacienteChange}
                                        ></Select>
                                    }
                                </FormGroup>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row d-flex align-items-center justify-content-center flex-wrap">
                        <div className="input-group input-group-sm mb-2 d-flex justify-content-center align-items-center">
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Primer Apellido Paciente"
                                        className="form-control "
                                        type="Text"
                                        name="Apellido1Paciente"
                                        value={apellido1Paciente}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Segundo Apellido Paciente"
                                        className="form-control "
                                        type="Text"
                                        name="Apellido2Paciente"
                                        value={apellido2Paciente}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Primer Nombre Paciente"
                                        className="form-control "
                                        type="Text"
                                        name="nombre1Paciente"
                                        value={nombre1Paciente}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Segundo Nombre Paciente"
                                        className="form-control "
                                        type="Text"
                                        name="nombre2Paciente"
                                        value={nombre2Paciente}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <label htmlFor="documentoId">
                                        Tipo de Documento
                                    </label>
                                    <Select
                                        isDisabled
                                        options={tipoDocumentoOptions}
                                        value={selectedTipoDocumento}
                                        label="ciudad"
                                    ></Select>
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Numero de indentificación"
                                        className="form-control "
                                        type="Text"
                                        name="numeroIdentificacion"
                                        value={numeroIdentificacion}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Fecha de Nacimiento"
                                        className="form-control "
                                        type="Date"
                                        name="FechaNacimiento"
                                        value={fechaNacimiento}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Edad"
                                        className="form-control "
                                        type="text"
                                        name="edad"
                                        value={edad}
                                    />
                                </FormGroup>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row d-flex align-items-center justify-content-center flex-wrap">
                        <div className="input-group input-group-sm mb-2 d-flex justify-content-center align-items-center">
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Telefono Contacto Paciente"
                                        className="form-control "
                                        type="Number"
                                        name="TelPaciente"
                                        value={telPaciente}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        label="Numero MIPRES"
                                        className="form-control "
                                        type="Text"
                                        name="NumeroMIPRES"
                                        value={numeroMipres}
                                        onChange={handleNumeroMipresChange}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <label htmlFor="ciudadOrigenId">
                                        Ciudad Origen
                                    </label>
                                    <Select
                                        isDisabled
                                        options={ciudadOptions}
                                        value={selectedCiudadOrigen}
                                        label="ciudad"
                                    ></Select>
                                </FormGroup>
                            </FormControl>
                        </div>
                    </div>
                </Box>
                <Divider className="mb-4">
                    <Chip label="Servicio Discriminado" />
                </Divider>
                <Box>
                    <div className="row d-flex align-items-center justify-content-center flex-wrap">
                        {inputs.map((input, index) => (
                            <div
                                className="input-group mb-2 d-flex"
                                key={input.id}
                            >
                                <FormControl size="small" className="col-md-3">
                                    <FormGroup>
                                        <label htmlFor="tipoServicioId">
                                            Tipo de Servicio
                                        </label>
                                        <Select
                                            value={servicioOptions.find(
                                                (option) =>
                                                    option.value ==
                                                    input.tipoServicio
                                            )}
                                            options={servicioOptions}
                                            onChange={(selectedOption) => {
                                                handleInputChange(
                                                    selectedOption,
                                                    input.id,
                                                    "tipoServicio"
                                                );
                                                handleTipoServicioChange(
                                                    selectedOption,
                                                    input.id
                                                );
                                            }}
                                            label="tipoServicio"
                                            name="tipoServicio"
                                        ></Select>
                                        <div
                                            style={{ wordBreak: "break-word" }}
                                        >
                                            <FormHelperText>
                                                {servicioOptions.find(
                                                    (option) =>
                                                        option.value ===
                                                        input.tipoServicio
                                                )?.label || ""}
                                            </FormHelperText>
                                        </div>
                                    </FormGroup>
                                </FormControl>
                                <FormControl size="small" className="col-md-3">
                                    <FormGroup>
                                        <label htmlFor="ipsId">IPS</label>
                                        <Select
                                            options={ipsOptions}
                                            value={ipsOptions.find(
                                                (option) =>
                                                    option.value == input.Ips
                                            )}
                                            onChange={(selectedOption) => {
                                                handleInputChange(
                                                    selectedOption,
                                                    input.id,
                                                    "Ips"
                                                );
                                                handleIpsChange(
                                                    selectedOption,
                                                    input.id
                                                );
                                            }}
                                            label="Ips"
                                            name="Ips"
                                        ></Select>
                                        <div
                                            style={{ wordBreak: "break-word" }}
                                        >
                                            <div
                                                style={{
                                                    wordBreak: "break-word",
                                                }}
                                            >
                                                <FormHelperText>
                                                    {ipsOptions.find(
                                                        (option) =>
                                                            option.value ===
                                                            input.Ips
                                                    )?.label || ""}
                                                </FormHelperText>
                                            </div>
                                        </div>
                                    </FormGroup>
                                </FormControl>
                                <FormControl size="small" className="col-md-3">
                                    <FormGroup>
                                        <label htmlFor="tipoAdmisionId">
                                            Tipo de Amisión
                                        </label>
                                        <Select
                                            options={admisionOptions}
                                            value={admisionOptions.find(
                                                (option) =>
                                                    option.value ==
                                                    input.tipoAdmision
                                            )}
                                            onChange={(selectedOption) => {
                                                handleInputChange(
                                                    selectedOption,
                                                    input.id,
                                                    "tipoAdmision"
                                                );
                                                handleTipoAdmisionChange(
                                                    selectedOption,
                                                    input.id
                                                );
                                            }}
                                            label="tipoAdmision"
                                            name="tipoAdmision"
                                        ></Select>
                                        {
                                            <FormHelperText>
                                                {admisionOptions.find(
                                                    (option) =>
                                                        option.value ===
                                                        input.tipoAdmision
                                                )?.label || ""}
                                            </FormHelperText>
                                        }
                                    </FormGroup>
                                </FormControl>
                                <FormControl className="col-md-auto">
                                    <FormGroup>
                                        <TextField
                                            label="Fecha de consulta"
                                            className="form-control "
                                            type="Date"
                                            name="fechaConsulta"
                                            value={input.fechaConsulta}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    input.id,
                                                    "fechaConsulta"
                                                )
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormGroup>
                                </FormControl>
                                <FormControl
                                    size="small"
                                    className="col-md-auto"
                                >
                                    <FormGroup>
                                        <IconButton
                                            className="ml-2"
                                            aria-label="delete"
                                            onClick={() =>
                                                cancelarInputs(input.id)
                                            }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </FormGroup>
                                </FormControl>
                            </div>
                        ))}
                        <FormControl className="col-md-3 align-self-center">
                            <Button onClick={agregarInput}>
                                Añadir Tipo Servicio
                            </Button>
                        </FormControl>
                    </div>
                    {/* </div> */}
                    <Divider className="mb-4">
                        <Chip label="Descripcion de servicio" />
                    </Divider>
                </Box>
                <Box>
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="input-group input-group-sm mb-2 d-flex justify-content-center align-items-center">
                            <FormControl size="small" className="col-md-4">
                                <FormGroup>
                                    <TextField
                                        label="Codigo de Servicio"
                                        className="form-control "
                                        type="Text"
                                        name="codigoServicio"
                                        value={codigoServicio}
                                        onChange={handleCodigoServicioChange}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-4">
                                <FormGroup>
                                    <TextField
                                        label="Descripción"
                                        className="form-control "
                                        type="Text"
                                        name="Descripcion"
                                        value={descripcion}
                                        onChange={handleDescripcionChange}
                                    />
                                </FormGroup>
                            </FormControl>
                        </div>
                    </div>
                </Box>
                <Divider className="mb-4">
                    <Chip label="Acompañante" />
                </Divider>
                <Box>
                    <div className="row d-flex align-items-center justify-content-center flex-wrap">
                        <div className="input-group input-group-sm mb-2 d-flex justify-content-center align-items-center">
                            {inputsAcom.map((input, index) => (
                                <div
                                    className="input-group input-group-sm mb-2 d-flex justify-content-center align-items-center"
                                    key={input.id}
                                >
                                    <FormControl
                                        size="small"
                                        className="col-md-10"
                                    >
                                        <FormGroup>
                                            <label htmlFor="acompananteId">
                                                Acompañante
                                            </label>
                                            <Select
                                                options={acompananteOptions}
                                                value={{
                                                    label: input.acompananteLabel,
                                                    value: input.acompanante,
                                                }}
                                                onChange={(selectedOption) =>
                                                    handleInputAcompananteChange(
                                                        selectedOption,
                                                        input.id
                                                    )
                                                }
                                                label="acompanante"
                                                name="acompanante"
                                            ></Select>
                                        </FormGroup>
                                    </FormControl>
                                    <FormControl
                                        size="small"
                                        className="col-md-3"
                                    >
                                        <Button
                                            type="button"
                                            onClick={() =>
                                                cancelarInputsAcompanante(index)
                                            }
                                        >
                                            Remove
                                        </Button>
                                    </FormControl>
                                </div>
                            ))}
                            <FormControl size="small" className="col-md-3">
                                <Button
                                    type="button"
                                    onClick={agregarInputAcompanante}
                                >
                                    Añadir acompañante
                                </Button>
                            </FormControl>
                        </div>
                    </div>
                </Box>
                <Divider className="mb-4">
                    <Chip label="Caracteristicas Especiales" />
                </Divider>
                <div style={{ display: "box", alignItems: "center" }}>
                    <FormControl component="fieldset">
                        <FormGroup>
                            {caracteristicasEspeciales.map((index) => {
                                return (
                                    <FormControlLabel
                                        key={index.id}
                                        control={
                                            <Checkbox
                                                checked={checkedState[index.id]}
                                                onChange={() =>
                                                    handleOnChange(index.id)
                                                }
                                            />
                                        }
                                        label={index.Texto}
                                    />
                                );
                            })}
                        </FormGroup>
                    </FormControl>
                </div>
                <Divider className="mb-4">
                    <Chip label="Observaciones Especiales" />
                </Divider>
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="input-group input-group-sm mb-2 d-flex justify-content-center align-items-center">
                        <FormControl size="small" className="col-md-10">
                            <FormGroup>
                                <TextField
                                    label="Observaciones de necesidades especiales para el hospedaje"
                                    className="form-control "
                                    type="Text"
                                    name="observaciones"
                                    value={observaciones}
                                    onChange={handleObservacionesChange}
                                />
                            </FormGroup>
                        </FormControl>
                    </div>
                </div>

                <Box display="flex" justifyContent="center" alignItems="center">
                    <Button
                    onClick={handleCreateSubmit}
                    color="primary"
                    otype="submit"
                    disabled={isSubmitting}
                    id="Button"
                >
                    {isSubmitting ? "Enviando..." : "Enviar Formulario"}
                </Button>
                </Box>
            </Form>
        </Box>
    );
};

export default withTokenCheck(AppForm);
