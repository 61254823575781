import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import BedSelection from "../../ComponentsUse/Select/selectBed";
import { TextField } from "@material-ui/core";

const ModalCreate = ({ isOpen, toggle, title, formId, pacienteId, token }) => {
  const [acompanantes, setAcompanantes] = useState([]);
  const [selectedAcompanante, setSelectedAcompanante] = useState("");
  const [selectedAcompanante2, setSelectedAcompanante2] = useState("");
  const [acompanantesOptions, setAcompanantesOptions] = useState([]);
  const [numeroDiasAcompanante, setNumeroDiasAcompanante] = useState("");
  const [selectedRoomId, setSelectedRoomId] = useState("");
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);

  useEffect(() => {
    if (token) {
      axios
        .get(`/acompanante/paciente/${pacienteId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setAcompanantes(response.data);
          generateOptions(response.data);
        })
        .catch((error) => {
          console.log(error);
        });

      const generateOptions = (data) => {
        if (data.length > 0) {
          const options = data.map((option) => ({
            value: option.id,
            label:
              option.Nombre1 + " " +
              (option.Nombre2 ? option.Nombre2 : " ") +
              " " +
              option.Apellido1 + " " +
              (option.Apellido2 ? option.Apellido2 : "") +
              " " +
              (option.NumeroIdent ?? option.NumeroIdent),
          }));
          setAcompanantesOptions(options);
        }
      };
    }
  }, [pacienteId, token]);

  const handleAcompananteChange = (selectedOption) => {
    setSelectedAcompanante2(selectedOption.value);
    setSelectedAcompanante(
      acompanantes.find((option) => option.value === selectedOption.value)
    );
  };

  const handleDiasHospedajeChange = (event) => {
    setNumeroDiasAcompanante(event.target.value);
  };
  const handleCreateSubmit = (event) => {
    event.preventDefault();

    // Enviar los datos del paciente y de los acompañantes
    axios
      .post("/edicion-formulario/acompanantes", {
        FormularioId: formId,
        AcompananteId: selectedAcompanante2,
      }, {headers: { Authorization: `Bearer ${token}` }})
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Creado!",
          text: "el acompañante ha sido añadido con exito.",
          showCloseButton: true,
        }).then(() => {
          toggle();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Ha ocurrido un error al añadir el acompañante.",
          showCloseButton: true,
        });
      });

      axios
      .post("/asignacion/acompanante-adicional", {
        FormularioId: formId,
        PersonaId: selectedAcompanante2,
        HabitacionId:selectedRoomId,
        TipoPersonaId: 2,
        DiasHospedajeAcompanante: numeroDiasAcompanante,
      }, {headers: { Authorization: `Bearer ${token}` }})
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Creado!",
          text: "Se ha asignado cama al acompañante exitosamente.",
          showCloseButton: true,
        }).then(() => {
          toggle();
          setNumeroDiasAcompanante("");
          setSelectedRoomId("");
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Se han presentado problemas para asignar cama al acompañante.",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label htmlFor="servicioId">Selecciona acompañante:</label>
          <Select
            value={selectedAcompanante}
            onChange={handleAcompananteChange}
            options={acompanantesOptions}
          ></Select>
        </FormGroup>
        <BedSelection
          token={token}
          value={selectedRoomId}
          onChange={setSelectedRoomId}
        />
        <FormGroup>
          <TextField
              label="Cantidad de días de hospedaje"
              className="form-control "
              type="Number"
              name="diasHospedaje"
              value={numeroDiasAcompanante}
              onChange={handleDiasHospedajeChange}
          />
      </FormGroup>
       
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleCreateSubmit} color="primary" otype="submit">
          Insertar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalCreate;
