import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button } from "reactstrap";
import { Helmet } from "react-helmet";
import Spinner from "../../Spinner/spinner";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import ModalAsignacion from "./ModalAsignacion";
import ModalAsignacionMasiva from "./ModalAsignacionMasiva";
import { localeEs } from "../../locale/ag-grid-es";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import BreadCrum from "../../breadCrum/breadCrum";
import withTokenCheck from "../../withTokenCheck";

import Stack from "@mui/material/Stack";

const AppPaciente = ({ token }) => {
    const [form, setFormt] = useState([]);
    const currentDate = dayjs();
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [modalEditcontent, setModalEditContent] = useState({});
    const [modalTittleContent, setModalTittleContent] = useState({});
    const [dateStart, setDateStart] = useState(currentDate);
    const [dateEnd, setDateEnd] = useState(currentDate);
    const [consultClicked, setConsultClicked] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [horaSalidaMasivo, setHoraSalidaMasivo] = useState("");
    const gridApi = useRef(null);
    const etiquetas = [
        "Inicio Lossman",
        "Cambios De Evento",
        "Asignación De Transporte",
    ];

    useEffect(() => {
        setLoading(false);
    }, []);

    const handleConsult = (event) => {
        event.preventDefault();
        const params = {
            FechaInicio: dateStart.format("YYYY-MM-DD"),
            FechaFinal: dateEnd.format("YYYY-MM-DD"),
        };
        if (token) {
            setLoading(true);
            axios
                .get("/programacionformularios", {
                    headers: { Authorization: `Bearer ${token}` },
                    params,
                })
                .then((response) => {
                    console.log(response.data, "info");
                    setFormt(response.data);
                    setConsultClicked(true);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    function formatNumeroAcompanantes(element) {
        const numAcompanantes = element.data.CantidadAcompanantes
            ? element.data.CantidadAcompanantes
            : "No se solicito transporte para acompañantes";
        return numAcompanantes;
    }

    function formatVehiculos(element) {
        const vehiculo = element.data.VehiculoId
            ? element.data.vehiculo.Placa
            : "No se ha asignado vehiculo";
        return vehiculo;
    }

    function formatConductor(element) {
        const conductor = element.data.ConductorId
            ? element.data.conductor.Nombre
            : "No se ha asignado Conductor";
        return conductor;
    }

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        editable: true,
    };

    const columnDefs = [
        {
            headerName: "Hogar",
            field: "formulario.hogar.Nombre",
            checkboxSelection: true,
        },
        { headerName: "Fecha de servicio", field: "FechaServicio" },
        { headerName: "Tipo de servicio", field: "tipo_servicio_hogar.Texto" },
        {
            headerName: "Nombre del paciente",
            field: "formulario.paciente.Nombre",
        },
        {
            headerName: "Numero de contacto",
            field: "formulario.paciente.TelContacto",
        },
        { headerName: "Ruta de traslado", field: "RutaTraslado" },
        { headerName: "Hora de la cita", field: "HoraCita" },
        { headerName: "Hora de salida", field: "HoraSalida" },
        { headerName: "Observación", field: "Observacion" },
        {
            headerName: "Conductor",
            field: "conductor.Nombre",
            valueFormatter: formatConductor,
        },
        {
            headerName: "Vehiculo",
            field: "vehiculo.Placa",
            valueFormatter: formatVehiculos,
        },
        {
            headerName: "Detalle Transporte Externo",
            field: "TransporteExterno",
        },
        { headerName: "Numero de solicitud", field: "formulario.NumeroCaso" },
        {
            headerName:
                "Numero de Acompañantes a los que se solicito transporte",
            field: "formulario.CantidadAcompanantes",
            valueFormatter: formatNumeroAcompanantes,
        },
        {
            headerName: "Numero de documento",
            field: "formulario.paciente.NumeroIdent",
        },
        {
            field: "id",
            headerName: "Asignar conductor",
            cellRenderer: (params) => (
                <Button
                    color="secondary"
                    onClick={() => handleEditClick(params.data)}
                >
                    Editar
                </Button>
            ),
        },
    ];

    const handleModalToggle = () => {
        setModalOpen(!modalOpen);
        setHoraSalidaMasivo("");
    };
    const handleEditModalToggle = () => setModalEditOpen(!modalEditOpen);

    const handleEditClick = (element) => {
        setModalContent(element);
        setModalTittleContent({
            title: "Asignar conductor",
            footer: (
                <Button
                    className="mb-3"
                    color="secondary"
                    onClick={handleEditModalToggle}
                >
                    Cancelar
                </Button>
            ),
        });
        handleEditModalToggle();
    };

    const handleMasiveClick = (content) => {
        setModalEditContent(content);
        handleModalToggle();
    };

    const handleExportCSV = () => {
        gridApi.current.exportDataAsCsv();
    };

    const onGridReady = (params) => {
        gridApi.current = params.api;
    };

    const handleUpdateTable = () => {
        const params = {
            FechaInicio: dateStart.format("YYYY-MM-DD"),
            FechaFinal: dateEnd.format("YYYY-MM-DD"),
        };
        if (token) {
            setLoading(true);
            axios
                .get("/programacionformularios", {
                    headers: { Authorization: `Bearer ${token}` },
                    params,
                })
                .then((response) => {
                    setFormt(response.data);
                    setConsultClicked(true);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        const idsSeleccionados = selectedRows.map((row) => row.id);
        setSelectedRows(idsSeleccionados);
    };

    return (
        <>
            <Helmet>
                <title>Asignar transporte</title>
            </Helmet>
            <div style={{ position: "relative" }}>
                {loading && <Spinner />}
                <div>
                    <BreadCrum etiquetas={etiquetas} />
                </div>
                <div style={{ opacity: loading ? 0.5 : 1 }}>
                    <div className="container-fluid mt-5">
                        <div className="ConsultReport">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={2} sx={{ minWidth: 305 }}>
                                    <DatePicker
                                        label="Fecha Inicial Desde"
                                        id="dateStart"
                                        value={dateStart}
                                        onChange={setDateStart}
                                    />
                                </Stack>
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={2} sx={{ minWidth: 305 }}>
                                    <DatePicker
                                        label="Fecha Inicial Hasta"
                                        value={dateEnd}
                                        onChange={setDateEnd}
                                    />
                                </Stack>
                            </LocalizationProvider>

                            <Button
                                id="buttonConsult"
                                className="mb-3"
                                color="secondary"
                                onClick={handleConsult}
                            >
                                Consultar
                            </Button>
                        </div>

                        <Button color="primary" onClick={handleMasiveClick}>
                            Asignar conductor masivo
                        </Button>
                        <Button
                            style={{ marginLeft: "10px" }}
                            color="success"
                            onClick={handleExportCSV}
                        >
                            Exportar CSV (Excel)
                        </Button>
                        <div
                            className="mt-3"
                            style={{ height: "500px", width: "100%" }}
                        >
                            <div
                                className="ag-theme-alpine"
                                style={{ height: "100%", width: "100%" }}
                            >
                                <AgGridReact
                                    rowData={form}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    rowSelection={"multiple"}
                                    pagination={true}
                                    onGridReady={onGridReady}
                                    localeText={localeEs}
                                    paginationPageSize={20}
                                    onSelectionChanged={onSelectionChanged}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalAsignacion
                isOpen={modalEditOpen}
                toggle={handleEditModalToggle}
                title={modalContent.title}
                content={modalContent.content}
                footer={modalContent.footer}
                token={token}
                updateTable={handleUpdateTable}
                SolicitudId={modalContent.id ? modalContent.id : ""}
                HogarId={
                    modalContent.formulario
                        ? modalContent.formulario.HogarId
                        : ""
                }
                TipoServicioId={
                    modalContent.TipoServicioHogarId
                        ? modalContent.TipoServicioHogarId
                        : ""
                }
                Nombre={
                    modalContent.formulario
                        ? (modalContent.formulario.paciente.Nombre1
                              ? modalContent.formulario.paciente.Nombre1
                              : "") +
                          " " +
                          (modalContent.formulario.paciente.Apellido1
                              ? modalContent.formulario.paciente.Apellido1
                              : "")
                        : " "
                }
                Celular={
                    modalContent.formulario
                        ? modalContent.formulario.paciente.TelContacto
                        : ""
                }
                RutaTraslado={
                    modalContent.RutaTraslado ? modalContent.RutaTraslado : ""
                }
                HoraCita={modalContent.HoraCita ? modalContent.HoraCita : ""}
                HoraSalida={
                    modalContent.HoraSalida ? modalContent.HoraSalida : ""
                }
                Observacion={
                    modalContent.Observacion ? modalContent.Observacion : ""
                }
                FormularioId={modalContent.id ? modalContent.id : ""}
                ConductorId={
                    modalContent.ConductorId ? modalContent.ConductorId : ""
                }
                VehiculoId={
                    modalContent.VehiculoId ? modalContent.VehiculoId : ""
                }
            />
            <ModalAsignacionMasiva
                isOpen={modalOpen}
                toggle={handleModalToggle}
                infoModal={modalEditcontent}
                footer={modalEditcontent.footer}
                token={token}
                updateTable={handleUpdateTable}
                selectedRows={selectedRows}
                horaSalidaMasivo={horaSalidaMasivo}
                setHoraSalidaMasivo={setHoraSalidaMasivo}
            />
        </>
    );
};

export default withTokenCheck(AppPaciente);
