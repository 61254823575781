import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";

const ModalCreate = ({ isOpen, toggle, title, token, updateTable }) => {
  const [descripcion, setDescripcion] = useState([]);
  const [selectedDescripcion, setSelectedDescripcion] = useState("");
  const [selectedDescripcion2, setSelectedDescripcion2] = useState("");
  const [codigo, setCodigo] = useState("");
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);

  useEffect(() => {
    if (token) {
      axios
        .get("/descripcion", { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          setDescripcion(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  const descripcionOptions = descripcion.map((option) => ({
    value: option.id,
    label: option.Texto ?? option.Texto,
  }));

  const handleCodigoChange = (event) => {
    setCodigo(event.target.value);
  };
  const handleDescriptionChange = (selectedOption) => {
    setSelectedDescripcion2(selectedOption.value);
    setSelectedDescripcion(
      descripcionOptions.find((option) => option.value === selectedOption.value)
    );
  };

  const handleCreateSubmit = (event) => {
    event.preventDefault();
    axios
      .post(
        "/codigoDescripcion",
        {
            CodigoServicio : codigo,
            TipoDescripcionId : parseInt(selectedDescripcion2),
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Creado!",
          text: "codigo asociado a descripción con exito",
          showCloseButton: true,
        }).then(() => {
            updateTable();
            toggle();
            setCodigo("");
            setSelectedDescripcion("");
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Ha ocurrido un error al asociar el codigo a la descripción.",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label>Codigo:</label>
          <input
            className="form-control"
            name="codig"
            type="text"
            value={codigo}
            onChange={handleCodigoChange}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="servicioId">Descripción:</label>
          <Select
            value={selectedDescripcion}
            onChange={handleDescriptionChange}
            options={descripcionOptions}
          ></Select>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleCreateSubmit} color="primary" otype="submit">
          Insertar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalCreate;
