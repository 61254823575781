import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import GrainIcon from "@mui/icons-material/Grain";
import "./breadCrumStyle.css";

function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
}

const breadCrum = ({ etiquetas }) => {
    const item1 = etiquetas[0];
    const item2 = etiquetas[1];
    const item3 = etiquetas[2];
    return (
        <section id="breadCrum">
            <div role="presentation" onClick={handleClick}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography
                        underline="hover"
                        sx={{ display: "flex", alignItems: "center" }}
                        color="inherit"
                        href="/home"
                    >
                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        {item1}
                    </Typography>
                    <Typography
                        underline="hover"
                        sx={{ display: "flex", alignItems: "center" }}
                        color="inherit"
                        href="/material-ui/getting-started/installation/"
                    >
                        <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        {item2}
                    </Typography>
                    <Typography
                        sx={{ display: "flex", alignItems: "center" }}
                        color="text.primary"
                    >
                        <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        {item3}
                    </Typography>
                </Breadcrumbs>
            </div>
        </section>
    );
};

export default breadCrum;
