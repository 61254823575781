import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button, FormGroup } from "reactstrap";
import { Helmet } from "react-helmet";
import Spinner from "../Spinner/spinner";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { localeEs } from "../locale/ag-grid-es";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import BreadCrum from "../breadCrum/breadCrum";
import withTokenCheck from "../withTokenCheck";

const AppReportBed = ({ token }) => {
    const [caseDeleted, setCaseDeleted] = useState([]);
    const [loading, setLoading] = useState(true);
    const gridApi = useRef(null);
    const [numberCase, setNumberCase] = useState([]);
    const [selectedCase, setSelectedCase] = useState("");
    const [selectedCase2, setSelectedCase2] = useState("");
    const etiquetas = [
        "Inicio Lossman",
        "Cambios De Evento",
        "Cancelación De Solicitudes",
    ];

    useEffect(() => {
        setLoading(false);
        if (token) {
            axios
                .get("/edicion-formulario/consulta/eliminados", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setNumberCase(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const numberCaseoptions = numberCase.map((option) => ({
        value: option.id,
        label: option.NumeroCaso ?? option.NumeroCaso,
    }));

    const handleNumberCaseChange = (selectedOption) => {
        setSelectedCase2(selectedOption.value);
        setSelectedCase(
            numberCaseoptions.find(
                (option) => option.value === selectedOption.value
            )
        );
    };
    const handleSelectCase = () => {
        setLoading(true);
        if (token) {
            axios
                .get(`/formulario/consulta/lista/${selectedCase2}`, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setCaseDeleted(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    function showDeleteConfirmation(data) {
        Swal.fire({
            title: "¿Estás seguro de eliminar este registro?",
            input: "text",
            inputLabel: "Motivo de eliminación",
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            inputValidator: (value) => {
                if (!value) {
                    return "Por favor, ingresa el motivo de la eliminación.";
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const motivo = result.value;
                deleteRow(data, motivo);
            }
        });
    }

    function deleteRow(data, motivo) {
        setLoading(true);
        axios
            .post(
                `/formulario/eliminacion/${data.id}`,
                {
                    Motivo: motivo,
                },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((response) => {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    title: "Eliminado!",
                    text: "El formulario ha sido eliminado con exito.",
                    showCloseButton: true,
                }).then(() => {
                    handleUpdatedTable();
                });
            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "Ha ocurrido un error al eliminar el formulario.",
                });
            });
    }

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        editable: true,
    };

    const columnDefs = [
        {
            field: "id",
            headerName: "Cancelar Formulario",
            cellRenderer: (params) => (
                <Button
                    color="secondary"
                    onClick={() => showDeleteConfirmation(params.data)}
                >
                    Cancelar
                </Button>
            ),
            flex: 1,
        },
        { headerName: "Nombre Paciente", field: "paciente.Nombre", flex: 1 },
        {
            headerName: "Tipo de documento",
            field: "paciente.tipo_documento.Texto",
            flex: 1,
        },
        {
            headerName: "Numero Documento",
            field: "paciente.NumeroIdent",
            flex: 1,
        },
        { headerName: "Numero de caso", field: "NumeroCaso", flex: 1 },
    ];

    const handleExportCSV = () => {
        gridApi.current.exportDataAsCsv();
    };

    const onGridReady = (params) => {
        gridApi.current = params.api;
    };

    const handleUpdatedTable = () => {
        setLoading(true);
        if (token) {
            axios
                .get(`/formulario/consulta/lista/${selectedCase2}`, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setCaseDeleted(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    return (
        <>
            <Helmet>
                <title>Cancelar solicitudes</title>
            </Helmet>
            <div style={{ position: "relative" }}>
                {loading && <Spinner />}
                <div>
                    <BreadCrum etiquetas={etiquetas} />
                </div>
                <div
                    style={{
                        width: "90%",
                        margin: "0 auto",
                        marginTop: "2rem",
                    }}
                >
                    <label htmlFor="servicioId">
                        Seleccione numero de caso que desea cancelar
                    </label>
                    <FormGroup id="FormEdit">
                        <Select
                            id="SelectEdit"
                            options={numberCaseoptions}
                            value={selectedCase}
                            onChange={handleNumberCaseChange}
                        ></Select>
                        <Button onClick={handleSelectCase}>Consultar</Button>
                    </FormGroup>
                </div>
                <div style={{ opacity: loading ? 0.5 : 1 }}>
                    <div className="container mt-5">
                        <Button color="success" onClick={handleExportCSV}>
                            Exportar CSV (Excel)
                        </Button>
                        <div
                            className="mt-3"
                            style={{ height: "500px", width: "100%" }}
                        >
                            <div
                                className="ag-theme-alpine"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                }}
                            >
                                <AgGridReact
                                    rowData={caseDeleted}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    pagination={true}
                                    onGridReady={onGridReady}
                                    localeText={localeEs}
                                    paginationPageSize={20}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withTokenCheck(AppReportBed);
