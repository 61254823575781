import { TextField } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";

const CustomModal = ({
    isOpen,
    toggle,
    title,
    handleUpdateTable,
    companion,
    FormId,
    pacienteId,
    token,
    paciente,
    companionsData,
    setCompanionsData,
    selectedHabitaciones,
    setSelectedHabitaciones,
}) => {
    const [room, setRoom] = useState([]);
    const [patientHabitacionId, setPatientHabitacionId] = useState(null);
    const modalRef = useRef(null);

    useEffect(() => {
        if (token) {
            axios
                .get("/habitacion", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setRoom(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const roomOptions = room.map((option) => ({
        value: option.id,
        label:
            option.hogar.Nombre + ", Habitación: " + option.NumeroHabitacion ??
            option.NumeroHabitacion,
    }));

    const handleCancelEvent = () => {
        setSelectedHabitaciones([]);
        setCompanionsData([]);
        toggle();
    };

    const handleCreateSubmit = (event) => {
        event.preventDefault();

        // Construir los datos del paciente
        const patientData = {
            FormularioId: FormId,
            TipoPersonaId: 1,
            PersonaId: pacienteId,
            HabitacionId: parseInt(patientHabitacionId),
        };
        // Construir los datos de los acompañantes
        const sendData = [];
        sendData.push(patientData);

        if (companion.length === companionsData.length) {
            if (companion) {
                for (let i = 0; i < companionsData.length; i++) {
                    const comp = companionsData[i];
                    sendData.push({
                        FormularioId: parseInt(FormId),
                        TipoPersonaId: 2,
                        PersonaId: comp.PersonaId,
                        HabitacionId: parseInt(selectedHabitaciones[i]),
                    });
                }
            }
            // Enviar los datos del paciente y de los acompañantes
            axios
                .post("/asignacion", sendData, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    if (response.status === 201 || response.status === 200) {
                        setSelectedHabitaciones([]);

                        Swal.fire({
                            icon: "success",
                            title: "Cama asignada",
                            text: "¿Desea descargar el formato de checkin ?",
                            showCancelButton: true,
                            confirmButtonText: "Sí, descargar",
                            cancelButtonText: "Cancelar",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                if (token) {
                                    axios
                                        .post(
                                            `/checkin/${FormId}`,
                                            {},
                                            {
                                                headers: {
                                                    Authorization: `Bearer ${token}`,
                                                },
                                                responseType: "blob",
                                            }
                                        )
                                        .then((response2) => {
                                            if (response2.status != 200) {
                                                throw new Error(
                                                    "Error al descargar el archivo"
                                                );
                                            }

                                            // Obtener el nombre del archivo del encabezado Content-Disposition
                                            const contentDisposition =
                                                response2.headers.get(
                                                    "Content-Disposition"
                                                );
                                            const fileNameMatch =
                                                contentDisposition &&
                                                contentDisposition.match(
                                                    /filename="(.+)"/
                                                );
                                            const fileName = fileNameMatch
                                                ? fileNameMatch[1]
                                                : "documento.pdf";

                                            // Crear un Blob a partir del contenido del archivo
                                            const blob = new Blob(
                                                [response2.data],
                                                {
                                                    type: "application/pdf",
                                                }
                                            );

                                            // Crear un enlace temporal para descargar el archivo
                                            const link =
                                                document.createElement("a");
                                            link.href =
                                                window.URL.createObjectURL(
                                                    blob
                                                );
                                            link.download = fileName;
                                            link.click();

                                            let showSecondConfirmation = true;

                                            Swal.fire({
                                                icon: "success",
                                                title: "Cama asignada",
                                                text: "¿Desea descargar el formato de checkout ?",
                                                showCancelButton: true,
                                                confirmButtonText:
                                                    "Sí, descargar",
                                                cancelButtonText: "Cancelar",
                                            }).then((result2) => {
                                                if (result2.isConfirmed) {
                                                    axios
                                                        .post(
                                                            `/checkout/${FormId}`,
                                                            {},
                                                            {
                                                                headers: {
                                                                    Authorization: `Bearer ${token}`,
                                                                },
                                                                responseType:
                                                                    "blob",
                                                            }
                                                        )
                                                        .then((response3) => {
                                                            if (
                                                                response3.status !=
                                                                200
                                                            ) {
                                                                throw new Error(
                                                                    "Error al descargar el archivo"
                                                                );
                                                            }

                                                            // Obtener el nombre del archivo del encabezado Content-Disposition
                                                            const contentDisposition =
                                                                response3.headers.get(
                                                                    "Content-Disposition"
                                                                );
                                                            const fileNameMatch =
                                                                contentDisposition &&
                                                                contentDisposition.match(
                                                                    /filename="(.+)"/
                                                                );
                                                            const fileName =
                                                                fileNameMatch
                                                                    ? fileNameMatch[1]
                                                                    : "documento.pdf";

                                                            // Crear un Blob a partir del contenido del archivo
                                                            const blob =
                                                                new Blob(
                                                                    [
                                                                        response3.data,
                                                                    ],
                                                                    {
                                                                        type: "application/pdf",
                                                                    }
                                                                );

                                                            // Crear un enlace temporal para descargar el archivo
                                                            const link =
                                                                document.createElement(
                                                                    "a"
                                                                );
                                                            link.href =
                                                                window.URL.createObjectURL(
                                                                    blob
                                                                );
                                                            link.download =
                                                                fileName;
                                                            link.click();
                                                            handleUpdateTable();
                                                            toggle();
                                                        })
                                                        .catch((error) => {
                                                            Swal.fire({
                                                                icon: "error",
                                                                title: "Error!",
                                                                text: error
                                                                    .response
                                                                    .data
                                                                    .message,
                                                                showCloseButton: true,
                                                            });
                                                        });
                                                } else {
                                                    toggle();
                                                    handleUpdateTable();
                                                }
                                            });
                                        })
                                        .catch((error) => {
                                            Swal.fire({
                                                icon: "error",
                                                title: "Error!",

                                                text: error.response.data
                                                    .message,
                                                showCloseButton: true,
                                            });
                                        });
                                }
                            } else {
                                Swal.fire({
                                    icon: "success",
                                    title: "Cama asignada",
                                    text: "¿Desea descargar el formato de checkout ?",
                                    showCancelButton: true,
                                    confirmButtonText: "Sí, descargar",
                                    cancelButtonText: "Cancelar",
                                }).then((result2) => {
                                    if (result2.isConfirmed) {
                                        axios
                                            .post(
                                                `/checkout/${FormId}`,
                                                {},
                                                {
                                                    headers: {
                                                        Authorization: `Bearer ${token}`,
                                                    },
                                                    responseType: "blob",
                                                }
                                            )
                                            .then((response3) => {
                                                if (response3.status != 200) {
                                                    throw new Error(
                                                        "Error al descargar el archivo"
                                                    );
                                                }

                                                // Obtener el nombre del archivo del encabezado Content-Disposition
                                                const contentDisposition =
                                                    response3.headers.get(
                                                        "Content-Disposition"
                                                    );
                                                const fileNameMatch =
                                                    contentDisposition &&
                                                    contentDisposition.match(
                                                        /filename="(.+)"/
                                                    );
                                                const fileName = fileNameMatch
                                                    ? fileNameMatch[1]
                                                    : "documento.pdf";

                                                // Crear un Blob a partir del contenido del archivo
                                                const blob = new Blob(
                                                    [response3.data],
                                                    {
                                                        type: "application/pdf",
                                                    }
                                                );

                                                // Crear un enlace temporal para descargar el archivo
                                                const link =
                                                    document.createElement("a");
                                                link.href =
                                                    window.URL.createObjectURL(
                                                        blob
                                                    );
                                                link.download = fileName;
                                                link.click();
                                                handleUpdateTable();
                                                toggle();
                                            })
                                            .catch((error) => {
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "Error!",
                                                    text: error.response.data
                                                        .message,
                                                    showCloseButton: true,
                                                });
                                            });
                                    } else {
                                        toggle();
                                        handleUpdateTable();
                                    }
                                });
                            }
                        });
                    }
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        title: "Error!",
                        text: error.response.data.message,
                        showCloseButton: true,
                    });
                });
        } else {
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: "Por favor asigne habitación para todos los acompañantes",
                showCloseButton: true,
            });
        }
    };

    const handlePatientHabitacionChange = (selectedOption) => {
        setPatientHabitacionId(selectedOption.value);
    };

    const handleCompanionChange = (index, data) => {
        setCompanionsData((companionsData) => {
            const newCompanionsData = [...companionsData];
            newCompanionsData[index] = data;
            return newCompanionsData;
        });

        setSelectedHabitaciones((selectedHabitaciones) => {
            const newSelectedHabitaciones = [...selectedHabitaciones];
            newSelectedHabitaciones[index] = data.HabitacionId;
            return newSelectedHabitaciones;
        });
    };

    return (
        <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <TextField
                        label="paciente"
                        disabled
                        value={
                            (paciente.Nombre1 ? paciente.Nombre1 : "") +
                            " " +
                            (paciente.Nombre2 ? paciente.Nombre2 : "") +
                            " " +
                            (paciente.Apellido1 ? paciente.Apellido1 : "") +
                            " " +
                            (paciente.Apellido2 ? paciente.Apellido2 : "")
                        }
                        className="form-control"
                        name="Texto"
                        type="text"
                    />
                </FormGroup>
                <FormGroup>
                    <label htmlFor="habitacionId">
                        Seleccionar habitación:
                    </label>
                    <Select
                        options={roomOptions}
                        onChange={handlePatientHabitacionChange}
                        className="form-control"
                    ></Select>
                </FormGroup>
            </ModalBody>
            {companion &&
                companion.map((element, index) => (
                    <ModalBody key={element.id}>
                        <FormGroup>
                            <TextField
                                label="primer acompañante"
                                value={
                                    element.Nombre1 +
                                    " " +
                                    (element.Nombre2 ? element.Nombre2 : "") +
                                    " " +
                                    element.Apellido1 +
                                    " " +
                                    (element.Apellido2
                                        ? element.Apellido2
                                        : "") +
                                    " - " +
                                    element.NumeroIdent
                                }
                                disabled
                                className="form-control"
                                name="Texto"
                                type="text"
                            />
                            <input
                                type="hidden"
                                name="companionId"
                                value={element.id}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="habitacionId">
                                Seleccionar habitación:
                            </label>
                            <Select
                                required
                                options={roomOptions}
                                onChange={(selectedOption) => {
                                    handleCompanionChange(index, {
                                        ...companionsData[index],
                                        PersonaId: element.id,
                                        HabitacionId: selectedOption.value,
                                    });
                                }}
                                value={roomOptions.find(
                                    (option) =>
                                        option.value ===
                                        selectedHabitaciones[index]
                                )}
                                className="form-control"
                            ></Select>
                        </FormGroup>
                    </ModalBody>
                ))}
            <ModalFooter>
                <Button
                    onClick={handleCreateSubmit}
                    className="mb-4"
                    type="submit"
                    id="Button"
                    variant="contained"
                >
                    Confirmar
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CustomModal;
