import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button } from "reactstrap";
import { Helmet } from "react-helmet";
import Spinner from "../../Spinner/spinner";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { localeEs } from "../../locale/ag-grid-es";
import BreadCrum from "../../breadCrum/breadCrum";
import withTokenCheck from "../../withTokenCheck";

const AppReport = ({ token }) => {
    const [form, setForm] = useState([]);
    const [loading, setLoading] = useState(true);
    const gridApi = useRef(null);
    const etiquetas = [
        "Inicio Lossman",
        "Reportes",
        "Reporte Asignación de Camas",
    ];

    useEffect(() => {
        if (token) {
            setLoading(true);
            axios
                .get("/asignacion/reporte/asignaciones", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setForm(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        editable: true,
    };

    const handleExportCSV = () => {
        gridApi.current.exportDataAsCsv();
    };

    const onGridReady = (params) => {
        gridApi.current = params.api;
    };

    const columnDefs = [
        { headerName: "Numero de caso", field: "NumeroCaso" },
        { headerName: "¿CheckOut?", field: "CheckOut"},
        { headerName: "Fecha Inicio", field: "FechaInicial" },
        { headerName: "Fecha Final", field: "FechaFinal" },
        { headerName: "Tipo de persona", field: "Texto" },
        {
            headerName: "Nombre del paciente",
            field: "Persona",
        },
        { headerName: "tipo de documento", field: "TipoDocumento" },
        { headerName: "Numero de Documento", field: "Documento" },
        { headerName: "Ciudad de Origen", field: "CiudadOrigen"},
        { headerName: "Cama Asignada", field: "NumeroHabitacion" },
        {
            headerName: "Usuario Creación",
            field: "created_by",
            cellRenderer: (params) => {
                const userCreate = params.data.created_by
                    ? params.data.created_by.name
                    : "";
                return userCreate;
            },
        },
        {
            headerName: "Usuario Actualización",
            field: "updated_by",
            cellRenderer: (params) => {
                const userUpdate = params.data.updated_by
                    ? params.data.updated_by.name
                    : "";
                return userUpdate;
            },
        },
    ];

    return (
        <>
            <Helmet>
                <title>Reporte Asignación</title>
            </Helmet>
            <div style={{ position: "relative" }}>
                {loading && <Spinner />}
                <div>
                    <BreadCrum etiquetas={etiquetas} />
                </div>
                <div style={{ opacity: loading ? 0.5 : 1 }}>
                    <div className="container mt-5">
                        <Button color="success" onClick={handleExportCSV}>
                            Exportar CSV (Excel)
                        </Button>
                        <div
                            className="mt-3"
                            style={{ height: "500px", width: "100%" }}
                        >
                            <div
                                className="ag-theme-alpine"
                                style={{ height: "100%", width: "100%" }}
                            >
                                <AgGridReact
                                    rowData={form}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    rowSelection={"multiple"}
                                    pagination={true}
                                    onGridReady={onGridReady}
                                    localeText={localeEs}
                                    paginationPageSize={20}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withTokenCheck(AppReport);
