import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";

const ModalEdit = ({ isOpen, toggle, title, id, textoInicial, token, HogarId }) => {
  const [Rol, setRol] = useState("");
  const [hogar, setHogar] = useState([]);
  const [selectedHogar, setSelectedHogar] = useState("");
  const [selectedHogar2, setSelectedHogar2] = useState("");
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);

  useEffect(() => {
    setRol(textoInicial);
  }, [textoInicial]);

  useEffect(() => {
    if (token) {
      const newOption = {
        id: null,
        Nombre: "Todos los hogares",
      }
      axios
        .get("/hogar", { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          setHogar(response.data);
          
        })
        .then(() =>{
          setHogar(hogar => hogar.concat(newOption));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  const hogarOptions = hogar.map((option) => ({
    value: option.id,
    label: option.Nombre ?? option.Nombre,
  }));
  

  const handleHogarChange = (selectedOption) => {
    setSelectedHogar2(selectedOption.value);
    setSelectedHogar(
      hogarOptions.find((option) => option.value === selectedOption.value)
    );
  };

  const handleRolChange = (event) => {
    setRol(event.target.value);
  };

  const handleEditSubmit = (event) => {
    event.preventDefault();
    console.log(selectedHogar2 === null, "selectedHogar")
    axios
      .put(`/roles/${id}`, {
        Nombre: Rol,
        HogarId: (selectedHogar2 || selectedHogar2 === null) ? selectedHogar2 : HogarId,
      }, {headers: { Authorization: `Bearer ${token}` }})
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Actualizado!",
          text: "el Rol a sido actualizada con exito.",
          showCloseButton: true,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "el tipo de Rol no se pudo actualizar",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label>Rol:</label>
          <input
            className="form-control"
            name="rol"
            type="text"
            value={Rol}
            onChange={handleRolChange}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="MenuId">Hogar Asociado:</label>
          <Select
            options={hogarOptions}
            value={
              selectedHogar
                ? selectedHogar
                : hogarOptions.find((option) => option.value === HogarId)
            }
            onChange={handleHogarChange}
          ></Select>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleEditSubmit} color="primary" otype="submit">
          Guardar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalEdit;
