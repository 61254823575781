import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button, Modal } from "reactstrap";
import { Helmet } from "react-helmet";
import Spinner from "../../Spinner/spinner";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { localeEs } from "../../locale/ag-grid-es";
import Swal from "sweetalert2";
import BreadCrum from "../../breadCrum/breadCrum";
import withTokenCheck from "../../withTokenCheck";

const AppReasignacionHogar = ({ token }) => {
    const [infoHogarAsignado, setInfoHogarAsignado] = useState([]);
    const [loading, setLoading] = useState(true);
    const gridApi = useRef(null);
    const etiquetas = [
        "Inicio Lossman",
        "Cambios de Evento",
        "Activar Evento De Hospitalización",
    ];

    useEffect(() => {
        if (token) {
            axios
                .get("/asignacion/reporte/reasignaciones", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setInfoHogarAsignado(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        editable: true,
    };

    const columnDefs = [
        {
            field: "id",
            headerName: "Excepeción hospitalización",
            cellRenderer: (params) => (
                <Button
                    color="secondary"
                    onClick={() => handleButtonClick(params.data)}
                >
                    Activar excepcion
                </Button>
            ),
        },
        {
            field: "Nombre",
            headerName: "Hogar Asignado",
        },
        {
            field: "NumeroCaso",
            headerName: "Numero de caso",
        },
        {
            field: "FechaInicial",
            headerName: "Fecha Inicial",
        },
        {
            field: "FechaFinal",
            headerName: "Fecha Final",
        },
        {
            field: "Persona",
            headerName: "Nombre",
        },
        {
            field: "TipoDocumento",
            headerName: "Tipo de documento",
        },
        {
            field: "Documento",
            headerName: "Numero Documento",
        },
        {
            field: "created_by.name",
            headerName: "Usuario que asigno",
        },
        {
            field: "created_by.created_at",
            headerName: "Fecha Asignación",
        },
    ];

    const handleButtonClick = (element) => {
        const formId = element.FormularioId;
        const tipoPerId = element.TipoPersonaId;
        const perId = element.PersonaId;
        const habId = element.HabitacionId;
        Swal.fire({
            icon: "warning",
            title: "¿Estás seguro de que el paciente se hospitalizo?",
            text: "recuerde que se suspenderan los servicios en el hogar",
            input: "date",
            inputLabel: "Fecha hospitalización",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "Cancelar",
            inputValidator: (value) => {
                // Validar que se haya ingresado un motivo
                if (!value) {
                    return "Por favor, ingrese la fecha de inicio de hospitalización.";
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const fechaFin = result.value;
                axios
                    .put(
                        `/asignacion/actualizacion/hospitalizacion`,
                        {
                            FormularioId: formId,
                            TipoPersonaId: tipoPerId,
                            PersonaId: perId,
                            HabitacionId: habId,
                            FechaFinal: fechaFin,
                        },
                        { headers: { Authorization: `Bearer ${token}` } }
                    )
                    .then((response) => {
                        Swal.fire({
                            icon: "success",
                            title: "Actualizado!",
                            text: "se ha suspendido el servicio con exito.",
                            showCloseButton: true,
                        }).then(() => {
                            handleUpdateTable();
                        });
                    })
                    .catch((error) => {
                        Swal.fire({
                            icon: "error",
                            title: "Error!",
                            text: "El servicio no fue suspendido",
                            showCloseButton: true,
                        });
                    });
            }
        });
    };

    const handleExportCSV = () => {
        gridApi.current.exportDataAsCsv();
    };

    const onGridReady = (params) => {
        gridApi.current = params.api;
    };

    const handleUpdateTable = () => {
        setLoading(true);
        if (token) {
            axios
                .get("/asignacion/reporte/reasignaciones", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setInfoHogarAsignado(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    return (
        <>
            <Helmet>
                <title>Hospitalización</title>
            </Helmet>
            {loading ? (
                <Spinner />
            ) : (
                <div>
                    <div>
                        <BreadCrum etiquetas={etiquetas} />
                    </div>
                    <div className="container-fluid mt-5">
                        <Button color="success" onClick={handleExportCSV}>
                            Exportar CSV (Excel)
                        </Button>
                        <div
                            className="mt-3"
                            style={{ height: "500px", width: "100%" }}
                        >
                            <div
                                className="ag-theme-alpine"
                                style={{ height: "100%", width: "100%" }}
                            >
                                <AgGridReact
                                    rowData={infoHogarAsignado}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    pagination={true}
                                    onGridReady={onGridReady}
                                    localeText={localeEs}
                                    paginationPageSize={20}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default withTokenCheck(AppReasignacionHogar);
