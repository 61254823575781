import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button } from "reactstrap";
import { Helmet } from "react-helmet";
import Spinner from "../../Spinner/spinner";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { localeEs } from "../../locale/ag-grid-es";
import Swal from "sweetalert2";
import BreadCrum from "../../breadCrum/breadCrum";
import withTokenCheck from "../../withTokenCheck";

const AppCheckOut = ({ token }) => {
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    /* const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [modalTittleContent, setModalTittleContent] = useState({});
    const tableRef = useRef(null); */
    const gridApi = useRef(null);
    const etiquetas = [
        "Inicio Lossman",
        "Cambios De Evento",
        "Proceso De Check-Out",
    ];

    useEffect(() => {
        if (token) {
            axios
                .get("/formulario/consulta/checkout", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setInfo(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const handleCheckOutClick = (element) => {
        if (token) {
            Swal.fire({
                icon: "info",
                title: "¿Está seguro de cerrar el caso?",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sí, cerrar caso",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.isConfirmed) {
                    // Modal para seleccionar la opción de facturación
                    Swal.fire({
                        icon: "question",
                        title: "Facturación del caso",
                        text: "¿Desea facturar el caso que esta siendo cerrado?",
                        text: "¿Desea facturar el caso que está siendo cerrado?",
                        html: `
                          <div>
                            <label>Opción:</label>
                            <div class="swal2-radio">
                              <label>
                                <input type="radio" name="opcion" value="facturar" />
                                <span>Facturar</span>
                              </label>
                              <label>
                                <input type="radio" name="opcion" value="nofacturar" />
                                <span>No Facturar</span>
                              </label>
                            </div>
                          </div>
                          <div>
                            <label for="observaciones">Observaciones:</label>
                            <textarea id="observaciones" class="swal2-textarea"></textarea>
                          </div>
                        `,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Aceptar",
                        preConfirm: () => {
                            const opcion = document.querySelector(
                                'input[name="opcion"]:checked'
                            )?.value;
                            const observaciones =
                                document.getElementById("observaciones").value;

                            if (!opcion) {
                                Swal.showValidationMessage(
                                    "Debes seleccionar una opción"
                                );
                            }
                            if (opcion === "nofacturar" && !observaciones) {
                                Swal.showValidationMessage(
                                    "Debes indicar el motivo por el cual no se debe facturar"
                                );
                            }

                            return { opcion, observaciones };
                        },
                    }).then((resultFacturacion) => {
                        if (resultFacturacion.isConfirmed) {
                            const estadoFacturacion =
                                resultFacturacion.value.opcion === "facturar"
                                    ? 1
                                    : 0;
                            const observacionesFacturacion =
                                resultFacturacion.value.observaciones;

                            // Realizar la solicitud POST para cerrar el caso

                            axios
                                .post(
                                    `/formulario/checkout/${element.id}`,
                                    {
                                        EstadoFacturacion: estadoFacturacion,
                                        ObservacionesFacturacion:
                                            observacionesFacturacion,
                                    },
                                    {
                                        headers: {
                                            Authorization: `Bearer ${token}`,
                                        },
                                        responseType: "blob",
                                    }
                                )
                                .then((response) => {
                                    if (response.status === 200) {
                                        // Mostrar mensaje de éxito y ofrecer la descarga del formato de checkout
                                        Swal.fire({
                                            icon: "success",
                                            title: "Caso cerrado con éxito",
                                            text: "¿Desea descargar el formato de checkout?",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText: "Sí, descargar",
                                            cancelButtonText: "No",
                                        }).then((resultDescarga) => {
                                            if (resultDescarga.isConfirmed) {
                                                // Descargar el formato de checkout
                                                const fileName =
                                                    response.headers
                                                        .get(
                                                            "Content-Disposition"
                                                        )
                                                        .match(
                                                            /filename="(.+)"/
                                                        )[1];
                                                const blob = new Blob(
                                                    [response.data],
                                                    { type: "application/pdf" }
                                                );
                                                const link =
                                                    document.createElement("a");
                                                link.href =
                                                    window.URL.createObjectURL(
                                                        blob
                                                    );
                                                link.download = fileName;
                                                link.click();
                                            }
                                        });

                                        // Actualizar la tabla de casos
                                        handleUpdateTable();
                                    } else {
                                        // Mostrar mensaje de error
                                        Swal.fire({
                                            icon: "error",
                                            title: "Error",
                                            text: "Se produjo un error al cerrar el caso",
                                            showCloseButton: true,
                                        });
                                    }
                                })
                                .catch((error) => {
                                    console.error(error);
                                    Swal.fire({
                                        icon: "error",
                                        title: "Error",
                                        text: "Se produjo un error al cerrar el caso",
                                        showCloseButton: true,
                                    });
                                });
                        }
                    });
                }
            });
        }
    };

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        editable: true,
    };

    const columnDefs = [
        {
            field: "id",
            headerName: "Check Out",
            cellRenderer: (params) => (
                <Button
                    color="secondary"
                    onClick={() => handleCheckOutClick(params.data)}
                >
                    Check out
                </Button>
            ),
        },
        {
            headerName: "Paciente",
            field: "paciente",
            cellRenderer: (params) => {
                const nombre1 = params.data.paciente.Nombre1 || "";
                const nombre2 = params.data.paciente.Nombre2 || " ";
                const apellido1 = params.data.paciente.Apellido1 || "";
                const apellido2 = params.data.paciente.Apellido2 || "";
                return (
                    nombre1 + " " + nombre2 + " " + apellido1 + " " + apellido2
                );
            },
        },
        { headerName: "Numero De Caso", field: "NumeroCaso" },
        { headerName: "Hogar", field: "hogar.Nombre" },
        {
            headerName: "Fecha Inicial",
            field: "FechaInicial",
        },
        { headerName: "Fecha Final", field: "FechaFinal" },
        { headerName: "Usuario Que Actualizo", field: "updated_by.name" },
        { headerName: "Fecha Actualización", field: "updated_at" },
    ];

    const handleExportCSV = () => {
        gridApi.current.exportDataAsCsv();
    };

    const onGridReady = (params) => {
        gridApi.current = params.api;
    };

    const handleUpdateTable = () => {
        if (token) {
            setLoading(true);
            axios
                .get("/formulario/consulta/checkout", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setInfo(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    return (
        <>
            <Helmet>
                <title>Check Out</title>
            </Helmet>
            <div style={{ position: "relative" }}>
                {loading && <Spinner />}
                <div>
                    <BreadCrum etiquetas={etiquetas} />
                </div>
                <div style={{ opacity: loading ? 0.5 : 1 }}>
                    <div className="container-fluid mt-5">
                        <Button color="success" onClick={handleExportCSV}>
                            Exportar CSV (Excel)
                        </Button>
                        <div
                            className="mt-3"
                            style={{ height: "500px", width: "100%" }}
                        >
                            <div
                                className="ag-theme-alpine"
                                style={{ height: "100%", width: "100%" }}
                            >
                                <AgGridReact
                                    rowData={info}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    pagination={true}
                                    onGridReady={onGridReady}
                                    localeText={localeEs}
                                    paginationPageSize={20}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withTokenCheck(AppCheckOut);
