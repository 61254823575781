import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import { ToggleOnOutlined } from "@mui/icons-material";

const ModalEdit = ({
    isOpen,
    toggle,
    title,
    id,
    infoModal,
    updateTable,
    token,
}) => {
    const [fechaVigenciaDesde, setFechaVigenciaDesde] = useState("");
    const [fechaVigenciaHasta, setFechaVigenciaHasta] = useState("");
    const [tarifaHospedaje, setTarifaHospedaje] = useState("");
    const [tarifaAlimentacion, setTarifaAlimentacion] = useState("");
    const [tarifaTransporte, setTarifaTransporte] = useState("");
    const [recargoAlimentacion, setRecargoAlimentacion] = useState("");
    const [recargoAislamiento, setRecargoAislamiento] = useState("");
    const [tiposolicitud, setTipoSolicitud] = useState([]);
    const [selectedTipoSolicitud, setSelectedTipoSolicitud] = useState("");
    const [selectedTipoSolicitud2, setSelectedTipoSolicitud2] = useState("");
    const modalRef = useRef(null);

    console.log(infoModal, "info edición");

    useEffect(() => {
        setFechaVigenciaDesde(infoModal.VigenciaDesde);
        setFechaVigenciaHasta(infoModal.VigenciaHasta);
        setTarifaHospedaje(infoModal.TarifaHospedaje);
        setTarifaAlimentacion(infoModal.TarifaAlimentacion);
        setTarifaTransporte(infoModal.TarifaTransporte);
        setRecargoAlimentacion(infoModal.TarifaRecargoAlimentacion);
        setRecargoAislamiento(infoModal.TarifaRecargoAislamiento);
    }, [
        infoModal.VigenciaDesde,
        infoModal.VigenciaHasta,
        infoModal.TarifaHospedaje,
        infoModal.TarifaTransporte,
        infoModal.TarifaRecargoAlimentacion,
        infoModal.TarifaRecargoAlimentacion,
        infoModal.TarifaRecargoAislamiento,
    ]);

    useEffect(() => {
        if (token) {
            axios
                .get("/tiposolicitud", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setTipoSolicitud(response.data);
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const tipoSolicitudOptions = tiposolicitud.map((option) => ({
        value: option.id,
        label: option.Texto ?? option.Texto,
    }));

    const handleTipoSolicitudChange = (selectedOption) => {
        setSelectedTipoSolicitud2(selectedOption.value);
        setSelectedTipoSolicitud(selectedOption);
    };

    const handleFechaVigenciaDesde = (event) => {
        setFechaVigenciaDesde(event.target.value);
    };

    const handleFechaVigenciaHasta = (event) => {
        setFechaVigenciaHasta(event.target.value);
    };

    const handleTarifaHospedaje = (event) => {
        setTarifaHospedaje(event.target.value);
    };

    const handleTarifaAlimentacion = (event) => {
        setTarifaAlimentacion(event.target.value);
    };

    const handleTarifaTransporte = (event) => {
        setTarifaTransporte(event.target.value);
    };

    const handleTarifaRecargoAlimentacion = (event) => {
        setRecargoAlimentacion(event.target.value);
    };

    const handleTarifaRecargoAislamiento = (event) => {
        setRecargoAislamiento(event.target.value);
    };

    const handleEditSubmit = (event) => {
        event.preventDefault();
        axios
            .put(
                `/tarifasLossman/${infoModal.id}`,
                {
                    TipoSolicitudId: selectedTipoSolicitud2
                        ? selectedTipoSolicitud2
                        : infoModal.TipoSolicitudId,
                    VigenciaDesde: fechaVigenciaDesde
                        ? fechaVigenciaDesde
                        : infoModal.VigenciaDesde,
                    VigenciaHasta: fechaVigenciaHasta
                        ? fechaVigenciaHasta
                        : infoModal.VigenciaHasta,
                    TarifaAlimentacion: tarifaAlimentacion
                        ? tarifaAlimentacion
                        : infoModal.TarifaAlimentacion,
                    TarifaHospedaje: tarifaHospedaje
                        ? tarifaHospedaje
                        : infoModal.TarifaHospedaje,
                    TarifaTransporte: tarifaTransporte
                        ? tarifaTransporte
                        : infoModal.TarifaTransporte,
                    TarifaRecargoAlimentacion: recargoAlimentacion
                        ? recargoAlimentacion
                        : infoModal.TarifaRecargoAlimentacion,
                    TarifaRecargoAislamiento: recargoAislamiento
                        ? recargoAislamiento
                        : infoModal.TarifaRecargoAislamiento,
                },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: "Actualizado!",
                    text: "Las Tarifas han sido actualizadas con exito.",
                    showCloseButton: true,
                }).then(() => {
                    updateTable();
                    toggle();
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "Las tarifas no se pudieron actualizar",
                    showCloseButton: true,
                });
            });
    };

    return (
        <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <label htmlFor="ServicioHogar">Tipo solicitud:</label>
                    <Select
                        options={tipoSolicitudOptions}
                        onChange={handleTipoSolicitudChange}
                        value={
                            selectedTipoSolicitud
                                ? selectedTipoSolicitud
                                : tipoSolicitudOptions.find(
                                      (option) =>
                                          option.value ===
                                          infoModal.TipoSolicitudId
                                  )
                        }
                    ></Select>
                </FormGroup>
                <FormGroup>
                    <label>Fecha Vigencia Desde:</label>
                    <input
                        className="form-control"
                        name="tipoSolicitud"
                        type="date"
                        value={fechaVigenciaDesde}
                        onChange={handleFechaVigenciaDesde}
                    />
                </FormGroup>
                <FormGroup>
                    <label>Fecha Vigencia Hasta:</label>
                    <input
                        className="form-control"
                        name="tipoSolicitud"
                        type="date"
                        value={fechaVigenciaHasta}
                        onChange={handleFechaVigenciaHasta}
                    />
                </FormGroup>
                <FormGroup>
                    <label>Tarifa Hospedaje:</label>
                    <input
                        className="form-control"
                        name="tipoSolicitud"
                        type="number"
                        value={tarifaHospedaje}
                        onChange={handleTarifaHospedaje}
                    />
                </FormGroup>
                <FormGroup>
                    <label>Tarifa Alimentación:</label>
                    <input
                        className="form-control"
                        name="tipoSolicitud"
                        type="number"
                        value={tarifaAlimentacion}
                        onChange={handleTarifaAlimentacion}
                    />
                </FormGroup>
                <FormGroup>
                    <label>Tarifa Transporte:</label>
                    <input
                        className="form-control"
                        name="tipoSolicitud"
                        type="number"
                        value={tarifaTransporte}
                        onChange={handleTarifaTransporte}
                    />
                </FormGroup>
                <FormGroup>
                    <label>Recargo Alimentación:</label>
                    <input
                        className="form-control"
                        name="tipoSolicitud"
                        type="number"
                        value={recargoAlimentacion}
                        onChange={handleTarifaRecargoAlimentacion}
                    />
                </FormGroup>
                <FormGroup>
                    <label>Recargo Aislamiento:</label>
                    <input
                        className="form-control"
                        name="tipoSolicitud"
                        type="number"
                        value={recargoAislamiento}
                        onChange={handleTarifaRecargoAislamiento}
                    />
                </FormGroup>
            </ModalBody>

            <ModalFooter>
                <Button
                    onClick={handleEditSubmit}
                    color="primary"
                    otype="submit"
                >
                    Editar
                </Button>
                <Button color="danger" onClick={() => toggle()}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalEdit;
