import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";

const ModalEdit = ({
  isOpen,
  toggle,
  title,
  id,
  RolId,
  token,
  MenuDetalleId,
}) => {
  const [menuRol, setMenuRol] = useState("");
  const [rol, setRol] = useState([]);
  const [selectedRol, setSelectedRol] = useState("");
  const [selectedRol2, setSelectedRol2] = useState("");
  const [selectedMenu, setSelectedMenu] = useState("");
  const [selectedMenu2, setSelectedMenu2] = useState("");
  const [menuDetail, setMenuDetail] = useState([]);
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);

  const handleRolChange = (selectedOption) => {
    setSelectedRol2(selectedOption.value);
    setSelectedRol(
      rolOptions.find((option) => option.value === selectedOption.value)
    );
  };

  const handleMenuChange = (selectedOption) => {
    setSelectedMenu2(selectedOption.value);
    setSelectedMenu(
      menuDetalleOptions.find((option) => option.value === selectedOption.value)
    );
  };

  useEffect(() => {
    if (token) {
      axios
        .get("/roles", { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          setRol(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      axios
        .get("/menudetalle", { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          setMenuDetail(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  const rolOptions = rol.map((option) => ({
    value: option.id,
    label: option.Nombre ?? option.nombre,
  }));
  const menuDetalleOptions = menuDetail.map((option) => ({
    value: option.id,
    label: option.Nombre ?? option.nombre,
  }));

  const handleEditSubmit = (event) => {
    event.preventDefault();
    axios
      .put(
        `/menuporrol/${id}`,
        {
          RolId: selectedRol2 ? selectedRol2 : RolId,
          MenuDetalleId : selectedMenu2 ? selectedMenu2 : MenuDetalleId,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Actualizado!",
          text: "el Menú rol a sido actualizado con exito.",
          showCloseButton: true,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "el Menú rol no se pudo actualizar",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label htmlFor="RolId">Rol:</label>
          <Select options={rolOptions} onChange={handleRolChange} value={
              selectedRol
                ? selectedRol
                : rolOptions.find((option) => option.value === RolId)
            }></Select>
        </FormGroup>
        <FormGroup>
          <label htmlFor="MenuId">Menú:</label>
          <Select
            value={
              selectedMenu
                ? selectedMenu
                : menuDetalleOptions.find((option) => option.value === MenuDetalleId)
            }
            options={menuDetalleOptions}
            onChange={handleMenuChange}
          ></Select>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleEditSubmit} color="primary" otype="submit">
          Guardar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalEdit;
