import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import * as jzip from "jszip";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Helmet } from "react-helmet";
import { Button, FormGroup } from "reactstrap";
import "./styleConsult.css";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import Spinner from "../Spinner/spinner";
import { AgGridReact } from "ag-grid-react";
import { localeEs } from "../locale/ag-grid-es";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Select from "react-select";
import BreadCrum from "../breadCrum/breadCrum";
import withTokenCheck from "../withTokenCheck";

window.JSZip = jzip;

const AppReport = ({ token }) => {
    const [form, setForm] = useState([]);
    const currentDate = dayjs();
    const [dateStart, setDateStart] = useState(currentDate);
    const [dateEnd, setDateEnd] = useState(currentDate);
    const [numberCase, setNumberCase] = useState([]);
    const [selectedCase, setSelectedCase] = useState("");
    const [selectedCase2, setSelectedCase2] = useState("");
    const [loading, setLoading] = useState(true);
    const gridApi = useRef(null);
    const tableRef = useRef(null);
    const etiquetas = ["Lossman Inicio", "Reportes", "Reporte General"];
    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        if (token) {
            axios
                .get("/edicion-formulario/consulta/reporte", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setNumberCase(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const emptyOption = { value: null, label: "Seleccionar o Dejar Vacío" };

    const numberCaseoptions = [
        emptyOption,
        ...numberCase.map((option) => ({
            value: option.id,
            label: option.NumeroCaso ?? option.NumeroCaso,
        })),
    ];

    const handleNumberCaseChange = (selectedOption) => {
        setSelectedCase2(selectedOption.value);
        setSelectedCase(
            numberCaseoptions.find(
                (option) => option.value === selectedOption.value
            )
        );
    };

    const handleConsult = (event) => {
        event.preventDefault();
        const params = {
            FechaInicio: dateStart.format("YYYY-MM-DD"),
            FechaFinal: dateEnd.format("YYYY-MM-DD"),
            FormularioId: selectedCase2,
        };
        if (token) {
            setLoading(true);
            axios
                .get("/formulario", {
                    headers: { Authorization: `Bearer ${token}` },
                    params,
                })
                .then((response) => {
                    setForm(response.data);
                    console.log(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        editable: true,
    };

    const handleExportCSV = () => {
        gridApi.current.exportDataAsCsv();
    };

    const onGridReady = (params) => {
        gridApi.current = params.api;
    };

    const columnDefs = [
        {
            headerName: "Nit Prestador",
            field: "paciente.Nombre",
        },
        { headerName: "Nombre Prestador", field: "NumeroCaso" },
        {
            headerName: "Codigo Servicio",
            field: "codigo_servicios.CodigoServicio",
        },
        {
            headerName: "Nombre Servicio",
            field: "paciente.MotivoDeshabilitacion",
        },
        {
            headerName: "Numero De caso",
            field: "NumeroCaso",
        },
        {
            headerName: "Total dias",
            field: "DiasHospedaje",
        },
        {
            headerName: "Valor Unitario",
            field: "valor_unitario",
        },
        {
            headerName: "Total Facturado",
            field: "total_facturacion",
        },
        /* {
            headerName: "Nombre Usuario",
            field: "hogar.Nombre",
        },
        {
            headerName: "Tipo Documento",
            field: "asignacion_existente",
        },
        {
            headerName: "Numero Identificación",
            field: "asignacion_existente",
        },
        {
            headerName: "Fecha Inicio",
            field: "paciente.TelContacto",
        },
        {
            headerName: "Fecha Final",
            field: "paciente.tipo_documento.Texto",
        },
        {
            headerName: "Cantidad Facturada Hospedaje",
            field: "paciente.NumeroIdent",
        },
        {
            headerName: "Valor Hospedaje",
            field: "paciente.ciudad.Texto",
        },
        {
            headerName: "Cantidad Facturada Alimentación",
            field: "FechaSolicitud",
        },
        {
            headerName: "Valor Alimentación",
            field: "FechaInicial",
        },
        {
            headerName: "Ciudad Origen",
            field: "FechaFinal",
        },
        {
            headerName: "Nombre Acompañante",
            field: "NumeroMIPRES",
        },
        {
            headerName: "Tipo de Documento",
            field: "tipo_solicitud.Texto",
        },
        {
            headerName: "Numero de Identificación",
            field: "descripcion.Texto",
        },
        {
            headerName: "Fecha Inicio",
            field: "CodigoServicio",
        },
        {
            headerName: "Fecha Final",
            field: "Descripcion",
        },
        {
            headerName: "Cantidad Facturada Hospedaje",
            field: "Descripcion",
        },
        {
            headerName: "Valor Hospedaje",
            field: "Descripcion",
        },
        {
            headerName: "Cantidad Facturada Alimentación",
            field: "Descripcion",
        },
        {
            headerName: "Valor Alimentación",
            field: "Descripcion",
        },
        {
            headerName: "¿Tiene segundo Acompañane?",
            field: "Descripcion",
        },
        {
            headerName: "Fecha Inicio",
            field: "Descripcion",
        },
        {
            headerName: "Fecha Final",
            field: "Descripcion",
        },
        {
            headerName: "Cantidad Facturada Hospedaje",
            field: "Descripcion",
        },
        {
            headerName: "Valor Hospedaje",
            field: "Descripcion",
        },
        {
            headerName: "Cantidad Facturada Alimentación",
            field: "Descripcion",
        },
        {
            headerName: "Valor Alimentación",
            field: "Descripcion",
        },
        {
            headerName: "Transporte Aeropuerto - Terminal (Sencillo)",
            field: "Descripcion",
        },
        {
            headerName: "Transporte Aeropuerto - Terminal (redondo)",
            field: "Descripcion",
        },
        {
            headerName: "Traslado Interno (sencillo)",
            field: "Descripcion",
        },
        {
            headerName: "Traslado Interno (Redondo)",
            field: "Descripcion",
        },
        {
            headerName: "Valor Traslados",
            field: "Descripcion",
        },
        {
            headerName: "Cantidad de traslados",
            field: "Descripcion",
        },
        {
            headerName: "Funcionario responsable revisión",
            field: "Descripcion",
        },
        {
            headerName: "Solicitud interna",
            field: "Descripcion",
        },
        {
            headerName: "Orden de compra",
            field: "Descripcion",
        },
        {
            headerName: "Numero Recepción",
            field: "Descripcion",
        }, */
    ];

    function editRow(content) {
        const id = content.id;
        if (token) {
            axios
                .get(`/formulario/descargarPDF/${id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                    responseType: "blob",
                })
                .then((response) => {
                    if (response.status == 200) {
                        // Obtener el nombre del archivo del encabezado Content-Disposition
                        const contentDisposition = response.headers.get(
                            "Content-Disposition"
                        );
                        const fileNameMatch =
                            contentDisposition &&
                            contentDisposition.match(/filename="(.+)"/);
                        const fileName = fileNameMatch
                            ? fileNameMatch[1]
                            : "documento.pdf";

                        // Crear un Blob a partir del contenido del archivo
                        const blob = new Blob([response.data], {
                            type: "application/pdf",
                        });

                        // Crear un enlace temporal para descargar el archivo
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = fileName;
                        link.click();
                    }
                })
                .catch((error) => {
                    console.error(error);
                    Swal.fire({
                        icon: "error",
                        title: "Error!",
                        text: "No se encontro información del PDF",
                        showCloseButton: true,
                    });
                });
        }
    }

    return (
        <>
            <Helmet>
                <title>Acompañante</title>
            </Helmet>
            <div style={{ position: "relative" }}>
                {loading && <Spinner />}

                <div>
                    <BreadCrum etiquetas={etiquetas} />
                </div>

                <div
                    style={{
                        width: "90%",
                        margin: "0 auto",
                        marginTop: "2rem",
                    }}
                >
                    <label htmlFor="servicioId">
                        Seleccione el numero de caso que desea consultar
                    </label>
                    <FormGroup id="FormEdit">
                        <Select
                            id="SelectEdit"
                            options={numberCaseoptions}
                            value={selectedCase}
                            onChange={handleNumberCaseChange}
                        ></Select>
                    </FormGroup>
                </div>
                <div style={{ opacity: loading ? 0.5 : 1 }}>
                    <div className="container mt-5">
                        <label htmlFor="servicioId">
                            Ingrese el rango de fechas que desea consultar
                        </label>
                        <div className="ConsultReport">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={2} sx={{ minWidth: 305 }}>
                                    <DatePicker
                                        label="Fecha Inicial Desde"
                                        id="dateStart"
                                        value={dateStart}
                                        onChange={setDateStart}
                                    />
                                </Stack>
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={2} sx={{ minWidth: 305 }}>
                                    <DatePicker
                                        label="Fecha Inicial Hasta"
                                        value={dateEnd}
                                        onChange={setDateEnd}
                                    />
                                </Stack>
                            </LocalizationProvider>

                            <Button
                                id="buttonConsult"
                                className="mb-3"
                                color="secondary"
                                onClick={handleConsult}
                            >
                                Consultar
                            </Button>
                        </div>
                        <Button color="success" onClick={handleExportCSV}>
                            Exportar CSV (Excel)
                        </Button>
                        <div
                            className="mt-3"
                            style={{ height: "500px", width: "100%" }}
                        >
                            <div
                                className="ag-theme-alpine"
                                style={{ height: "100%", width: "100%" }}
                            >
                                <AgGridReact
                                    rowData={form}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    rowSelection={"multiple"}
                                    pagination={true}
                                    onGridReady={onGridReady}
                                    localeText={localeEs}
                                    paginationPageSize={20}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withTokenCheck(AppReport);
