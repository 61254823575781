import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FormGroup } from 'reactstrap';
import axios from "axios";

const BedSelection = ({token, onChange, value}) => {
    const [room, setRoom] = useState([]);

    useEffect(() => {
    if (token) {
        axios
            .get("/habitacion", {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                setRoom(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }, [token]);

    const roomOptions = room.map((option) => ({
        value: option.id,
        label:
            option.hogar.Nombre + ", Habitación: " + option.NumeroHabitacion ??
            option.NumeroHabitacion,
    }));

    const handleCompanionHabitacionChange = (selectedOption) => {
        onChange(selectedOption.value);
    };

    return (
    <FormGroup>
        <label htmlFor="habitacionId">
            Seleccionar habitación:
        </label>
        <Select
            options={roomOptions}
            onChange={handleCompanionHabitacionChange}
            value={roomOptions.find((option) => option.value === value)}
            className="form-control"
        ></Select>
    </FormGroup>
    );
}

export default BedSelection;